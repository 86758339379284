// @ts-check
import React, { useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { DialogContent, DialogTitle, Stack } from '@mui/material';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import { Dialog } from '../../../../newComponents/Dialog';
import { IconButton } from '../../../../newComponents/IconButton';
import { ProfilePictureSelector } from '../../../../components/ProfilePicture';
import { SoriHappy } from '../../../../components/Illustrations/Illustrations';
import { useScreenSize } from '../../../../Hooks';
import { STEP_TYPES } from '../UserOnboarding.constants';
import { userOnboardingVar } from '../UserOnboarding.vars';
import {
  UPLOAD_PROFILE_PICTURE,
  SKIP_PROFILE_PICTURE,
} from '../../../RHPod/EmployeePortal/gql';
import { useGetMyProfile } from '../../../RHPod/EmployeePortal/useGetMyProfile';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import {
  SoriSection,
  InfoSection,
  CloseButtonWrapper,
} from '../UserOnboarding.styles';

/** @param {import('./ProfilePictureUploaderDialog.types').ProfilePictureUploaderDialogProps} props */
export const ProfilePictureUploaderDialog = (props) => {
  const { onPictureUploaded } = props;
  const { isMobile } = useScreenSize();
  const [uploadProfilePicture] = useMutation(UPLOAD_PROFILE_PICTURE);
  const [skipProfilePicture] = useMutation(SKIP_PROFILE_PICTURE);
  const { fetchMyProfile } = useGetMyProfile();
  const { onboardingStep, onboardingWorkflow } =
    useReactiveVar(userOnboardingVar);

  const [pictureSelected, setPictureSelected] = useState(
    /** @type {Blob | null} */
    (null),
  );

  const handleSkipProfilePicture = async () => {
    try {
      globalBackdropVar({ open: true, text: 'Omitiendo foto de perfil...' });
      await skipProfilePicture();
      onPictureUploaded();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al omitir la foto de perfil',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleUploadProfilePicture = async () => {
    try {
      globalBackdropVar({ open: true, text: 'Actualizando foto de perfil...' });
      await uploadProfilePicture({
        variables: { file: pictureSelected },
      });
      globalSnackbarVar({
        show: true,
        message: 'Foto de perfil actualizada correctamente',
        severity: 'success',
      });
      fetchMyProfile();
      onPictureUploaded();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al subir la foto de perfil',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleClose = () => props.onClose();

  return (
    <Dialog
      open={
        onboardingWorkflow[onboardingStep] ===
        STEP_TYPES.ASK_FOR_PROFILE_PICTURE
      }
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: isMobile ? '0px' : '16px' } }}
    >
      {isMobile && (
        <DialogTitle textAlign="right">
          <IconButton icon="close_line" onClick={handleClose} />
        </DialogTitle>
      )}
      <DialogContent sx={{ padding: 0, height: '650px', position: 'relative' }}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <Stack direction="row">
              {!isMobile && (
                <SoriSection>
                  <SoriHappy width="300px" />
                </SoriSection>
              )}
              {!isMobile && (
                <CloseButtonWrapper>
                  <IconButton icon="close_line" onClick={handleClose} />
                </CloseButtonWrapper>
              )}
              <InfoSection
                height={height}
                pt={isMobile ? 0 : 4}
                justifyContent="center"
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  gap={4}
                  width={isMobile ? '100%' : '486px'}
                >
                  <ProfilePictureSelector
                    onPictureChange={(blob) => {
                      setPictureSelected(blob);
                    }}
                  />
                  <Typography variant="h4" mt={4}>
                    Agrega una foto de perfil
                  </Typography>
                  <Stack
                    direction={isMobile ? 'column' : 'row'}
                    padding={isMobile ? 2 : 3}
                    gap={2}
                    width="100%"
                  >
                    <Button
                      variant="outlined"
                      color="default"
                      fullWidth
                      size="large"
                      onClick={handleSkipProfilePicture}
                    >
                      Omitir
                    </Button>
                    <Button
                      variant="contained"
                      fullWidth
                      size="large"
                      disabled={!pictureSelected}
                      onClick={handleUploadProfilePicture}
                    >
                      Continuar
                    </Button>
                  </Stack>
                </Stack>
              </InfoSection>
            </Stack>
          )}
        </AutoSizer>
      </DialogContent>
    </Dialog>
  );
};
