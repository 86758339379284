import { useEffect, useCallback } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { Dialog, useMediaQuery, IconButton } from '@mui/material';
import { Icon } from '../../../components/Icons/Icons';
import { Box } from '@mui/material';
import { H5 } from '../../../components/Typographies/Typography';
import { ProfileWrapper } from './style';
import { GET_MY_PROFILE } from '../gql';
import {
  trustedOrigins,
  sifeProfileUrl,
} from '../../../API/instance/createInstance';
import { useTheme } from '@mui/material/styles';
import { profileDialogVar, userVar } from '../../../cache.reactiveVars';

const ProfileDialog = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useReactiveVar(userVar);
  const profileDialog = useReactiveVar(profileDialogVar);

  const [getMyProfile] = useLazyQuery(GET_MY_PROFILE, {
    onCompleted: ({ getMyProfile: profileData }) => {
      const newData = profileData.user;
      const newUserData = {
        ...user,
        profilePicture: newData.profilePicture,
        name: newData.names,
        lastNameP: newData.lastNameP,
        lastNameM: newData.lastNameM,
        curp: newData.curp,
      };

      userVar(newUserData);
      localStorage.setItem('user', JSON.stringify(newUserData));
    },
  });

  const handleClose = () => {
    profileDialogVar(false);
  };

  const handleOpenerMessage = useCallback(
    (e) => {
      if (!trustedOrigins.includes(e.origin)) {
        return;
      }

      if (/^react-devtools/gi.test(e.data.source)) {
        return null;
      }

      if (e.data.message === 'TOKEN_EXPIRED') {
        return window.removeEventListener('message', handleOpenerMessage);
      }

      // used to inject actual session data to SIFE
      if (e.data.message === 'openedReady') {
        const iframeId = 'sife-profile-iframe';
        const iframe = document.getElementById(iframeId);
        const targetWindow = iframe?.contentWindow;
        if (targetWindow) {
          targetWindow.postMessage(
            { message: 'openerReady', payload: { user }, type: 'INJ_USER' },
            sifeProfileUrl,
          );
        }
      }

      if (
        e.data.message === 'USER_DATA_UPDATED' ||
        e.data.message === 'PROFILE_IMG_UPDATED' ||
        e.data.message === 'PROFILE_IMG_REMOVED'
      ) {
        getMyProfile();
      }
    },
    [user, getMyProfile],
  );

  useEffect(() => {
    window.addEventListener('message', handleOpenerMessage, false);
    return () => {
      window.removeEventListener('message', handleOpenerMessage, false);
    };
  }, [handleOpenerMessage]);

  return (
    <Dialog
      open={profileDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      fullScreen={isMobile}
      sx={{
        '.MuiDialog-paper': { borderRadius: isMobile ? '0!important' : 2 },
      }}
    >
      {isMobile && (
        <div>
          <IconButton size="large" onClick={handleClose}>
            <Icon
              fill={theme.customPalette.grayColors.gray_70}
              icon="arrow_left_s_line"
              pointer
            />
          </IconButton>
        </div>
      )}
      <Box px={[1, 3]} py={{ xs: 0, md: 2 }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mx={1}
          py={{ xs: 0, md: '12px' }}
        >
          <H5>Perfil</H5>
        </Box>
        <ProfileWrapper>
          <iframe
            id="sife-profile-iframe"
            title="Perfil"
            src={`${sifeProfileUrl}?ntb=1&aep=0`}
            allowFullScreen
          />
        </ProfileWrapper>
      </Box>
    </Dialog>
  );
};

export default ProfileDialog;
