//@ts-check
import { endOfMonth, startOfMonth } from 'date-fns';
import { sub } from 'date-fns';

export const MAP_LEGENDS = {
  documents: {
    signed: 'Firmados',
    pending: 'Pendientes',
    percentage: 'Completado',
  },
  receipts: {
    total: 'Enviados',
    signed: 'Firmados',
    percentage: 'Completado',
  },
  onboarding: {
    active: 'Activos',
    total: 'Registrados',
  },
};

export const STATUS_MAP = ({ theme }) => {
  return {
    all: {
      title: 'Todos',
      color: 'success',
      textColor: theme.newPalette.success.dark,
      primaryColor: theme.newPalette.success.main,
      pieChartColor: theme.newPalette.primary.main,
    },
    active: {
      title: 'Activos',
      color: 'primary',
      textColor: theme.newPalette.primary.main,
      primaryColor: theme.newPalette.primary.main,
    },
    inactive: {
      title: 'Inactivos',
      color: 'default',
      textColor: theme.newPalette.grey.grey600,
      primaryColor: theme.newPalette.grey.grey600,
    },
    absent: {
      title: 'Ausente',
      color: 'info',
      textColor: theme.newPalette.info.main,
      primaryColor: theme.newPalette.info.main,
    },
    baja: {
      title: 'Baja',
      color: 'warning',
      textColor: theme.newPalette.warning.dark,
      primaryColor: theme.newPalette.warning.main,
    },
    sued: {
      title: 'Demanda',
      color: 'error',
      textColor: theme.newPalette.error.dark,
      primaryColor: theme.newPalette.error.main,
    },
  };
};

export const dateRanges = {
  lastThreeMonths: {
    start: startOfMonth(sub(startOfMonth(new Date()), { months: 2 })),
    end: endOfMonth(new Date()),
    title: '3 meses',
    key: 'lastThreeMonths',
  },
  lastSixMonths: {
    start: startOfMonth(sub(startOfMonth(new Date()), { months: 5 })),
    end: endOfMonth(new Date()),
    title: '6 meses',
    key: 'lastSixMonths',
  },
  lastTwelveMonths: {
    start: startOfMonth(sub(startOfMonth(new Date()), { months: 11 })),
    end: endOfMonth(new Date()),
    title: '12 meses',
    key: 'lastTwelveMonths',
  },
  historic: {
    start: null,
    end: null,
    title: 'Histórico',
    key: 'historic',
  },
};

export const rangeDatesTitles = () => {
  return Object.keys(dateRanges).map((key) => {
    return {
      title: dateRanges[key].title,
      key,
    };
  });
};

export const DATA_TO_RENDER = {
  documentProgressDashboard: {
    title: 'Firmado de documentos',
    dialog: 'signedDocumentsDialog',
  },
  receiptProgressDashboard: {
    title: 'Firmado de recibos',
    dialog: 'signedReceiptsDialog',
  },
  onboardingProgressDashboard: {
    title: 'Progreso de onboarding',
    dialog: 'onboardingDialog',
  },
};

export const strokes = {
  average: {
    primaryColor: (theme) => theme.newPalette.error.main,
    secondaryColor: (theme) => theme.newPalette.warning.main,
    colorPalette: 'error',
  },
  good: {
    primaryColor: (theme) => theme.newPalette.info.main,
    secondaryColor: (theme) => theme.newPalette.secondary.main,
    colorPalette: 'primary',
  },
  excellent: {
    primaryColor: (theme) => '#15F06E',
    secondaryColor: (theme) => theme.newPalette.secondary.main,
    colorPalette: 'primary',
  },
};

export const verifyStatus = (total) => {
  if (total >= 86) {
    return 'excellent';
  }

  if (total > 21) {
    return 'good';
  }

  return 'average';
};

export const colorText = (total = 0) => {
  if (total >= 86) {
    return 'success';
  }
  if (total > 21) {
    return 'primary';
  }

  return 'error';
};

export const TABS_VALUES = ({ theme }) => {
  return {
    documents: [
      {
        label: 'Pendientes de firmar',
        key: 'PENDING',
        color: 'default',
        textColor: theme.newPalette.grey.grey600,
      },
      {
        label: 'Firmados',
        key: 'SIGNED',
        color: 'success',
        textColor: theme.newPalette.success.dark,
      },
    ],
    receipts: [
      {
        label: 'Pendientes de firmar',
        key: 'PENDING',
        color: 'default',
        textColor: theme.newPalette.grey.grey600,
      },
      {
        label: 'Firmados',
        key: 'SIGNED',
        color: 'success',
        textColor: theme.newPalette.success.dark,
      },
    ],
    onboarding: [
      {
        label: 'Pendientes',
        key: 'INACTIVE',
        color: 'default',
        textColor: theme.newPalette.grey.grey600,
      },
      {
        label: 'Activos',
        key: 'ACTIVE',
        color: 'success',
        textColor: theme.newPalette.success.dark,
      },
    ],
    checkListDocType: [
      {
        label: 'Pendientes',
        key: 'PENDING',
        color: 'warning',
        textColor: theme.newPalette.warning.dark,
      },
      {
        label: 'Sin documentos',
        key: 'WITHOUT_DOCUMENTS',
        color: 'default',
        textColor: theme.newPalette.grey.grey600,
      },
      {
        label: 'Completados',
        key: 'COMPLETE',
        color: 'success',
        textColor: theme.newPalette.success.dark,
      },
    ],
    employeesStatus: Object.keys(STATUS_MAP({ theme })).map((key) => {
      const colorData = STATUS_MAP({ theme })[key];
      return {
        label: colorData.title,
        key: key.toUpperCase(),
        color: colorData.color,
        textColor: colorData.textColor,
      };
    }),
  };
};

export const itemsToSearch = [
  'EMPLOYEES_BY_COMPANIES',
  'WORKCENTERS_BY_COMPANIES',
  'WORKTITLES_BY_COMPANIES',
  'GROUPS_BY_COMPANIES',
  'ALL_COMPANIES',
];

export const ALL_COMPANIES = {
  _id: 'ALL_COMPANIES',
  name: '',
  clientName: 'Todas las empresas',
};

/** @type {import('./Dashboard.types').EmployeesByCriteriaObj} */
export const EMPLOYEES_BY_CRITERIA_OBJ = {
  EMPLOYEES: {
    _id: 'EMPLOYEES_BY_COMPANIES',
    name: 'Todos los empleados',
    icon: 'user_line',
    color: 'primary',
    key: 'employees',
  },
  WORKCENTERS: {
    _id: 'WORKCENTERS_BY_COMPANIES',
    name: 'Todos los centros de trabajo',
    icon: 'building_line',
    color: 'success',
    key: 'workCenters',
  },
  WORKTITLES: {
    _id: 'WORKTITLES_BY_COMPANIES',
    name: 'Todos los puestos de trabajo',
    icon: 'suitcase_line',
    color: 'error',
    key: 'workTitles',
  },
  GROUPS: {
    _id: 'GROUPS_BY_COMPANIES',
    name: 'Todos los grupos',
    icon: 'team_line',
    color: 'warning',
    key: 'groups',
  },
};
