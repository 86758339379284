//@ts-check
import React from 'react';
import { Dialog } from './Dialog';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';

import { useTheme } from '@mui/material';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { Typography } from '../Typography';
import { Icon } from './../../components/Icons/Icons';

/**
 * @typedef {import('./../../theme').CustomTheme} CustomTheme
 * @typedef {import('./../../components/Icons/Icons').Icons} Icons
 * @typedef {import('@mui/material/DialogActions').DialogActionsProps} DialogActionsProps
 */

/** @param {import('./ConfirmDialog.types').ConfirmDialogProps} props */
export const ConfirmDialog = ({
  open,
  onClose = () => {},
  onCancel,
  icon,
  title,
  confirmLabel,
  cancelLabel,
  onConfirm,
  children,
  severity,
  confirmButtonToRight,
  DialogProps = {},
  DialogActionsProps = {},
  CancelButtonProps = {},
  ConfirmButtonProps = {},
  handleCloseIconButton = false,
}) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;
  const severityColor = severityColors(palette)[severity];
  const { sx: dialogActionsSx, ...dialogActionsProps } = DialogActionsProps;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" {...DialogProps}>
      {title && (
        <DialogTitle>
          <Stack
            flexDirection="row"
            gap={2}
            color={severityColor}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6" color={severityColor}>
              {title}
            </Typography>
            {handleCloseIconButton && (
              <IconButton icon="close_line" onClick={onCancel} />
            )}

            {icon && !handleCloseIconButton && <Icon icon={icon} />}
          </Stack>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      {(cancelLabel || confirmLabel) && (
        <DialogActions
          {...dialogActionsProps}
          sx={[
            {
              pb: 3,
              pr: 3,
              gap: 1,
              flexDirection: confirmButtonToRight ? 'row-reverse' : 'row',
              justifyContent: confirmButtonToRight ? 'flex-start' : 'flex-end',
            },
            ...(Array.isArray(dialogActionsSx)
              ? dialogActionsSx
              : [dialogActionsSx]),
          ]}
        >
          {confirmLabel && (
            <Button
              variant="contained"
              color={severity}
              onClick={onConfirm}
              {...ConfirmButtonProps}
            >
              {confirmLabel}
            </Button>
          )}
          {cancelLabel && (
            <Button
              variant="outlined"
              color="default"
              onClick={onCancel}
              {...CancelButtonProps}
            >
              {cancelLabel}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

const severityColors = (palette) => ({
  info: palette.info.main,
  success: palette.success.main,
  error: palette.error.main,
  warning: palette.warning.main,
});
