import React, { useEffect, useState, useCallback } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useMediaQuery, Dialog, IconButton, Tooltip } from '@mui/material';
import { Icon } from '../Icons/Icons';
import { deviceUtil } from '../../utils/deviceUtil';
import {
  gtag,
  sifeAuthUrl,
  sifeMultiSignUrl,
  trustedOrigins,
} from '../../API/instance/createInstance';
import { SignWrapper } from '../DocumentSigner/style';
import {
  MY_DASHBOARD,
  MY_DOCUMENTS,
  MY_DOCUMENTS_AND_RECEIPTS,
  MY_RECEIPTS,
  OLDEST_UNSIGNED_RECEIPT,
  SIFE_CONFIRM_DOCUMENT_SIGN,
  SIFE_SEND_DOCUMENTS,
} from '../../containers/RHPod/EmployeePortal/gql';
import { useTheme } from '@mui/material/styles';
import {
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../cache.reactiveVars';
import { Button } from '../../newComponents/Button';
import { useCustomTheme } from '../../Hooks/useCustomTheme';

export const SifeIframe = ({
  setSignOpen = (input) => {},
  requestedDocuments,
  onSuccess,
  onUnsuccessfully = (input) => {},
  onCameraCallback = (input) => {},
}) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = deviceUtil.isMobile() || sm;
  const [cameraStarted, setCameraStarted] = useState(false);
  const user = useReactiveVar(userVar);
  const [sifeConfirmDocumentSign] = useMutation(SIFE_CONFIRM_DOCUMENT_SIGN, {
    refetchQueries: [
      { query: MY_DOCUMENTS_AND_RECEIPTS },
      { query: MY_DOCUMENTS },
      { query: MY_RECEIPTS },
      { query: MY_DASHBOARD },
      { query: OLDEST_UNSIGNED_RECEIPT },
    ],
  });
  const [iframeSize, setIframeSize] = useState({
    height: '300px',
    width: '375px',
  });

  const docIds = requestedDocuments.map((doc) => doc.id).join(',');
  const requestUrl = `${sifeMultiSignUrl}?docIds=${docIds}&ntb=1&min=1`;

  const handleSignMessage = useCallback(
    async (e) => {
      if (!trustedOrigins.includes(e.origin)) {
        return;
      }
      if (/^react-devtools/gi.test(e.data.source)) {
        return null;
      }
      console.log('💌', e.data);
      const messageReply = { success: false };

      if (e.data.message === 'CAMERA_STARTED') {
        setCameraStarted(true);
        setIframeSize({ width: '65vw', height: '90vh' });
        return onCameraCallback('CAMERA_STARTED');
      }

      if (e.data.message === 'CAMERA_STOPPED') {
        setCameraStarted(false);
        setIframeSize({ width: '65vw', height: '70vh' });
        return onCameraCallback('CAMERA_STOPPED');
      }

      if (
        e.data.message === 'VERIFICATION_STARTED' ||
        e.data.message === 'SIGNATURE_CREATION_STARTED'
      ) {
        return setIframeSize({ width: '65vw', height: '70vh' });
      }

      if (e.data.message === 'EFIRMA_SIGN_MULTI_DOCS_STARTED') {
        return setIframeSize({ height: '80vh', width: '55vw' });
      }

      if (e.data.message === 'SIGN_MULTI_DOCS_STARTED') {
        return setIframeSize({ height: '320px', width: '385px' });
      }

      setIframeSize({ height: '300px', width: '375px' });

      if (e.data.message === 'ACCESS_DENIED') {
        onUnsuccessfully && onUnsuccessfully(requestedDocuments);
        return setSignOpen(false);
      }

      if (e.data.message === 'NO_DOCS_TO_SIGN') {
        onUnsuccessfully && onUnsuccessfully(requestedDocuments);
        globalSnackbarVar({
          show: true,
          severity: 'warning',
          message: 'No hay documentos para firmar',
        });
        setSignOpen(false);
      }

      if (
        e.data.message === 'ALREADY_SIGNED' ||
        e.data.message === 'DOCS_SIGNED'
      ) {
        if (e.data.message === 'ALREADY_SIGNED') {
          globalSnackbarVar({
            show: true,
            severity: 'warning',
            message: 'Documento(s) ya firmado(s)',
          });
        }

        try {
          globalBackdropVar({
            open: true,
            text: 'Sincronizando documento(s)...',
          });

          await sifeConfirmDocumentSign({
            variables: {
              input: requestedDocuments.map((doc) => {
                return { type: doc.type.split('-')[0], documentId: doc.id };
              }),
            },
          });
          onSuccess && onSuccess(requestedDocuments);
          setSignOpen(false);
        } catch (error) {
          console.error(error);
          e.source?.postMessage(messageReply, e.origin);
          onUnsuccessfully && onUnsuccessfully(requestedDocuments);
          setSignOpen(false);
        } finally {
          globalBackdropVar({ open: false });
        }
      }
    },
    [
      requestedDocuments,
      onSuccess,
      sifeConfirmDocumentSign,
      setSignOpen,
      onCameraCallback,
      onUnsuccessfully,
    ],
  );

  const handleOpenerMessage = useCallback(
    (e) => {
      if (!trustedOrigins.includes(e.origin)) {
        return;
      }
      if (
        /^react-devtools/gi.test(e.data.source) ||
        e.data.message !== 'openedReady'
      ) {
        return null;
      }

      if (e.data.message === 'TOKEN_EXPIRED') {
        return window.removeEventListener('message', handleOpenerMessage);
      }

      const iframeId = 'sife-iframe';
      /** @type {HTMLIFrameElement} */
      const iframe = document.getElementById(iframeId);
      const targetWindow = iframe?.contentWindow;
      if (targetWindow) {
        const payload = { user };
        const type = 'INJ_USER';
        targetWindow.postMessage(
          { message: 'openerReady', payload, type },
          sifeAuthUrl,
        );
        // window.addEventListener('message', handleSignMessage);
      }
    },
    [user],
  );

  useEffect(() => {
    window.addEventListener('message', handleSignMessage);
    window.addEventListener('message', handleOpenerMessage);
    return () => {
      window.removeEventListener('message', handleOpenerMessage);
      window.removeEventListener('message', handleSignMessage);
    };
  }, [handleOpenerMessage, handleSignMessage]);

  return (
    <SignWrapper
      height={iframeSize.height}
      width={iframeSize.width}
      isMobile={isMobile}
      cameraStarted={cameraStarted}
    >
      <iframe
        style={{
          marginTop: !cameraStarted && isMobile ? '50px' : '0',
          minHeight: '100%',
        }}
        id="sife-iframe"
        title="sife-sign"
        src={requestUrl}
        allowFullScreen
        allow="camera; microphone; geolocation"
      />
    </SignWrapper>
  );
};

const SifeIFrameButton = ({
  requestedDocuments,
  onSuccess = () => {},
  onUnsuccessfully = (input) => {},
  signLabel = 'Firmar',
  handleClick = (fn) => fn(),
  handleCloseIframe = (fn) => fn(),
  tooltipText = '',
  disabled = false,
  shouldBlockOnSign = false,
  onClickModuleBlocked = () => {},
  ...props
}) => {
  const theme = useCustomTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = deviceUtil.isMobile() || sm;
  const [signOpen, setSignOpen] = useState(false);
  const [showCloseIframe, setShowCloseIframe] = useState(false);
  const [sifeSendDocument] = useMutation(SIFE_SEND_DOCUMENTS);

  const handleSendDocuments = async () => {
    if (shouldBlockOnSign) {
      return onClickModuleBlocked();
    }
    const typeOfSign =
      requestedDocuments?.length > 1 ? 'Multiple_Sign' : 'Single_Sign';
    gtag('event', 'click', {
      event_category: typeOfSign,
    });
    try {
      globalBackdropVar({ open: true, text: 'Cargando...' });

      const res = await sifeSendDocument({
        variables: {
          input: requestedDocuments.map((doc) => {
            return {
              type: doc.type,
              documentId: doc.id,
            };
          }),
        },
      });
      if (res.data?.sifeSendDocuments?.__typename === 'SentDocument') {
        setSignOpen(true);
      } else {
        globalSnackbarVar({
          show: true,
          message: res.data?.sifeSendDocuments?.message,
          severity: 'error',
        });
      }
    } catch (e) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message:
          e.message ||
          'Hubo un problema al obtener tu documento, contacta a Sora.',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleClose = () => {
    setSignOpen(false);
  };

  return (
    <>
      {signOpen && (
        <Dialog
          open={signOpen}
          maxWidth="xl"
          onClose={() => handleCloseIframe(handleClose)}
          fullScreen={isMobile}
          sx={{
            '.MuiDialog-paper': {
              borderRadius: isMobile ? '0!important' : 2,
            },
          }}
        >
          {!showCloseIframe && isMobile && (
            <div
              style={{
                height: '50px',
                width: '100%',
                position: 'fixed',
                borderBottom: `1px solid ${theme.customPalette.grayColors.gray_40}`,
              }}
            >
              <IconButton
                size="large"
                onClick={() => handleCloseIframe(handleClose)}
              >
                <Icon
                  fill={theme.customPalette.grayColors.gray_70}
                  icon="arrow_left_s_line"
                  pointer
                />
              </IconButton>
            </div>
          )}

          <SifeIframe
            requestedDocuments={requestedDocuments}
            onSuccess={onSuccess}
            onUnsuccessfully={onUnsuccessfully}
            setSignOpen={setSignOpen}
            onCameraCallback={(message) => {
              if (message === 'CAMERA_STARTED') setShowCloseIframe(true);
              if (message === 'CAMERA_STOPPED') setShowCloseIframe(false);
            }}
          />
        </Dialog>
      )}
      <div>
        <Tooltip title={tooltipText} placement="top" arrow>
          <span>
            <Button
              onClick={() => handleClick(handleSendDocuments)}
              fullWidth={isMobile}
              disabled={disabled}
              variant="contained"
              style={{
                width: isMobile ? '100%' : props.buttonWidth || '120px',
              }}
            >
              {signLabel}
            </Button>
          </span>
        </Tooltip>
      </div>
    </>
  );
};

export default SifeIFrameButton;
