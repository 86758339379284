import { useMutation, useReactiveVar } from '@apollo/client';
import { DialogContent, DialogTitle, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../components/Icons/Icons';
import { IconTab } from '../../../../../components/Tabs/Tabs';
import { H6 } from '../../../../../components/Typographies/Typography';
import { NoPermissions } from '../../../../RHPod/NoPermissions';
import { UPDATE_PERMISSIONS } from '../../../Company/company.gql';
import { buildPermissions } from '../../../utils';
import Permissions from './Permissions';
import { getRoles } from '../constants';
import { Tabs } from '../../../../../newComponents/Tabs';
import {
  globalBackdropVar,
  globalSnackbarVar,
  permissionsListVar,
  userPermissionsVar,
} from '../../../../../cache.reactiveVars';

const modules = {
  superAdmin: 'superAdmin',
  adminRH: 'adminRh',
  liderCT: 'adminWC',
};

export const DefaultRoles = ({ open, onClose }) => {
  const theme = useTheme();
  const palette = theme.customPalette;
  const [selectedRol, setSelectedRol] = useState('superAdmin');

  const userPermissions = useReactiveVar(userPermissionsVar);
  const permissionsList = useReactiveVar(permissionsListVar);
  const [updatePermissions] = useMutation(UPDATE_PERMISSIONS);

  useEffect(() => {
    permissionsListVar({
      superAdmin: null,
      adminRh: null,
      adminWC: null,
      defaultSuperAdmin: null,
      defaultAdminRh: null,
      defaultAdminWC: null,
    });
  }, [selectedRol]);

  const savePermissions = async () => {
    globalBackdropVar({ text: 'Actualizando información', open: true });
    try {
      const role = modules[selectedRol];
      const permissionsUpdated = permissionsList[role];
      if (!permissionsUpdated) {
        return globalSnackbarVar({
          show: true,
          message: 'No has realizado ninguna modificación',
          severity: 'warning',
        });
      }
      const newPermissions = buildPermissions(permissionsUpdated);
      await updatePermissions({
        variables: {
          input: {
            [role]: { permissions: newPermissions },
          },
        },
      });
      globalSnackbarVar({
        show: true,
        message: 'Actualización exitosa',
        severity: 'success',
      });
      onClose();
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  if (
    userPermissions &&
    !userPermissions.roles?.update &&
    !userPermissions.roles?.read
  ) {
    return <NoPermissions />;
  }

  return (
    <Dialog
      open={open}
      slideMode
      maxWidth="md"
      onClose={onClose}
      showCloseButton={false}
    >
      <DialogTitle
        sx={{
          borderBottom: `1px solid  ${palette.grayColors.gray_30}`,
        }}
      >
        <Stack flexDirection="row" alignItems="center" gap={2} mb={1} mt={1}>
          <Icon
            fill={palette.status.inactive_gray}
            icon="close_line"
            height="20px"
            pointer
            onClick={onClose}
          />
          <H6>Roles predeterminados</H6>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ height: '600px', p: 3, width: 700 }}>
        <Stack
          sx={{
            boxShadow: '-1px -4px 15px 3px  rgba(145, 158, 171, 0.10)',
            borderRadius: '8px',
          }}
          height="97%"
          mt={2}
        >
          <RolesTabs
            palette={palette}
            tabValue={selectedRol}
            setTabValue={(value) => setSelectedRol(value)}
          />

          {selectedRol === 'superAdmin' ? (
            <Permissions
              role="superAdmin"
              title="Permisos del Súper administrador"
              savePermissions={savePermissions}
              expandHeight={true}
            />
          ) : null}

          {selectedRol === 'adminRH' ? (
            <Permissions
              role="adminRh"
              title="Permisos del administrador"
              savePermissions={savePermissions}
              expandHeight={true}
            />
          ) : null}

          {selectedRol === 'liderCT' ? (
            <Permissions
              role="adminWC"
              title="Permisos del Líder de centro de trabajo"
              savePermissions={savePermissions}
              expandHeight={true}
            />
          ) : null}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export const RolesTabs = ({ palette, tabValue, setTabValue }) => {
  const roles = getRoles(palette);
  return (
    <Tabs
      value={tabValue}
      onChange={(e, newValue) => setTabValue(newValue)}
      variant="scrollable"
      scrollButtons={false}
      color={roles[tabValue].color}
    >
      {Object.keys(roles).map((roleKey) => {
        const role = roles[roleKey];
        return (
          <IconTab
            key={roleKey}
            value={roleKey}
            name={roleKey}
            label={role.label}
            selectedColor={role.textColor}
            typography="Source Sans Pro"
            fontWeight="600"
            icon={
              <Icon
                icon={role.icon}
                height="20px"
                fill={
                  tabValue === roleKey
                    ? role.textColor
                    : palette.status.inactive_gray
                }
                style={{ marginRight: '8px', marginTop: '6px' }}
              />
            }
          />
        );
      })}
    </Tabs>
  );
};
