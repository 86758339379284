// @ts-check
import { Stack, DialogContent } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Dialog } from '../../../../newComponents/Dialog';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import { SoriHappy } from '../../../../components/Illustrations/Illustrations';
import { useScreenSize } from '../../../../Hooks';
import { STEP_TYPES } from '../UserOnboarding.constants';
import { userOnboardingVar } from '../UserOnboarding.vars';

/** @param {import('./DocumentUploadCompletionDialog.types').DocumentUploadCompletionDialogProps} props */
export const DocumentUploadCompletionDialog = (props) => {
  const { isMobile } = useScreenSize();

  const { onboardingStep, onboardingWorkflow } =
    useReactiveVar(userOnboardingVar);

  const open =
    onboardingWorkflow[onboardingStep] ===
    STEP_TYPES.DOCUMENT_UPLOAD_COMPLETION_DIALOG;

  const handleClose = () => {
    sessionStorage.removeItem(STEP_TYPES.DOCUMENT_UPLOAD_COMPLETION_DIALOG);
    props.onClose({ force: true });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{ sx: { borderRadius: isMobile ? '0px' : '16px' } }}
    >
      <DialogContent dividers sx={{ minHeight: '50vh', padding: '16px' }}>
        <Stack
          gap={3}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          sx={{ height: '600px', overflow: 'auto' }}
        >
          <Stack>
            <SoriHappy width="200px" />
          </Stack>
          <Typography variant="h4">
            ¡Bien hecho! <br />
            Has enviado todos tus documentos.
          </Typography>
          <Stack>
            <Typography variant="body2" maxWidth="500px" color="grey">
              ¿Qué sigue?
            </Typography>
            <Typography variant="body2" maxWidth="500px" color="grey">
              Por ahora, esperar a que tu administrador revise tus documentos.
            </Typography>
          </Stack>
          <Typography variant="body2" maxWidth="500px" color="grey">
            Te llegará una notificación por correo, por si tienes que volver a
            cargar algún documento.
          </Typography>
          <Button variant="contained" size="large" onClick={handleClose}>
            Finalizar
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
