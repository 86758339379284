import { loggerUtil } from '../utils/loggerUtil';

const requestInterceptor = (config) => {
  loggerUtil.requestLog(config);
  return config;
};

const responseInterceptor = (res) => {
  loggerUtil.responseLog(res);
  return res;
};

export const interceptorHandler = (instance) => {
  instance.interceptors.request.use(requestInterceptor, (error) =>
    Promise.reject(error),
  );
  instance.interceptors.response.use(responseInterceptor, (error) => {
    return Promise.reject(error);
  });
};
