// @ts-check
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Button } from '../../../../../newComponents/Button';
import { Typography } from '../../../../../newComponents/Typography';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../components/Icons/Icons';
import { useTemplateDeletion } from '../TemplateHooks';
import type { TemplateDeleteConfirmationDialogProps } from './TemplateDeleteConfirmationDialog.types';

export const TemplateDeleteConfirmationDialog = (
  props: TemplateDeleteConfirmationDialogProps,
) => {
  const { showDialog, closeDialog, templateId, onSuccess } = props;
  const { handleDeleteTemplate } = useTemplateDeletion();

  const handleConfirm = () => {
    handleDeleteTemplate({
      data: { templateId },
      onSuccess: () => {
        closeDialog();
        onSuccess && onSuccess();
      },
    });
  };

  return (
    <Dialog
      fullWidth
      open={showDialog}
      maxWidth="xs"
      onClose={closeDialog}
      showCloseButton={false}
    >
      <DialogTitle
        sx={{ display: 'flex', alignItems: 'center', p: '24px' }}
        component="div"
      >
        <Icon
          icon="delete_bin_line"
          height="26px"
          onClick={closeDialog}
          sx={{ cursor: 'pointer' }}
          color="error"
        />
        <Typography
          variant="h6"
          color="error"
          sx={{ marginLeft: '16px', fontSize: '18px' }}
        >
          Eliminar plantilla
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: '0px 20px' }}>
        <Typography variant="body1">
          ¿Estás seguro que quieres eliminar esta plantilla?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button
          variant="contained"
          color="error"
          onClick={handleConfirm}
          sx={{
            height: '36px',
            padding: '6px 16px',
            borderRadius: '8px',
          }}
        >
          Eliminar
        </Button>
        <Button color="default" onClick={closeDialog} variant="outlined">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
