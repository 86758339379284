import React, { Suspense } from 'react';
import RoleRoute from '../../utils/RoleRoute';
import { LoaderWrapper } from '../../components/DocumentSigner/style';
import { CircularProgress } from '../../newComponents/Progress';
const Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));
const Employees = React.lazy(() => import('./Employees/Employees'));
const Documents = React.lazy(() => import('./Documents/Documents'));
const Credits = React.lazy(() => import('./Credits/Credits'));
const DocumentsNew = React.lazy(() => import('./Documents_New/Documents'));
const ReceiptsContainer = React.lazy(
  () => import('./Receipts/ReceiptsContainer'),
);
const AbsenceContainer = React.lazy(() => import('./Absence/AbsenceContainer'));

export default function RHPodAdminRoutes({ hideCreditsSection }) {
  return (
    <>
      <RoleRoute admin exact path="/rhpod/admin/empleados">
        <Suspense
          fallback={
            <LoaderWrapper style={{ display: 'flex', flexDirection: 'column' }}>
              <CircularProgress size={50} />
            </LoaderWrapper>
          }
        >
          <Employees />
        </Suspense>
      </RoleRoute>
      <RoleRoute admin exact path="/rhpod/admin/documentos">
        <Suspense
          fallback={
            <LoaderWrapper style={{ display: 'flex', flexDirection: 'column' }}>
              <CircularProgress size={50} />
            </LoaderWrapper>
          }
        >
          <DocumentsNew />
        </Suspense>
      </RoleRoute>
      <RoleRoute admin exact path="/rhpod/admin/documentos-old">
        <Suspense
          fallback={
            <LoaderWrapper style={{ display: 'flex', flexDirection: 'column' }}>
              <CircularProgress size={50} />
            </LoaderWrapper>
          }
        >
          <Documents />
        </Suspense>
      </RoleRoute>
      <RoleRoute admin exact path="/rhpod/admin/recibos">
        <Suspense
          fallback={
            <LoaderWrapper style={{ display: 'flex', flexDirection: 'column' }}>
              <CircularProgress size={50} />
            </LoaderWrapper>
          }
        >
          <ReceiptsContainer />
        </Suspense>
      </RoleRoute>
      <RoleRoute admin exact path="/rhpod/admin/ausencias">
        <Suspense
          fallback={
            <LoaderWrapper style={{ display: 'flex', flexDirection: 'column' }}>
              <CircularProgress size={50} />
            </LoaderWrapper>
          }
        >
          <AbsenceContainer />
        </Suspense>
      </RoleRoute>
      {!hideCreditsSection && (
        <RoleRoute admin exact path="/rhpod/admin/creditos">
          <Suspense
            fallback={
              <LoaderWrapper
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <CircularProgress size={50} />
              </LoaderWrapper>
            }
          >
            <Credits />
          </Suspense>
        </RoleRoute>
      )}
      <RoleRoute admin exact path="/rhpod/admin">
        <Suspense
          fallback={
            <LoaderWrapper style={{ display: 'flex', flexDirection: 'column' }}>
              <CircularProgress size={50} />
            </LoaderWrapper>
          }
        >
          <Dashboard />
        </Suspense>
      </RoleRoute>
    </>
  );
}
