// @ts-check
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Menu, MenuItem, Box, useTheme } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { Button } from '../../newComponents/Button';
import { employeesFinderVar } from './EmployeesFinder.vars';
import {
  FIND_BY_EMPLOYEES,
  FIND_BY_WORKCENTERS,
  FIND_BY_WORKTITLES,
  FIND_BY_GROUPS,
} from './EmployeesFinder.constants';

/** @type {import('./EmployeesFinder.types').FindByOptions} */
const FIND_BY_OPTIONS = {
  [FIND_BY_EMPLOYEES]: {
    label: 'Empleados',
    icon: 'user_line',
    color: 'primary',
  },
  [FIND_BY_WORKCENTERS]: {
    label: 'Centros de trabajo',
    icon: 'building_line',
    color: 'success',
  },
  [FIND_BY_WORKTITLES]: {
    label: 'Puestos de trabajo',
    icon: 'suitcase_line',
    color: 'error',
  },
  [FIND_BY_GROUPS]: {
    label: 'Grupos',
    icon: 'team_line',
    color: 'warning',
  },
};

/** @param {import('./EmployeesFinder.types').EmployeesFinderMenuProps} props */
export const EmployeesFinderMenu = (props) => {
  /** @type {import('../../theme').CustomTheme} */
  const theme = useTheme();
  const { findBy, disabled } = props;
  const { findBySelected } = useReactiveVar(employeesFinderVar);
  const findByOption = FIND_BY_OPTIONS[findBySelected];

  const [anchorEl, setAnchorEl] = useState(
    /** @type {null | HTMLElement} */ (null),
  );
  const open = Boolean(anchorEl);

  /** @param {React.MouseEvent<HTMLElement>} event */
  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  /** @param {import('./EmployeesFinder.types').FindBy} findBy */
  const handleSelectFindBy = (findBy) => {
    const newData = { ...employeesFinderVar(), findBySelected: findBy };
    if (findBy !== findBySelected) newData.data = { options: [], total: 0 };
    employeesFinderVar(newData);
    handleCloseMenu();
  };

  return (
    <Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
      <Button
        variant="soft"
        size="large"
        onClick={handleOpenMenu}
        startIcon={<Icon icon={findByOption.icon} />}
        color={findByOption.color}
        endIcon={<Icon icon="arrow_down_s_line" />}
        disabled={disabled}
        fullWidth
        sx={{
          borderTopRightRadius: { xs: '8px', sm: 0 },
          borderBottomLeftRadius: { xs: 0, sm: '8px' },
          borderBottomRightRadius: 0,
        }}
      >
        {findByOption.label}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
        {findBy.map((item) => {
          const findByOption = FIND_BY_OPTIONS[item];
          const color = theme.newPalette[findByOption.color].dark;
          return (
            <MenuItem
              key={item}
              sx={{ columnGap: 1.5, color }}
              onClick={() => handleSelectFindBy(item)}
            >
              <Icon icon={findByOption.icon} /> {findByOption.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
