import { Grid, Stack, TextField, useMediaQuery } from '@mui/material';
import { BodySmall, H5, H6 } from '../Typographies/Typography';
import { Dialog } from './Dialog';
import React, { useEffect, useState } from 'react';
import { useTheme, styled } from '@mui/system';
import { Icon } from '../Icons/Icons';
import { PrimaryButton, SecondaryButton } from '../Buttons/Buttons';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { GET_MY_PENDING_USER_DATA_CHANGE_REQUEST } from '../../containers/Login/gql';
import DocumentSigner from '../DocumentSigner/DocumentSigner';
import { RejectUserDataChangeRequest } from './RejectUserDataChangeRequest';
import ConfirmationDialog from './ConfirmationDialog';
import logout from '../../utils/logout';
import { GET_MY_PROFILE } from '../../containers/Landing/gql';
import { globalSnackbarVar, userVar } from '../../cache.reactiveVars';

const ErrorTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'red',
    },
    '&:hover fieldset': {
      borderColor: 'red',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'red',
    },
    '&.Mui-disabled fieldset': {
      borderColor: 'red',
    },
  },
});

const ReadOnlyField = ({ label, value, error }) => {
  if (error) {
    return (
      <ErrorTextField
        disabled={true}
        fullWidth={true}
        value={value}
        label={label}
        error
      />
    );
  }
  return (
    <TextField disabled={true} fullWidth={true} value={value} label={label} />
  );
};

export const UserDataChangeRequestDialog = () => {
  const theme = useTheme();
  const { data, refetch } = useQuery(GET_MY_PENDING_USER_DATA_CHANGE_REQUEST);
  const user = useReactiveVar(userVar);

  const [open, setOpen] = useState(true);
  const [openSigner, setOpenSigner] = useState(false);
  const [openConfirmationSignOut, setOpenConfirmationSignOut] = useState(false);
  const [openRejectConfirmDialog, setOpenRejectConfirmDialog] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [userCurrentData, setUserCurrentData] = useState({});
  const [userDataToChange, setUserDataToChange] = useState({});
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const onGetMyProfileComplete = ({ getMyProfile: profileData }) => {
    const newData = {
      ...user,
      ...profileData.user,
      name: profileData.user.names,
    };
    userVar(newData);
    localStorage.setItem('user', JSON.stringify(newData));
  };
  const [getMyProfile] = useLazyQuery(GET_MY_PROFILE, {
    onCompleted: onGetMyProfileComplete,
  });

  useEffect(() => {
    setCurrentRequest(data?.getMyPendingDataChangeRequest);
    setUserDataToChange(data?.getMyPendingDataChangeRequest?.input);
  }, [data]);

  useEffect(() => {
    setUserCurrentData({
      names: user.name,
      lastNameP: user.lastNameP,
      lastNameM: user.lastNameM,
      rfc: user.rfc,
      curp: user.curp,
    });
  }, [user]);

  const onClose = async () => {
    setOpenConfirmationSignOut(true);
  };

  const onConfirmationSignOutClose = async (userWillCheckRequest) => {
    if (userWillCheckRequest) {
      return setOpenConfirmationSignOut(false);
    } else {
      logout();
    }
  };

  const onSignConfirmed = async () => {
    setOpen(false);
    globalSnackbarVar({
      show: true,
      message: 'Datos actualizados con éxito',
      severity: 'success',
    });
    await getMyProfile();
  };

  const onReject = async () => {
    await refetch();
    setOpen(false);
  };

  if (!currentRequest) return <></>;
  return (
    <>
      <Dialog
        open={open && !!currentRequest}
        onClose={onClose}
        fullWidth={true}
        PaperProps={{
          style: {
            borderRadius: '16px',
          },
        }}
      >
        <Grid container spacing={2} sx={{ p: 4 }}>
          <Grid item xs={12}>
            <Icon
              style={{ cursor: 'pointer' }}
              icon={'close_line'}
              fill={theme.customPalette.primary.gray}
              onClick={onClose}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              spacing={2}
              justifyContent={'center'}
              sx={{ textAlign: 'center' }}
            >
              <Icon
                style={{ height: '60px' }}
                icon={'error_warning_line'}
                fill={theme.customPalette.primary.yellow}
              />
              <H5>Tienes una solicitud de cambio de datos personales.</H5>
              <p>
                <BodySmall color={theme.customPalette.primary.gray}>
                  {currentRequest.companyName} quiere cambiar los siguientes
                  datos personales de tu cuenta de Sora:
                </BodySmall>
              </p>
            </Stack>
          </Grid>
          <Grid container item xs={12} md={6} spacing={4} p={2}>
            <Grid item sx={{ width: '100%' }}>
              <Stack spacing={2}>
                <Stack>
                  <H6>Tus datos actuales</H6>
                  <BodySmall color={theme.customPalette.primary.gray}>
                    Estos son los que actualmente están en tu perfil
                  </BodySmall>
                </Stack>
                <Stack spacing={2}>
                  <ReadOnlyField
                    value={userCurrentData.curp ?? ''}
                    label={'CURP'}
                  />
                  <ReadOnlyField
                    value={userCurrentData.rfc ?? ''}
                    label={'RFC'}
                  />
                  <ReadOnlyField
                    value={userCurrentData.names ?? ''}
                    label={'Nombre(s)'}
                  />
                  <Stack direction={'row'} spacing={2}>
                    <ReadOnlyField
                      value={userCurrentData.lastNameP ?? ''}
                      label={'Apellido paterno'}
                    />
                    <ReadOnlyField
                      value={userCurrentData.lastNameM ?? ''}
                      label={'Apellido materno'}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item sx={{ width: '100%' }}>
              <Stack spacing={2}>
                <Stack>
                  <H6>Datos que van a cambiar</H6>
                  <BodySmall color={theme.customPalette.primary.gray}>
                    Verifica los datos marcados en rojo
                  </BodySmall>
                </Stack>
                <Stack spacing={2}>
                  <ReadOnlyField
                    value={userDataToChange.curp ?? user.curp ?? ''}
                    label={'CURP'}
                    error={!!userDataToChange.curp}
                  />
                  <ReadOnlyField
                    value={userDataToChange.rfc ?? user.rfc ?? ''}
                    label={'RFC'}
                    error={!!userDataToChange.rfc}
                  />
                  <ReadOnlyField
                    value={userDataToChange.names ?? user.name ?? ''}
                    label={'Nombre(s)'}
                    error={!!userDataToChange.names}
                  />
                  <Stack direction={'row'} spacing={2}>
                    <ReadOnlyField
                      value={userDataToChange.lastNameP ?? user.lastNameP ?? ''}
                      label={'Apellido paterno'}
                      error={!!userDataToChange.lastNameP}
                    />
                    <ReadOnlyField
                      value={userDataToChange.lastNameM ?? user.lastNameM ?? ''}
                      label={'Apellido materno'}
                      error={!!userDataToChange.lastNameM}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Stack
              direction={sm ? 'column' : 'row'}
              spacing={2}
              justifyContent={'end'}
            >
              <SecondaryButton onClick={() => setOpenRejectConfirmDialog(true)}>
                No estoy de acuerdo
              </SecondaryButton>
              <PrimaryButton onClick={() => setOpenSigner(true)}>
                Estoy de acuerdo
              </PrimaryButton>
            </Stack>
          </Grid>
        </Grid>
        <DocumentSigner
          open={openSigner}
          title={'Solicitud de cambio de datos'}
          docId={currentRequest.employeeDocumentId}
          onSuccess={onSignConfirmed}
          handleClose={() => setOpenSigner(false)}
          requestType={'DOCUMENT'}
          signable={true}
        />
        <RejectUserDataChangeRequest
          open={openRejectConfirmDialog}
          currentRequest={currentRequest}
          onClose={() => setOpenRejectConfirmDialog(false)}
          onReject={onReject}
        />
        <ConfirmationDialog
          onClose={onConfirmationSignOutClose}
          buttonsInBlock={true}
          title={'Revisa la petición antes de continuar'}
          open={openConfirmationSignOut}
          cancelLabel={'Cerrar sesión'}
          acceptLabel={'Revisar petición'}
          content={'Para continuar navegando revisa la solicitud'}
          closeOnEscKey={false}
          closeOnBackdropClick={false}
        />
      </Dialog>
    </>
  );
};
