import { DialogContent, DialogTitle } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { ErrorBoundary } from '@sentry/react';
import { DefaultErrorComponent } from '../../../../components/ErrorBoundary/ErrorBoundary';
import { ReceiptUpload } from '../../Documents/ReceiptUpload/ReceiptUpload';
import { Dialog } from '../../../../components/Dialogs/Dialog';
import { useState } from 'react';
import { GetFullScreen } from '../../../../globalTheme';
import { IconButton } from '../../../../newComponents/IconButton';

export const UploadReceiptsDialog = ({
  onClose = () => {},
  open = false,
  refetchReceipts,
}) => {
  const fullScreen = GetFullScreen();
  const [uploadError, setUploadError] = useState(false);

  return (
    <Dialog
      fullWidth
      maxWidth={uploadError ? 'md' : 'xs'}
      onClose={onClose}
      onCloseXButton={onClose}
      open={open}
      slideMode
      showCloseButton={false}
    >
      <DialogTitle
        display="flex"
        alignItems="center"
        component="div"
        gap="16px"
      >
        <IconButton
          aria-label="send"
          onClick={onClose}
          color="grey"
          icon="close_line"
        />
        <Typography variant="h6">Importar recibos .xml</Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: '0px', flexGrow: 1 }}>
        <ErrorBoundary fallback={<DefaultErrorComponent />}>
          <ReceiptUpload
            open={open}
            onClose={onClose}
            handleCloseAll={onClose}
            fullWidth
            fullScreen={fullScreen}
            setUploadError={setUploadError}
            refetchReceipts={refetchReceipts}
          />
        </ErrorBoundary>
      </DialogContent>
    </Dialog>
  );
};
