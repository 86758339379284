// @ts-check
import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { Tooltip } from '../Tooltip';

export const Button = React.forwardRef(
  /**
   * @param {import('./Button.types').ButtonProps} props
   * @param {any} ref
   * @returns {React.JSX.Element}
   */
  function ({ tooltipText, tooltipPlacement, tooltipArrow, ...props }, ref) {
    if (tooltipText) {
      return (
        <Tooltip
          title={tooltipText}
          placement={tooltipPlacement}
          arrow={tooltipArrow}
        >
          <span>
            {/* @ts-ignore */}
            <MuiButton {...props} ref={ref} />
          </span>
        </Tooltip>
      );
    }
    // @ts-ignore
    return <MuiButton {...props} ref={ref} />;
  },
);
