//@ts-check
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useApolloClient, useQuery, useReactiveVar } from '@apollo/client';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SoraGrid } from '../../../../../../components/Datagrid/Datagrid';
import { withErrorBoundary } from '@sentry/react';
import { DefaultErrorComponent } from '../../../../../../components/ErrorBoundary/ErrorBoundary';
import { userPermissionsVar } from '../../../../../../cache.reactiveVars';
import { recordChecklistTableColumns } from './RecordChecklistTableColumns';
import { RecordChecklistTableHeader } from './RecordChecklistTableHeader';
import { sendDocumentsOptionsDialogVar } from '../../../../../../businessComponents/Dialogs/SendDocumentsOptionsDialog/SendDocumentsOptionsDialog.vars';
import { setDocumentCreatorDialogVar } from '../../../../../../businessComponents/Dialogs/DocumentCreatorDialog/DocumentCreatorDialog.vars';
import {
  setSingleDocumentUploadDialogVar,
  resetSingleDocumentUploadDialog,
} from '../../../../../../businessComponents/Dialogs/SingleDocumentUploadDialog/SingleDocumentUploadDialog.vars';
import { OnboardingDocumentViewer } from '../../../../../../businessComponents/OnboardingDocumentViewer';
import { SORA_MODULES } from '../../../../../../components/ModuleBlockableComponent/ModuleBlockable.constants';
import { useBlockableActions } from '../../../../../../components/ModuleBlockableComponent/useBlockableActions';
import { getOrderedCategoryMap } from './RecordChecklistTable.helpers';
import { DOCUMENT_STATUS } from './RecordChecklistTable.constants';
import { useUploadPendingDocument } from '../../../../../Landing/UserOnboarding/PendingDocumentsDialog/useUploadPendingDocument';
import {
  GET_EMPLOYEE_INFO,
  GET_EMPLOYEE_RECORD_CHECKLIST_DOCUMENTS,
} from '../../EmployeeInfo.gql';

export const RecordChecklistTable = withErrorBoundary(
  ({ employeeId, recordChecklistAssigned, employeeData }) => {
    const {
      isModuleAvailable: isDocumentsModuleAvailable,
      onClick: moduleBlockedOnClick,
    } = useBlockableActions(SORA_MODULES.DOCUMENTS);

    const [pageSize, setPageSize] = useState(10);
    const [sortModel, setSortModel] = useState(
      /** @type {import('@mui/x-data-grid').GridSortModel} */
      ([]),
    );
    const [documentViewer, setDocumentViewer] = useState(
      /** @type {import('../../../../../../businessComponents/OnboardingDocumentViewer/OnboardingDocumentViewer.types').DocumentViewerState} */
      ({
        open: false,
        hideActions: false,
        documentIndex: 0,
      }),
    );

    const userPermissions = useReactiveVar(userPermissionsVar);
    const client = useApolloClient();

    const recordChecklistTableForm = useForm({
      mode: 'onChange',
      defaultValues: {
        recordChecklistName: '',
        documentStatusSelect: 'all',
        documentSearch: '',
      },
      reValidateMode: 'onChange',
    });

    useEffect(() => {
      if (recordChecklistAssigned?.name) {
        recordChecklistTableForm.reset({
          recordChecklistName: recordChecklistAssigned?.name,
          documentStatusSelect: 'all',
          documentSearch: '',
        });
      }
    }, [recordChecklistAssigned, recordChecklistTableForm, client]);

    const documentSearchValue =
      recordChecklistTableForm.watch('documentSearch');
    const documentStatusSelectValue = recordChecklistTableForm.watch(
      'documentStatusSelect',
    );

    const {
      data: { getRecordChecklistAssignedByEmployee } = {
        getRecordChecklistAssignedByEmployee: {},
      },
      loading,
    } = useQuery(GET_EMPLOYEE_RECORD_CHECKLIST_DOCUMENTS, {
      variables: { employeeId },
      fetchPolicy: 'cache-and-network',
    });

    /** @type {import('./RecordChecklistTable.types').RecordChecklistAssignedDocument[]} */
    const documents =
      getRecordChecklistAssignedByEmployee?.recordChecklistAssignedDocuments
        ?.documents || [];

    const sortedDocuments = [...documents].sort((a, b) => {
      const sortModelField = sortModel[0]?.field;
      const sortModelSort = sortModel[0]?.sort;
      if (!a[sortModelField] && !b[sortModelField]) {
        return 0;
      }

      if (a[sortModelField] && !b[sortModelField]) {
        return sortModelSort === 'asc' ? 1 : -1;
      }

      if (!a[sortModelField] && b[sortModelField]) {
        return sortModelSort === 'asc' ? -1 : 1;
      }

      return sortModelSort === 'asc'
        ? a[sortModelField].localeCompare(b[sortModelField])
        : b[sortModelField].localeCompare(a[sortModelField]);
    });

    const recordChecklistPositionMap = getOrderedCategoryMap(documents);
    const indexedDocuments = sortedDocuments.map((doc, index) => {
      const categoryAndSubcategory = `${doc.category}_${doc.subcategory}`;
      return {
        ...doc,
        index,
        position: recordChecklistPositionMap[categoryAndSubcategory],
      };
    });

    const filteredDocuments = indexedDocuments.filter((document) => {
      const upperCaseDocumentSearchValue = documentSearchValue.toUpperCase();
      const upperCaseTitle = document.title?.toUpperCase() || ''; // In case title is null
      const upperCaseCategory = document.category?.toUpperCase();
      const upperCaseSubcategory = document.subcategory?.toUpperCase();

      return (
        upperCaseTitle.includes(upperCaseDocumentSearchValue) ||
        upperCaseCategory.includes(upperCaseDocumentSearchValue) ||
        upperCaseSubcategory.includes(upperCaseDocumentSearchValue)
      );
    });

    const handleOnUploadSuccess = async () => {
      await client.refetchQueries({
        include: [GET_EMPLOYEE_INFO, GET_EMPLOYEE_RECORD_CHECKLIST_DOCUMENTS],
      });
    };

    const handleOnStatusChange = async () => {
      await client.refetchQueries({
        include: [GET_EMPLOYEE_INFO, GET_EMPLOYEE_RECORD_CHECKLIST_DOCUMENTS],
      });
    };

    const { handleUploadPendingDocument: uploadPendingDocumentToEmployee } =
      useUploadPendingDocument();

    const handleUploadDocumentFromTableButton = ({ row, colDef }) => {
      if (row.status === DOCUMENT_STATUS.EMPLOYEE_UPLOAD_DOC) {
        return setSingleDocumentUploadDialogVar({
          open: true,
          onUploadHandler: (file) => {
            uploadPendingDocumentToEmployee({
              file,
              _id: row._id,
              category: row.category,
              subcategory: row.subcategory,
              adminUploadDocument: true,
              userId: employeeData.userId,
              successMessage: 'Archivo cargado correctamente',
              onSuccess: () => {
                resetSingleDocumentUploadDialog();
                handleOnUploadSuccess();
              },
            });
          },
        });
      }

      if (!isDocumentsModuleAvailable) {
        return moduleBlockedOnClick();
      }
      const mappedEmployeeData = colDef.employeeData
        ? { ...colDef.employeeData, id: colDef.employeeData.userId }
        : null;
      sendDocumentsOptionsDialogVar({
        open: true,
        onCreateDocument: () => {
          setDocumentCreatorDialogVar({
            open: true,
            openConfirmDialog: false,
            category: row.category,
            subcategory: row.subcategory,
            employeeData: mappedEmployeeData,
            onUploadSuccess: handleOnUploadSuccess,
          });
        },
        onUploadDocument: () => {
          setSingleDocumentUploadDialogVar({
            open: true,
            employeeData: colDef.employeeData || null,
            category: row.category,
            subcategory: row.subcategory,
          });
        },
      });
    };

    const pendingDocuments = filteredDocuments.filter(
      (doc) => doc.status === DOCUMENT_STATUS.PENDING_REVIEW,
    );

    /** @param {import('./RecordChecklistTable.types').RecordChecklistAssignedDocument} row */
    const handleClickDocumentTitle = async (row) => {
      const hideActions = row.status !== DOCUMENT_STATUS.PENDING_REVIEW;
      const documentIndex = (
        hideActions ? filteredDocuments : pendingDocuments
      ).findIndex((doc) => doc._id === row._id);
      setDocumentViewer({ open: true, hideActions, documentIndex });
    };

    /** @type {import('../../../../../../theme').CustomTheme} */
    const theme = useTheme();

    const modifiedColumns = recordChecklistTableColumns.map((column) => {
      return {
        ...column,
        employeeId,
        employeeData,
        palette: theme.newPalette,
        userPermissions,
        handleOnStatusChange,
        handleClickDocumentTitle,
        handleUploadDocumentFromTableButton,
      };
    });

    /** @type {import('../../../../../../businessComponents/OnboardingDocumentViewer/OnboardingDocumentViewer.types').OnPrevDocument} */
    const handlePrevDocument = async () => {
      const { documentIndex } = documentViewer;
      setDocumentViewer({
        ...documentViewer,
        documentIndex: documentIndex + 1,
      });
    };

    /** @type {import('../../../../../../businessComponents/OnboardingDocumentViewer/OnboardingDocumentViewer.types').OnNextDocument} */
    const handleNextDocument = async (reason) => {
      if (reason === 'rejectedDocument' || reason === 'approvedDocument') {
        await handleOnStatusChange();
        return;
      }

      const { documentIndex } = documentViewer;
      setDocumentViewer({
        ...documentViewer,
        documentIndex: documentIndex + 1,
      });
    };

    /** @type {import('../../../../../../businessComponents/OnboardingDocumentViewer/OnboardingDocumentViewer.types').OnClose} */
    const handleCloseDocumentViewer = async (shouldRefetch) => {
      if (shouldRefetch) await handleOnStatusChange();
      setDocumentViewer({ ...documentViewer, open: false });
    };

    const docCount = documents?.length || 0;
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <OnboardingDocumentViewer
          loading={loading}
          open={documentViewer.open}
          document={
            documentViewer.hideActions
              ? filteredDocuments[documentViewer.documentIndex]
              : pendingDocuments[documentViewer.documentIndex]
          }
          employee={employeeData}
          hideActions={documentViewer.hideActions}
          totalDocuments={
            documentViewer.hideActions
              ? filteredDocuments.length
              : pendingDocuments.length
          }
          currentDocumentIndex={documentViewer.documentIndex}
          onClose={handleCloseDocumentViewer}
          onPrevDocument={handlePrevDocument}
          onNextDocument={handleNextDocument}
        />

        <RecordChecklistTableHeader
          recordChecklistAssigned={recordChecklistAssigned}
          recordChecklistTableForm={recordChecklistTableForm}
        />
        <SoraGrid
          rows={filteredDocuments}
          rowCount={docCount}
          columns={modifiedColumns}
          loading={loading}
          checkboxSelection={false}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          hideFooter={false}
          headerHeight={57}
          getRowId={(row) => row._id}
          rowHeight={48}
          hideConfig={true}
          autoHeight
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50]}
          filterModel={{
            items: [
              {
                columnField: 'status',
                operatorValue: 'contains',
                value:
                  documentStatusSelectValue === 'all'
                    ? ''
                    : documentStatusSelectValue,
              },
            ],
          }}
          sortingMode="server"
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        />
      </Box>
    );
  },
  {
    fallback: <DefaultErrorComponent />,
  },
);
