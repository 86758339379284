//@ts-check
import React, { useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { useDropzone } from 'react-dropzone';
import { FolderAddIllustration } from '../../components/Illustrations/Illustrations';
import { Typography } from '../Typography/Typography';

/** @param {File} file */
const errorMessages = (file) => ({
  'file-invalid-type': `El formato del archivo ${file.name} no es válido`,
  'too-many-files': 'Solo puedes subir un archivo',
  'file-too-large':
    'El archivo es muy grande. El tamaño máximo aceptado es 25mb.',
  'file-too-small': 'El archivo no tiene contenido',
});

/**
 * @summary component for uploading files
 * @param {import('./FileUpload.types').FileUploadProps} props
 */
export const FileUpload = ({
  onChange,
  id,
  name,
  title,
  content,
  accept,
  single,
  setErrors,
  contentDirection = 'row',
  children,
  validateExtBeforeUpload,
  direction = 'row',
  disabled = false,
  maxFiles,
  iconSize = '106px',
  sx,
  illustrationComponent,
}) => {
  const componentDirection = direction === 'row' ? 'row' : 'column';

  /** @type {React.CSSProperties} */
  const textStyles = {
    textAlign: componentDirection === 'column' ? 'center' : 'unset',
  };

  /** @param {import('./FileUpload.types').FileRejection[]} fileRejections */
  const translateRejections = (fileRejections) => {
    return fileRejections.map((fileRejected) => {
      const { file, errors } = fileRejected;
      const errorsMapped = errors.map((error) => {
        const message = errorMessages(file)[error.code] || 'Ocurrió un error';
        return { ...error, message };
      });
      return { file, errors: errorsMapped };
    });
  };

  /** @type {import('./../../theme').CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop: (acceptedFiles, fileRejections) => {
        onChange(acceptedFiles, translateRejections(fileRejections));
      },
      accept,
      maxSize: 25000000,
      minSize: 1024,
      // ...(single && { maxFiles: 1 }),
      maxFiles: maxFiles || 1,
      disabled: disabled,
    });

  const handleError = ({ errors, file }) => {
    if (validateExtBeforeUpload) return;
    if (errors[0].code === 'file-invalid-type')
      return `El formato del archivo ${file.name} no es válido`;
    if (errors[0].code === 'too-many-files')
      return 'Solo puedes subir un archivo';
    if (errors[0].code === 'file-too-large')
      return 'El archivo es muy grande. El tamaño máximo aceptado es 25mb.';
    if (errors[0].code === 'file-too-small')
      return 'El archivo no tiene contenido';
    return 'Ocurrio un error con el archivo subido';
  };

  useEffect(() => {
    if (fileRejections.length && setErrors) {
      setErrors(fileRejections);
    }
  }, [fileRejections, setErrors]);

  if (children) {
    return (
      <Stack
        flexDirection={contentDirection}
        justifyContent="center"
        alignItems="center"
        gap={2}
        {...getRootProps()}
      >
        <input
          {...getInputProps({
            multiple: !single,
            id,
            name,
          })}
        />
        {children}
      </Stack>
    );
  }

  return (
    <Stack
      {...getRootProps()}
      sx={{
        cursor: 'pointer',
        p: '40px',
        borderRadius: '8px',
        border: `1px dashed ${palette.grey.transparent32}`,
        ...(disabled && { opacity: '0.5' }),
        ...(sx && { ...sx }),
      }}
    >
      <input
        {...getInputProps({
          multiple: !single,
          id,
          name,
          disabled: disabled,
        })}
      />
      <Stack
        spacing={4}
        direction={componentDirection}
        alignItems="center"
        justifyContent="center"
      >
        {illustrationComponent ? (
          illustrationComponent
        ) : (
          <FolderAddIllustration
            style={{
              width: '100%',
              maxWidth: iconSize,
              boxSizing: 'content-box',
            }}
          />
        )}
        <Box ml={3} display="flex" flexDirection="column">
          <Typography
            variant="h5"
            style={{ ...textStyles }}
            color={palette.text.primary}
          >
            {isDragActive ? 'Suelta tu archivo aquí' : title}
          </Typography>
          {content ?? (
            <Typography variant="body2">
              Arrastra archivos aquí o da{' '}
              <span
                style={{
                  textDecoration: 'underline',
                  color: palette.primary.main,
                }}
              >
                clic
              </span>{' '}
              para subir desde tu dispositivo
            </Typography>
          )}

          {fileRejections.length ? (
            <Typography
              variant="body1"
              style={textStyles}
              color={palette.error.main}
            >
              {handleError(fileRejections[0])}
            </Typography>
          ) : null}
        </Box>
      </Stack>
    </Stack>
  );
};
