// @ts-check
import { Stack } from '@mui/material';
import { styled } from '@mui/system';

export const AvatarHover = styled(Stack)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: '#161C24',
  borderRadius: '50%',
  zIndex: 99,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: '0.6',
}));
