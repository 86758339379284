// @ts-check
import { useState } from 'react';
import {
  CardHeader,
  CardContent,
  CardActions,
  Box,
  Card,
  useTheme,
} from '@mui/material';
import { Button } from '../../../../newComponents/Button';
import { Typography } from '../../../../newComponents/Typography';
import { Tooltip } from '../../../../newComponents/Tooltip';
import { IconButton } from '../../../../newComponents/IconButton';
import { Label } from '../../../../newComponents/Label';
import { Upload } from '../../../../newComponents/Upload/Upload';
import { mimeTypes } from '../../../../utils/constants';
import { bytesToMB } from '../../../../utils/utils';
import { DocumentHistoryButton } from '../../../../businessComponents/DocumentHistory';
import { useUploadPendingDocument } from './useUploadPendingDocument';
import { Icon } from '../../../../components/Icons/Icons';
import { STEP_TYPES } from '../UserOnboarding.constants';
import {
  AcceptedFileWrapper,
  RejectedFileWrapper,
} from './PendingDocumentCard.styles';

/** @typedef {import('../../../../newComponents/Upload/FileUpload.types').FileRejection} FileRejection */

/** @param {import('./PendingDocumentsDialog.types').PendingDocumentCardProps} props */
export const PendingDocumentsCard = (props) => {
  /** @type {import('../../../../theme').CustomTheme} */
  const theme = useTheme();
  const { handleUploadPendingDocument } = useUploadPendingDocument();
  const [acceptedFile, setAcceptedFile] = useState(
    /** @type {File | null} */ (null),
  );
  const [rejectedFile, setRejectedFile] = useState(
    /** @type {FileRejection | null} */ (null),
  );

  const { pendingDocument, onDocumentUploaded } = props;
  const { _id, category, subcategory, status, history } = pendingDocument;

  if (status === 'PENDING_REVIEW') {
    return (
      <Card variant="outlined" sx={{ borderRadius: '16px', padding: '24px' }}>
        <CardHeader
          sx={{ padding: '0' }}
          avatar={<DocumentHistoryButton document={pendingDocument} />}
          title={<Typography variant="h6">{subcategory}</Typography>}
          subheader={
            <Box display="inline-block">
              <Label
                startIcon="eye_line"
                variant="soft"
                color="warning"
                label="Esperando revisión"
              />
            </Box>
          }
        />
      </Card>
    );
  }

  // get last rejected history
  const sortedHistory = [...history].reverse();
  const [lastRejectedHistory] = sortedHistory.filter(
    (event) => event.action === 'REJECTED',
  );

  return (
    <Card variant="outlined" sx={{ borderRadius: '16px', padding: '24px' }}>
      <CardHeader
        sx={{ padding: '0' }}
        avatar={<DocumentHistoryButton document={pendingDocument} />}
        title={<Typography variant="h6">{subcategory}</Typography>}
        subheader={
          <Box display="inline-block">
            {status === 'NOT_UPLOADED' ? (
              <Label
                startIcon="time_line"
                variant="soft"
                color="default"
                label="Pendiente de enviar"
              />
            ) : (
              <Tooltip
                title={`Motivo: ${lastRejectedHistory.comment}`}
                placement="right"
              >
                <span>
                  <Label
                    startIcon="close_circle_line"
                    variant="soft"
                    color="error"
                    label="Rechazado"
                  />
                </span>
              </Tooltip>
            )}
          </Box>
        }
      />
      <CardContent sx={{ padding: '20px 0px' }}>
        {rejectedFile !== null && (
          <RejectedFileWrapper onClick={() => setRejectedFile(null)}>
            <Typography variant="subtitle1" color={theme.newPalette.error.main}>
              {rejectedFile.file.name}
            </Typography>
            <Typography variant="caption" color={theme.newPalette.error.main}>
              {rejectedFile.errors.map((error) => error.message).join(' - ')}
            </Typography>
          </RejectedFileWrapper>
        )}
        {acceptedFile !== null && (
          <AcceptedFileWrapper>
            <IconButton
              icon="close_circle_line"
              color="error"
              onClick={() => setAcceptedFile(null)}
              size="small"
              sx={{ position: 'absolute', top: '8px', right: '8px' }}
            />
            <Typography
              variant="subtitle1"
              color={theme.newPalette.text.disabled}
              textAlign="center"
              sx={{ overflowWrap: 'break-word', width: '100%' }}
            >
              {acceptedFile.name}
            </Typography>
            <Typography
              variant="caption"
              color={theme.newPalette.text.disabled}
            >
              {bytesToMB(acceptedFile.size)} MB
            </Typography>
          </AcceptedFileWrapper>
        )}
        {rejectedFile === null && acceptedFile === null && (
          <Upload
            variant="blockFile"
            orientation="column"
            title="Carga o arrastra tu archivo aquí"
            iconSize="40px"
            accept={[...mimeTypes.image, mimeTypes.pdf]}
            onChange={(acceptedFiles, fileRejections) => {
              if (fileRejections.length > 0) {
                setRejectedFile(fileRejections[0]);
                setAcceptedFile(null);
                return;
              }
              if (acceptedFiles.length > 0) {
                setAcceptedFile(acceptedFiles[0]);
                setRejectedFile(null);
              }
            }}
            sx={{ width: '100%' }}
          />
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end', padding: '0' }}>
        <Button
          variant="contained"
          endIcon={<Icon icon="send_plane_line" />}
          disabled={!acceptedFile}
          onClick={() =>
            handleUploadPendingDocument({
              file: acceptedFile,
              _id,
              category,
              subcategory,
              onSuccess: () => {
                onDocumentUploaded();
                setAcceptedFile(null);
                sessionStorage.setItem(
                  STEP_TYPES.DOCUMENT_UPLOAD_COMPLETION_DIALOG,
                  'show',
                );
              },
            })
          }
        >
          Enviar a revisión
        </Button>
      </CardActions>
    </Card>
  );
};
