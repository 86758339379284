export const Wrapper = {
  backgroundColor: 'lightgray',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: 'auto',
  minHeight: '50vh',
};

export const HeaderWrapper = {
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 2,
  backgroundColor: 'white',
  display: 'flex',
  borderBottom: '1px solid lightgray',
  flexDirection: 'column',
};

export const LoaderWrapper = {
  minHeight: '50vh',
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ErrorWrapper = {
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};
