// @ts-check
import { useEffect } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { ALL_COMPANY_GROUPS } from '../../containers/Settings/Preferences/Groups/Groups.gql';
import { globalSnackbarVar } from '../../cache.reactiveVars';
import { GROUP_ROW, FIND_BY_GROUPS } from './EmployeesFinder.constants';
import { employeesFinderVar } from './EmployeesFinder.vars';

/**  @param {import('./EmployeesFinder.types').QueryHookProps} params */
export const useAllGroups = ({ searchValue }) => {
  const { groups, findBySelected } = useReactiveVar(employeesFinderVar);

  const [allCompanyGroups] = useLazyQuery(ALL_COMPANY_GROUPS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (response) => {
      if (response?.allCompanyGroups) {
        const { groups } = response.allCompanyGroups;
        const mapFunc = (row) => ({ ...row, type: GROUP_ROW });
        const newGroups = groups.map(mapFunc);
        employeesFinderVar({
          ...employeesFinderVar(),
          groups: newGroups,
          data: { options: newGroups, total: groups.length },
          loading: false,
        });
      }
    },
    onError: (error) => {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Hubo un error al obtener grupos',
        duration: 5000,
      });
    },
  });

  const handleFetchAllGroups = () => {
    allCompanyGroups({
      variables: {
        filter: { excludeEmpty: true, excludeEmployeesData: true },
      },
    });
  };

  useEffect(() => {
    if (findBySelected === FIND_BY_GROUPS) {
      const filteredGroups = groups.filter((group) =>
        group.name.toLowerCase().includes(searchValue.trim().toLowerCase()),
      );
      employeesFinderVar({
        ...employeesFinderVar(),
        data: { options: filteredGroups, total: filteredGroups.length },
        loading: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, findBySelected]);

  return {
    handleFetchAllGroups,
  };
};
