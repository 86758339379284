// @ts-check
import { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Stack, Box, List, Divider } from '@mui/material';
import { SimpleTemplateEmptySpace } from '../TemplateEmptySpace';
import { TemplateUpdateDialog } from '../TemplateUpdateDialog';
import { TemplateDeleteConfirmationDialog } from '../TemplateDeleteConfirmationDialog';
import { SimpleTemplatePermissionDenied } from '../TemplatePermissionDenied';
import { SearchForm } from '../../../../../components/Inputs/SearchForm';
import { usePermissionChecker } from '../../../../../Hooks';
import { TemplateListItem } from './TemplateListItem';
import type { TemplateListProps } from './TemplateList.types';
import type { TemplateRow, TemplateToUpdate } from '../Template.types';
import { useGetTemplate } from '../TemplateHooks';
import { ALL_TEMPLATES_FROM_LIST } from '../../gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
  refetchFunctionsVar,
} from '../../../../../cache.reactiveVars';

export const TemplateList = (props: TemplateListProps) => {
  const infiniteLoaderRef = useRef(null);
  const [allTemplates, setAllTemplates] = useState({ templates: [], total: 0 });
  const [templateSelected, setTemplateSelected] = useState(null);
  const [currentTemplate, setCurrentTemplate] =
    useState<TemplateToUpdate | null>(null);
  const [templatesEmpty, setTemplatesEmpty] = useState(false);
  const [search, setSearch] = useState('');
  const [showTemplateUpdateDialog, setShowTemplateUpdateDialog] =
    useState(false);
  const [
    showTemplateDeleteConfirmationDialog,
    setShowTemplateDeleteConfirmationDialog,
  ] = useState(false);
  const { handleGetTemplate } = useGetTemplate();
  const userHasPermissionToView = usePermissionChecker({
    permission: 'templates',
    action: 'read',
  });
  const userHasPermissionToCreateOrEdit = usePermissionChecker({
    permission: 'templates',
    action: 'update',
  });

  const {
    loading: allTemplatesLoading,
    refetch: refetchAllTemplates,
    fetchMore: fetchMoreTemplates,
  } = useQuery(ALL_TEMPLATES_FROM_LIST, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ allTemplates }) => {
      setTemplatesEmpty(allTemplates.total === 0);
      setAllTemplates(allTemplates);
    },
    onError: (err) => {
      // @ts-ignore
      if (err.networkError?.statusCode === 401) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Acceso denegado',
        });
      }
    },
  });

  const handleLoadMore = (startIndex) => {
    const newPage = Math.ceil(startIndex / 10);
    const rowsLoaded = allTemplates.templates.length;
    if (rowsLoaded === allTemplates.total) return;
    fetchMoreTemplates({ variables: { perPage: 10, page: newPage } });
  };

  useEffect(() => {
    if (refetchAllTemplates) {
      refetchFunctionsVar({
        ...refetchFunctionsVar(),
        refetchAllTemplates: () => {
          refetchAllTemplates();
          if (infiniteLoaderRef.current) {
            infiniteLoaderRef.current._listRef.scrollToItem(0);
          }
        },
      });
    }
  }, [refetchAllTemplates]);

  useEffect(() => {
    if (allTemplatesLoading) {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Obteniendo plantillas...',
      });
    } else {
      globalBackdropVar({ open: false });
    }
  }, [allTemplatesLoading]);

  useEffect(() => {
    refetchAllTemplates({ page: 0, perPage: 10, search });
  }, [search, refetchAllTemplates]);

  const handleDeleteAction = (row: TemplateRow) => {
    setTemplateSelected(row);
    setShowTemplateDeleteConfirmationDialog(true);
  };

  const handleEditAction = async (row: TemplateRow) => {
    await handleGetTemplate({
      templateId: row._id,
      onSuccess: (info) => {
        setShowTemplateUpdateDialog(true);
        setCurrentTemplate({
          _id: info._id,
          title: info.title,
          description: info.description,
          color: info.color.primary,
          secondaryColor: info.color.secondary,
          category: info.categories.category,
          subcategory: info.categories.subcategory,
          myEditor: info.htmlContent,
        });
      },
    });
  };

  const handleOpenAction = async (row: TemplateRow) => {
    await handleGetTemplate({
      templateId: row._id,
      onSuccess: (info) => props.onOpenTemplate(info),
    });
  };

  const handleChangeSearch = (newSearch) => setSearch(newSearch);

  if (!userHasPermissionToView) {
    return <SimpleTemplatePermissionDenied />;
  }

  if (templatesEmpty && search === '' && !allTemplatesLoading) {
    return <SimpleTemplateEmptySpace />;
  }

  return (
    <Box
      padding={3}
      borderRadius={2}
      boxShadow={(theme) => theme.newPalette.shadow.card}
      height="100%"
    >
      <Stack pb={2}>
        <SearchForm handleSearch={handleChangeSearch} />
      </Stack>
      <Divider />
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <List sx={{ height: 400, maxWidth: 296 }}>
          <InfiniteLoader
            ref={infiniteLoaderRef}
            isItemLoaded={(index) => index < allTemplates.templates.length}
            itemCount={allTemplates.total}
            threshold={0}
            loadMoreItems={handleLoadMore}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                height={400}
                width={296}
                itemSize={64}
                itemCount={allTemplates.total}
                ref={ref}
                onItemsRendered={onItemsRendered}
              >
                {({ index, style }) => (
                  <TemplateListItem
                    style={style}
                    row={allTemplates.templates[index]}
                    handleEditAction={handleEditAction}
                    handleDeleteAction={handleDeleteAction}
                    handleOpenAction={handleOpenAction}
                    userHasPermissionToCreateOrEdit={Boolean(
                      userHasPermissionToCreateOrEdit,
                    )}
                  />
                )}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        </List>
      </Stack>
      <TemplateUpdateDialog
        showDialog={showTemplateUpdateDialog}
        closeDialog={() => setShowTemplateUpdateDialog(false)}
        currentTemplate={currentTemplate}
        updateType="CONTENT"
      />
      <TemplateDeleteConfirmationDialog
        showDialog={showTemplateDeleteConfirmationDialog}
        closeDialog={() => setShowTemplateDeleteConfirmationDialog(false)}
        templateId={templateSelected?._id}
        onSuccess={() => {
          setTemplateSelected(null);
          refetchAllTemplates();
          if (infiniteLoaderRef.current) {
            infiniteLoaderRef.current._listRef.scrollToItem(0);
          }
        }}
      />
    </Box>
  );
};
