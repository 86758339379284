import type { Variable } from './InsertVariablesButton.types';

export const VARIABLES: Variable[] = [
  {
    text: '[Nombre del Empleado]',
    variable: 'Nombre_del_Empleado',
    category: 'employee',
    color: 'success',
  },
  {
    text: '[RFC del Empleado]',
    variable: 'RFC_del_Empleado',
    category: 'employee',
    color: 'success',
  },
  {
    text: '[No. de nómina del Empleado]',
    variable: 'No_nomina_del_Empleado',
    category: 'employee',
    color: 'success',
  },
  {
    text: '[Puesto del Empleado]',
    variable: 'Puesto_del_Empleado',
    category: 'employee',
    color: 'success',
  },
  {
    variable: 'Centro_de_Trabajo_del_Empleado',
    text: '[Centro de Trabajo del Empleado]',
    category: 'employee',
    color: 'success',
  },
  {
    text: '[Nombre de la Empresa]',
    variable: 'Nombre_de_la_Empresa',
    category: 'company',
    color: 'warning',
  },
];
