//@ts-check
import React, { useCallback, useEffect, useState } from 'react';
import { usePermissionChecker, useScreenSize } from '../../../../Hooks';
import {
  documentsTableFiltersVar,
  documentsTableSelectedTasksVar,
  resetDocumentsTableSelectedTasksVar,
  resetDocumentsTableSortingAndPagingVar,
  setDocumentsTableFiltersVar,
} from './DocumentsTable.vars';
import { InputAdornment } from '@mui/material';
import Searchbar from '../../../../components/Inputs/Searchbar';
import { Tooltip } from '../../../../newComponents/Tooltip';
import { FilterButton } from '../../../../components/Buttons/FilterButton';
import { Button } from '../../../../newComponents/Button';
import { Icon } from '../../../../components/Icons/Icons';
import SifeIFrameButton from '../../../../components/SifeIFrameButton/SifeIFrameButton';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import {
  documentsTableColumnsDialogVar,
  setDocumentsTableColumnsDialogVar,
} from './DocumentsTableColumnsDialog/DocumentsTableColumnsDialog.vars';
import { definedDateRangesList } from '../../../../components/DateRange/constants';
import { compareDates } from '../../../../utils/utils';
import DateRangeInput from '../../../../components/DateRange/DateRangeInput';
import DateRangeDialog from '../../../../components/DateRange/DateRangeDialog';
import { DocumentsTableAdvancedFiltersDialog } from './DocumentsTableAdvancedFiltersDialog/DocumentsTableAdvancedFiltersDialog';
import { setDocumentsTableAdvancedFiltersDialogVar } from './DocumentsTableAdvancedFiltersDialog/DocumentsTableAdvancedFiltersDialog.vars';
import { IconButton } from '../../../../newComponents/IconButton';
import { DeleteDocumentsDialog } from './DeleteDocumentsDialog/DeleteDocumentsDialog';
import { globalHideDocumentsDialogVar } from '../../../../cache.reactiveVars';
import { HideMultipleDocumentsModal } from '../../../../components/Dialogs/HideDocumentsDialog';
import {
  GET_COMPANY_DOCUMENTS,
  GET_COMPANY_DOCUMENTS_COUNTERS,
} from './DocumentsTable.gql';
import { EmployeeRecordTagsFilter } from './DocumentsTableAdvancedFiltersDialog/EmployeeRecordTagsFilter';
import { DocumentsHeaderButtons } from '../DocumentsHeader';
import { SORA_MODULES } from '../../../../components/ModuleBlockableComponent/ModuleBlockable.constants';
import { useBlockableActions } from '../../../../components/ModuleBlockableComponent/useBlockableActions';
import { TableHeaderDefaultFiltersWrapper } from '../../../../layoutComponents/Table/TableHeaderWrappers/TableHeaderDefaultFiltersWrapper';
import { TableHeaderActionsWrapper } from '../../../../layoutComponents/Table/TableHeaderWrappers/TableHeaderActionsWrapper';
import { TableHeaderWrapper } from '../../../../layoutComponents/Table/TableHeaderWrappers/TableHeaderWrapper';

export function DocumentsTableHeader({
  handleSignDocummentSuccess,
  handleDeleteDocuments,
  disableSearchBar,
  employeeData = null,
  hideColumnsButton,
  hideFiltersButton,
  showEmployeeRecordTagsFilter,
  showTitleButtons,
}) {
  const {
    selectedTasks,
    signableSelectedTasks,
    deletableSelectedTasks,
    hideableSelectedTasks,
  } = useReactiveVar(documentsTableSelectedTasksVar);
  const {
    isModuleAvailable: isDocumentsModuleAvailable,
    onClick: moduleBlockedOnClick,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);

  const { modified } = useReactiveVar(documentsTableFiltersVar);

  const [inputValue, setInputValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  const continuousEndDate = false;
  const defaultDateFilter = definedDateRangesList(continuousEndDate).historic;
  const [dateFilter, setDateFilter] = useState(defaultDateFilter);
  const [validityDateFilter, setValidityDateFilter] =
    useState(defaultDateFilter);
  const [dateDialog, setDateDialog] = useState(false);
  const [validityDateDialog, setValidityDateDialog] = useState(false);

  const client = useApolloClient();

  const onSearch = (e) => {
    let { value } = e.target;
    setInputValue(value);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  useEffect(() => {
    setDocumentsTableFiltersVar({ search: debouncedValue });
  }, [debouncedValue]);

  const dateFilterIsActive = useCallback(() => {
    const isDateDifferent = !compareDates(dateFilter, defaultDateFilter);
    return isDateDifferent;
  }, [dateFilter, defaultDateFilter]);

  const validityDateFilterIsActive = useCallback(() => {
    const isDateDifferent = !compareDates(
      validityDateFilter,
      defaultDateFilter,
    );
    return isDateDifferent;
  }, [validityDateFilter, defaultDateFilter]);

  const [permissionToSignDocs, permissionToUpdateDocs] = usePermissionChecker([
    { permission: 'signDocs', action: 'update' },
    { permission: 'docs', action: 'update' },
  ]);

  const handleOpenColumnsDialog = () => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    setDocumentsTableColumnsDialogVar({
      open: !documentsTableColumnsDialogVar().open,
    });
  };

  const handleChangeDate = (dateFilter) => {
    client.cache.evict({ fieldName: 'getCompanyDocuments' });
    resetDocumentsTableSelectedTasksVar();
    resetDocumentsTableSortingAndPagingVar();
    setDateFilter(dateFilter);
    setDocumentsTableFiltersVar({
      startDate: dateFilter.start,
      endDate: dateFilter.end,
    });
  };

  const handleChangeValidityDate = (dateFilter) => {
    client.cache.evict({ fieldName: 'getCompanyDocuments' });
    resetDocumentsTableSelectedTasksVar();
    resetDocumentsTableSortingAndPagingVar();
    setValidityDateFilter(dateFilter);
    setDocumentsTableFiltersVar({
      validity: {
        start: dateFilter.start,
        end: dateFilter.end,
      },
    });
  };

  const handleChangeDocumentsVisibility = () => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    globalHideDocumentsDialogVar({
      open: true,
      selectedTask: hideableSelectedTasks,
      refetch: async () => {
        await client.refetchQueries({
          include: [GET_COMPANY_DOCUMENTS, GET_COMPANY_DOCUMENTS_COUNTERS],
        });
      },
    });
  };

  const handleRemoveDateFilter = (event) => {
    event.stopPropagation();
    resetDocumentsTableSelectedTasksVar();
    resetDocumentsTableSortingAndPagingVar();
    setDateFilter(defaultDateFilter);
    setDocumentsTableFiltersVar({
      startDate: null,
      endDate: null,
    });
  };

  const handleRemoveValidityDateFilter = (event) => {
    event.stopPropagation();
    resetDocumentsTableSelectedTasksVar();
    resetDocumentsTableSortingAndPagingVar();
    setValidityDateFilter(defaultDateFilter);
    setDocumentsTableFiltersVar({
      validity: {
        start: null,
        end: null,
      },
    });
  };

  const handleOpenAdvancedFiltersDialog = () => {
    setDocumentsTableAdvancedFiltersDialogVar({ open: true });
  };

  const requestedDocuments = !permissionToSignDocs
    ? []
    : signableSelectedTasks.map((documentId) => {
        return { type: 'DOCUMENT', id: documentId };
      });

  const { xs } = useScreenSize();
  const isDateFilterActive = dateFilterIsActive();
  const isValidityDateFilterActive = validityDateFilterIsActive();
  return (
    <>
      <TableHeaderWrapper>
        <TableHeaderDefaultFiltersWrapper>
          <DateRangeInput
            dateFilter={dateFilter}
            continuousEndDate={continuousEndDate}
            onClick={() => setDateDialog(true)}
            label="Fecha de carga"
            size="small"
            sx={{
              minWidth: '200px',
              maxWidth: xs ? '100%' : '200px',
            }}
            InputProps={{
              sx: {
                '& fieldset': { border: 'none' },
                cursor: 'pointer',
                '& *': {
                  cursor: 'pointer',
                },
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="calendar_line" height="20px" color="text" />
                </InputAdornment>
              ),
              endAdornment: isDateFilterActive && (
                <InputAdornment position="end">
                  <Icon
                    icon="filter_off_line"
                    height="20px"
                    color="error"
                    onClick={handleRemoveDateFilter}
                    pointer
                  />
                </InputAdornment>
              ),
            }}
          />
          <DateRangeInput
            dateFilter={validityDateFilter}
            continuousEndDate={continuousEndDate}
            onClick={() => setValidityDateDialog(true)}
            label="Fecha de vencimiento"
            size="small"
            sx={{
              maxWidth: '200px',
            }}
            InputProps={{
              sx: {
                '& fieldset': { border: 'none' },
                cursor: 'pointer',
                '& *': {
                  cursor: 'pointer',
                },
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="calendar_line" height="20px" color="text" />
                </InputAdornment>
              ),
              endAdornment: isValidityDateFilterActive && (
                <InputAdornment position="end">
                  <Icon
                    icon="filter_off_line"
                    height="20px"
                    color="error"
                    onClick={handleRemoveValidityDateFilter}
                    pointer
                  />
                </InputAdornment>
              ),
            }}
          />
          {showEmployeeRecordTagsFilter && (
            <EmployeeRecordTagsFilter
              autoCompletePops={{
                size: 'small',
                sx: { minWidth: '200px' },
              }}
              tagProps={{ size: 'small' }}
              autoAssignFilter
              noTitle
              fullWidth={xs}
            />
          )}
          <Searchbar
            placeholder="Buscar"
            onChange={onSearch}
            name="search"
            value={inputValue}
            setInputValue={setInputValue}
            sx={{
              minWidth: '200px',
              flexGrow: 1,
              maxWidth: '520px',
            }}
            disabled={disableSearchBar}
          />
        </TableHeaderDefaultFiltersWrapper>
        <DocumentsTableHeaderActions
          employeeData={employeeData}
          hideColumnsButton={hideColumnsButton}
          hideFiltersButton={hideFiltersButton}
          showTitleButtons={showTitleButtons}
          selectedTasks={selectedTasks}
          hideableSelectedTasks={hideableSelectedTasks}
          handleChangeDocumentsVisibility={handleChangeDocumentsVisibility}
          deletableSelectedTasks={deletableSelectedTasks}
          permissionToUpdateDocs={permissionToUpdateDocs}
          handleDeleteDocuments={handleDeleteDocuments}
          signableSelectedTasks={signableSelectedTasks}
          permissionToSignDocs={permissionToSignDocs}
          handleSignDocummentSuccess={handleSignDocummentSuccess}
          modified={modified}
          handleOpenAdvancedFiltersDialog={handleOpenAdvancedFiltersDialog}
          handleOpenColumnsDialog={handleOpenColumnsDialog}
          requestedDocuments={requestedDocuments}
        />
      </TableHeaderWrapper>
      <DateRangeDialog
        open={dateDialog}
        setOpen={setDateDialog}
        setDateFilter={handleChangeDate}
        initialSelection={dateFilter}
        continuousEndDate={continuousEndDate}
      />
      <DateRangeDialog
        open={validityDateDialog}
        setOpen={setValidityDateDialog}
        setDateFilter={handleChangeValidityDate}
        initialSelection={validityDateFilter}
        continuousEndDate={continuousEndDate}
      />
      <DocumentsTableAdvancedFiltersDialog />
      <DeleteDocumentsDialog />
      <HideMultipleDocumentsModal />
    </>
  );
}

function DocumentsTableHeaderActions({
  employeeData,
  showTitleButtons,
  hideColumnsButton,
  hideFiltersButton,
  selectedTasks,
  hideableSelectedTasks,
  handleChangeDocumentsVisibility,
  deletableSelectedTasks,
  permissionToUpdateDocs,
  handleDeleteDocuments,
  signableSelectedTasks,
  permissionToSignDocs,
  handleSignDocummentSuccess,
  modified,
  handleOpenAdvancedFiltersDialog,
  handleOpenColumnsDialog,
  requestedDocuments,
}) {
  const {
    isModuleAvailable: isDocumentsModuleAvailable,
    onClick: moduleBlockedOnClick,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);

  if (showTitleButtons && !selectedTasks.length) {
    return <DocumentsHeaderButtons allColumns employeeData={employeeData} />;
  }

  const shouldShowSignButton = signableSelectedTasks?.length !== 0;
  const shouldShowHideButton = hideableSelectedTasks?.length !== 0;
  const shouldShowDeleteButton = deletableSelectedTasks?.length !== 0;

  const shouldShowActions =
    shouldShowSignButton || shouldShowHideButton || shouldShowDeleteButton;

  hideFiltersButton = hideFiltersButton || shouldShowActions;
  hideColumnsButton = hideColumnsButton || shouldShowActions;

  return (
    <TableHeaderActionsWrapper>
      {hideableSelectedTasks?.length !== 0 && (
        <IconButton
          color="text"
          onClick={handleChangeDocumentsVisibility}
          icon="eye_off_line"
          tooltipText={
            !permissionToUpdateDocs
              ? 'Necesitas permisos para esta acción'
              : 'Ocultar/Mostrar documentos al empleado tras firma'
          }
          tooltipArrow
          tooltipPlacement="top"
        />
      )}
      {deletableSelectedTasks?.length !== 0 && (
        <Button
          color="error"
          onClick={handleDeleteDocuments}
          variant="outlined"
          tooltipText={
            !permissionToUpdateDocs
              ? 'Necesitas permisos para esta acción'
              : 'Eliminar documentos'
          }
          tooltipArrow
          tooltipPlacement="top"
        >
          Eliminar ({deletableSelectedTasks?.length})
        </Button>
      )}
      {signableSelectedTasks?.length !== 0 && (
        <Tooltip
          arrow
          placement="top"
          title={!permissionToSignDocs && 'Necesitas permisos para esta acción'}
        >
          <span>
            <SifeIFrameButton
              disabled={!permissionToSignDocs}
              requestedDocuments={requestedDocuments}
              onSuccess={handleSignDocummentSuccess}
              signLabel={`Firmar (${signableSelectedTasks?.length})`}
              height="46px"
              shouldBlockOnSign={!isDocumentsModuleAvailable}
              onClickModuleBlocked={moduleBlockedOnClick}
            />
          </span>
        </Tooltip>
      )}
      {!hideFiltersButton && (
        <Tooltip
          title="Haz un filtro con diferentes centros de trabajo, tipos de documento y firmantes."
          placement="top"
          arrow
        >
          <span>
            <FilterButton
              active={modified}
              onClick={handleOpenAdvancedFiltersDialog}
            />
          </span>
        </Tooltip>
      )}
      {!hideColumnsButton && (
        <IconButton
          color="text"
          onClick={handleOpenColumnsDialog}
          icon="table_line"
          tooltipText="Editar columnas"
          tooltipArrow
          tooltipPlacement="top"
        />
      )}
    </TableHeaderActionsWrapper>
  );
}
