import { Stack } from '@mui/material';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Avatar } from '../../../../../newComponents/Avatar';
import { Typography } from '../../../../../newComponents/Typography';
import { Document as DocumentIcon } from '../../../../../components/Icons/Custom';
import type { ColumnsInput } from './TemplateTable.types';
import type { TemplateRow } from '../Template.types';
import { formatDate } from '../../../../../utils/utils';

export const columns = (input: ColumnsInput): GridColumns<TemplateRow> => {
  return [
    {
      field: 'title',
      headerName: 'Nombre de la plantilla',
      flex: 1,
      minWidth: 300,
      sortable: false,
      renderCell: (params) => {
        const { color, title } = params.row;
        return (
          <Stack
            direction="row"
            alignItems="center"
            onClick={() => input.handleEditContentAction(params.row)}
            sx={{ cursor: 'pointer' }}
          >
            <DocumentIcon
              svgProps={{
                style: { marginRight: '16px' },
                width: '32px',
              }}
              primaryColor={color.primary}
              secondaryColor={color.secondary}
            />
            <Typography variant="body2">{title}</Typography>
          </Stack>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Última modificación',
      width: 270,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const { timestamps } = params.row;
        return formatDate(timestamps.updatedAt, 'humanShort');
      },
    },
    {
      field: 'createdBy',
      headerName: 'Creador',
      width: 270,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const { creatorProfilePicture, creatorFullName } = params.row;
        return (
          <Avatar src={creatorProfilePicture} tooltip={creatorFullName}>
            {creatorFullName?.charAt(0)}
          </Avatar>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: (params: GridRowParams) => [
        <IconButton
          key="edit"
          icon="edit_line"
          size="medium"
          onClick={() => input.handleEditSettingsAction(params.row)}
          disabled={!input.userHasPermissionToCreateOrEdit}
          sx={{ visibility: 'hidden' }}
          tooltipText={
            !input.userHasPermissionToCreateOrEdit &&
            'No cuentas con los permisos para esta acción'
          }
          tooltipArrow
          tooltipPlacement="top"
        />,
        <IconButton
          key="edit"
          icon="delete_bin_5_line"
          size="medium"
          onClick={() => input.handleDeleteAction(params.row)}
          disabled={!input.userHasPermissionToCreateOrEdit}
          color="error"
          sx={{ visibility: 'hidden' }}
          tooltipText={
            !input.userHasPermissionToCreateOrEdit &&
            'No cuentas con los permisos para esta acción'
          }
          tooltipArrow
          tooltipPlacement="top"
        />,
      ],
    },
  ];
};
