import { documentCopies, noticieCopies, receiptCopies } from './const';

export const getRandomDialogContent = ({ signable, type }) => {
  let content = [];
  if (type === 'documento') {
    content = signable ? documentCopies : noticieCopies;
  } else {
    content = receiptCopies;
  }

  const index = getRandomInt(content.length);

  return content[index];
};

const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};
