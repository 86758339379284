import React from 'react';
import { createTheme } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import GlobalStyle from './GlobalStyle';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import PrivateRoute from './utils/PrivateRoute';
import Login from './containers/Login/Login';
import PasswordRecovery from './containers/Login/Recovery/PasswordRecovery';
import { Support } from './containers/Login/Recovery/Support';
import Landing from './containers/Landing/Landing';
import PasswordReset from './containers/Login/Recovery/PasswordReset';
import ChangeOTP from './containers/Login/Display/ChangeOTP';
import CreatePassword from './containers/Login/Display/CreatePassword';
import { GlobalSnackbar } from './newComponents/Snackbar';
import { GlobalBackdrop } from './newComponents/Backdrop';
import GlobalSessionWillExpireDialog from './components/Dialogs/GlobalSessionWillExpireDialog';
import { getDesignTokens } from './theme';
import { SupportDialog } from './businessComponents/SupportDialog';
import { ErrorDialog } from './businessComponents/ErrorDialog';
import CreateAccount from './containers/CreateAccount/CreateAccount';
import CreateAccountMailSent from './containers/CreateAccount/CreateAccountMailSent';
import { CompleteContactInfo } from './containers/Login/Display/CompleteContactInfo/CompleteContactInfo';
import LinkedLogin from './containers/Login/LinkedLogin';
import { FinishContactVerification } from './containers/Login/Display/CompleteContactInfo/FinishContactVerification';
import { GenericDialog } from './containers/Landing/Dialogs/GenericDialog';
import { DefaultErrorComponent } from './components/ErrorBoundary/ErrorBoundary';
import { ErrorBoundary } from '@sentry/react';
import { useReactiveVar } from '@apollo/client';
import WidgetChat from './components/Widgets/WidgetChat';
import { GlobalDownloadSnackbar } from './components/Snackbar/GlobalDownloadSnackbar';
import { preventRefreshPage } from './utils/preventRefreshPage';
import { usePendingDownloads } from './Hooks/usePendingDownloads';
import { ValidateXML } from './containers/ValidateXml/ValidateXml';
import { PrivateDocumentDetails } from './containers/FileDetails/PrivateDocumentDetails/PrivateDocumentDetails';
import { PublicReceiptDetails } from './containers/FileDetails/PublicReceiptDetails/PublicReceiptDetails';
import { PublicDocumentDetails } from './containers/FileDetails/PublicDocumentDetails/PublicDocumentDetails';

import {
  globalDownloadSnackbarVar,
  globalSessionWillExpireDialogVar,
} from './cache.reactiveVars';

const App = () => {
  const enableSignup = localStorage.getItem('enableSignup') || 'false';
  const isEnableSignup = enableSignup === 'true';
  const confirmDialog = useReactiveVar(globalSessionWillExpireDialogVar);
  const downloadSnackbar = useReactiveVar(globalDownloadSnackbarVar);
  const showDownloadSnackbar = Boolean(downloadSnackbar?.downloads?.length);
  const isTherePendingDownloads = usePendingDownloads();
  preventRefreshPage(isTherePendingDownloads);
  const theme = createTheme(getDesignTokens('light'));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ErrorBoundary fallback={<DefaultErrorComponent />}>
          <BrowserRouter>
            <WidgetChat />
            <GlobalStyle />
            <GlobalSnackbar />
            {showDownloadSnackbar && <GlobalDownloadSnackbar />}
            {confirmDialog.open && <GlobalSessionWillExpireDialog />}
            <SupportDialog />
            <ErrorDialog />
            <GenericDialog />
            <GlobalBackdrop />
            <Switch>
              {isEnableSignup && (
                <Route exact path="/create-account">
                  <CreateAccount />
                </Route>
              )}
              <Route exact path="/create-account/mail-sent">
                <CreateAccountMailSent />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/linked-login/:user/:token">
                <LinkedLogin />
              </Route>
              <Route exact path="/login/recuperacion">
                <PasswordRecovery />
              </Route>
              <Route exact path="/reset/:rfc/:token">
                <PasswordReset />
              </Route>
              <Route exact path="/cambiar-contraseña-temporal">
                <ChangeOTP />
              </Route>
              <Route exact path="/completa-tus-datos">
                <CompleteContactInfo />
              </Route>
              <Route exact path="/finish-contact-verification">
                <FinishContactVerification />
              </Route>
              <Route exact path="/activate/:rfc/:token">
                <CreatePassword />
              </Route>
              <Route exact path="/login/soporte">
                <Support />
              </Route>
              <Route exact path="/recibos/:companyId/:rfc">
                {/* Receives receiptId as id in query params*/}
                <PublicReceiptDetails />
              </Route>
              <Route exact path="/documentos/:docId">
                <PublicDocumentDetails />
              </Route>
              <PrivateRoute path={'/documentos-privados/:docId'}>
                {/** Works for receipts and documents */}
                <PrivateDocumentDetails />
              </PrivateRoute>
              <Route exact path="/validate-xml">
                <ValidateXML />
              </Route>
              <PrivateRoute path="/">
                <Landing />
              </PrivateRoute>
            </Switch>
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
