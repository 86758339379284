// @ts-check
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Stack } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { convertMiniature } from '../../utils/image';
import { Typography } from '../../newComponents/Typography';
import { globalSnackbarVar } from '../../cache.reactiveVars';
import { CropperDialog } from '../../components/ProfilePicture/CropperDialog';
import { HelpDialog } from '../../components/ProfilePicture/HelpDialog';
import {
  ProfileImageWrapper,
  ProfileImage,
  ProfileImageEmpty,
} from './ProfilePictureSelector.styles';

/** @param {import('./ProfilePictureSelector.types').ProfilePictureSelectorProps} props */
export const ProfilePictureSelector = (props) => {
  const { onPictureChange } = props;

  const [cropper, setCropper] = useState(
    /** @type {import('cropperjs').default | null} */
    (null),
  );
  const [droppedPicture, setDroppedPicture] = useState('');
  const [croppedPicture, setCroppedPicture] = useState(
    /** @type {Blob | null} */
    (null),
  );
  const [openCropperDialog, setOpenCropperDialog] = useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = async () => {
      const resizedImg = await convertMiniature(
        { dataUrl: reader.result },
        600,
      );
      setDroppedPicture(resizedImg.dataUrl);
      setOpenCropperDialog(true);
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/jpg, image/png',
    maxFiles: 1,
    onDropAccepted: onDrop,
    onDropRejected: () => {
      globalSnackbarVar({
        show: true,
        message: 'El archivo seleccionado no es una imagen válida',
        severity: 'error',
      });
    },
  });

  const getCroppedImage = () => {
    if (cropper !== null) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        if (!blob) return;
        setCroppedPicture(blob);
        onPictureChange(blob);
      });
      return setOpenCropperDialog(false);
    }
    return null;
  };

  return (
    <ProfileImageWrapper>
      <input {...getInputProps()} />
      <Stack {...getRootProps()} sx={{ width: '100%', height: '100%' }}>
        {croppedPicture ? (
          <ProfileImage
            src={URL.createObjectURL(croppedPicture)}
            alt="Imagen de perfil seleccionada"
          />
        ) : (
          <ProfileImageEmpty>
            <Icon icon="add_a_photo_line" color="grey" />
            <Typography variant="caption" color="grey">
              Subir foto
            </Typography>
          </ProfileImageEmpty>
        )}
      </Stack>
      <CropperDialog
        openCropperDialog={openCropperDialog}
        setOpenCropperDialog={setOpenCropperDialog}
        setOpenHelpDialog={setOpenHelpDialog}
        droppedPicture={droppedPicture}
        setCropper={setCropper}
        getCroppedImage={getCroppedImage}
        resizable={false}
        type="circular"
        dragMode="none"
      />
      <HelpDialog
        openHelpDialog={openHelpDialog}
        setOpenHelpDialog={setOpenHelpDialog}
      />
    </ProfileImageWrapper>
  );
};
