// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { Tooltip } from '../../newComponents/Tooltip';
import { Typography } from '../../newComponents/Typography';
import { GroupAvatar } from '../GroupAvatar';

const defaultSx = {
  width: '300px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

/** @param {import('./GroupListItem.types').GroupListItemProps} props */
export const GroupListItem = (props) => {
  const {
    color,
    name,
    secondaryText,
    AvatarProps = {},
    PrimaryTextProps = {},
    SecondaryTextProps = {},
  } = props;

  const { sx: primaryTextSx, ...primaryTextProps } = PrimaryTextProps;
  const { sx: secondaryTextSx, ...secondaryTextProps } = SecondaryTextProps;

  return (
    <Stack direction="row" alignItems="center" gap={2} width="100%">
      <GroupAvatar color={color} {...AvatarProps} />
      <Stack>
        <Tooltip title={name} placement="top">
          <span>
            <Typography
              variant="subtitle2"
              {...primaryTextProps}
              sx={[
                defaultSx,
                ...(Array.isArray(primaryTextSx)
                  ? primaryTextSx
                  : [primaryTextSx]),
              ]}
            >
              {name}
            </Typography>
          </span>
        </Tooltip>
        {secondaryText && (
          <Tooltip title={secondaryText} placement="top">
            <span>
              <Typography
                variant="body2"
                color="text.secondary"
                {...secondaryTextProps}
                sx={[
                  defaultSx,
                  ...(Array.isArray(secondaryTextSx)
                    ? secondaryTextSx
                    : [secondaryTextSx]),
                ]}
              >
                {secondaryText}
              </Typography>
            </span>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};
