//@ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { useReactiveVar } from '@apollo/client';
import {
  documentsTableAdvancedFiltersDialogVar,
  setDocumentsTableAdvancedFiltersDialogVar,
} from './DocumentsTableAdvancedFiltersDialog.vars';
import { EmployeeRecordTagsSelector } from '../../../../../businessComponents/EmployeeRecordTagsSelector/EmployeeRecordTagsSelector';
import { setDocumentsTableFiltersVar } from '../DocumentsTable.vars';
import { debounceInput } from '../../../../../utils/time.utils';

/**
 * @typedef {import('../../../../../businessComponents/EmployeeRecordTagsSelector/EmployeeRecordTagsSelector.helpers').FlatEmployeeRecordTag} FlatEmployeeRecordTag
 */

export function EmployeeRecordTagsFilter({
  autoCompletePops = {},
  tagProps = {},
  autoAssignFilter = false,
  noTitle = false,
  fullWidth = false,
}) {
  const { employeeRecordTags } = useReactiveVar(
    documentsTableAdvancedFiltersDialogVar,
  );

  const debouncedSetDocumentsTableFiltersVar = debounceInput(
    setDocumentsTableFiltersVar,
    1200,
  );

  /**
   *
   * @param {*} event
   * @param {FlatEmployeeRecordTag[]} value
   */
  const handleOnSelectTag = (event, value) => {
    let newSelectedRows = [...employeeRecordTags];

    value.forEach((selectedOption) => {
      if (
        !employeeRecordTags.some(
          (tag) => tag.combinedId === selectedOption.combinedId,
        )
      ) {
        newSelectedRows.push(selectedOption);
      }
    });

    employeeRecordTags.forEach((selectedTag) => {
      if (
        !value.some((option) => option.combinedId === selectedTag.combinedId)
      ) {
        newSelectedRows = newSelectedRows.filter(
          (tag) => tag.combinedId !== selectedTag.combinedId,
        );
      }
    });

    if (autoAssignFilter) {
      setDocumentsTableAdvancedFiltersDialogVar({
        employeeRecordTags: newSelectedRows,
      });
      return debouncedSetDocumentsTableFiltersVar({
        employeeRecordTags: newSelectedRows.map((tag) => ({
          category: tag.category,
          subcategory: tag.subcategory,
        })),
      });
    }
    setDocumentsTableAdvancedFiltersDialogVar({
      employeeRecordTags: newSelectedRows,
    });
  };

  return (
    <Stack
      spacing={1}
      sx={{
        width: fullWidth ? '100%' : 'auto',
      }}
    >
      {!noTitle && (
        <Typography variant="body2" color="grey">
          Tipos de documento
        </Typography>
      )}
      <EmployeeRecordTagsSelector
        selectedTags={employeeRecordTags}
        onSelectTag={handleOnSelectTag}
        isRowSelected={(tag) =>
          employeeRecordTags.some(
            (selectedTag) => selectedTag.combinedId === tag.combinedId,
          )
        }
        {...autoCompletePops}
        tagProps={tagProps}
      />
    </Stack>
  );
}
