import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { environment } from '../../../API/instance/createInstance';
import DocumentSigner from '../../../components/DocumentSigner/DocumentSigner';
import { letterConstants } from '../../../utils/constants';
import logout from '../../../utils/logout';
import { useHistory } from 'react-router-dom';
import {
  currentCompanyVar,
  globalSnackbarVar,
  pendingDocsVar,
} from '../../../cache.reactiveVars';

const OnboardingDocument = ({
  shouldShowDialogs,
  setShouldShowDialogs,
  onboardingDocs,
}) => {
  const [open, setOpen] = useState(true);
  const pendingDocs = useReactiveVar(pendingDocsVar);
  const currentCompany = useReactiveVar(currentCompanyVar);
  const history = useHistory();

  const getSortedIndexDoc = () => {
    let indexTratamientoDatos = onboardingDocs?.findIndex(
      (document) => document.constantType === letterConstants.treatmentLetter,
    );
    let indexCartaLaboral = onboardingDocs?.findIndex(
      (document) => document.constantType === letterConstants.workingLetter,
    );

    if (indexTratamientoDatos >= 0) return indexTratamientoDatos;
    if (indexCartaLaboral >= 0) return indexCartaLaboral;
    return 0;
  };

  let currentDocIndex = getSortedIndexDoc();
  const currentDoc = onboardingDocs[currentDocIndex];
  const signLabel =
    currentDoc.constantType === 'CARTA_TRATAMIENTO_DATOS_PERSONALES'
      ? 'Continuar'
      : 'Firmar';

  const handleClose = () => {
    if (environment === 'dev' || environment === 'prod') return;
    setShouldShowDialogs({
      ...shouldShowDialogs,
      askForOnboarding: false,
    });
    setOpen(false);
  };

  const handleCloseConfirmDialog = () => {
    if (environment === 'dev' || environment === 'prod') {
      logout(history);
      return;
    }
    handleClose();
  };

  const handleSuccessfulSign = (docIds) => {
    const newOnboardingDocs = [...(pendingDocs?.onboardingDocs || [])];
    //Revisa los contratos por la compañia actual
    const currentOnboardingDocs = onboardingDocs.filter(
      (doc) => doc?._id !== docIds[0]?.id,
    );

    //Revisa los contratos por todas las compañias y los va filtrando una vez firmado
    const filteredNewOnboard = newOnboardingDocs.filter(
      (doc) => doc?._id !== docIds[0]?.id,
    );
    const newPendingDocsVars = {
      ...pendingDocs,
      onboardingDocs: filteredNewOnboard,
    };
    pendingDocsVar(newPendingDocsVars);
    localStorage.pendingDocs = JSON.stringify(newPendingDocsVars);
    setOpen(false);
    if (currentOnboardingDocs?.length) {
      setTimeout(() => {
        setOpen(true);
      }, 500);
    }
    if (!currentOnboardingDocs?.length) {
      globalSnackbarVar({
        show: true,
        status: 'success',
        message: 'Documentos firmados correctamente',
      });
      setShouldShowDialogs({
        ...shouldShowDialogs,
        askForOnboarding: false,
      });
    }
  };

  if (!onboardingDocs?.length) return null;
  return (
    <>
      {open && (
        <DocumentSigner
          handleCloseConfirmDialog={handleCloseConfirmDialog}
          pdf={currentDoc?.type === 'pdf'}
          docId={currentDoc?._id}
          title={currentDoc?.title}
          documentUpdated={currentDoc?.updated}
          company={currentCompany?.clientName}
          signed={false}
          signable
          requestType="DOCUMENT"
          onSuccess={handleSuccessfulSign}
          open={open}
          handleClose={handleClose}
          showCloseButton={false}
          signLabel={signLabel}
          message={
            currentDoc?.updated
              ? 'Documento actualizado'
              : 'Firma el documento para continuar'
          }
        />
      )}
    </>
  );
};

export default OnboardingDocument;
