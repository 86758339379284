import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { InputAdornment, Stack } from '@mui/material';
import { useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../components/Buttons/Buttons';
import { Icon } from '../../../components/Icons/Icons';
import TextInput from '../../../components/Inputs/TextInput';
import { Imagotipo } from '../../../components/Logos/Imagotipo/Imagotipo';
import Tooltip from '../../../components/Tooltip/Tooltip';
import {
  Body2,
  BodyLarge,
  H4,
} from '../../../components/Typographies/Typography';
import { SpacedWrapper, Wrapper } from '../../../components/Utils/Utils';
import { emailRegex } from '../../../utils/constants';
import logout from '../../../utils/logout';
import { CHANGE_OTP, GET_MY_COMPANIES, LOGIN } from '../gql';
import { handleSoraLogin, sifeDataFilter, SIFELogin } from '../helperFuncs';
import { setMyCompanies } from '../Login';
import { useTheme } from '@mui/material/styles';
import { CustomCheckbox } from '../../MyCompany/Settings/Settings.styles';
import {
  genericDialogVar,
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../cache.reactiveVars';

const ChangeOTP = () => {
  const theme = useTheme();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const history = useHistory();
  const user = useReactiveVar(userVar);
  const [changeOTP] = useMutation(CHANGE_OTP);
  const [login] = useMutation(LOGIN);
  const [privacyPoliciAccepted, setPrivacyPolicyAccepted] = useState(false);
  const params = window?.location?.search;
  const urlParams = new URLSearchParams(params);
  const identifier = urlParams?.get('id');
  const otpEncoded = urlParams?.get('otp');

  const [getMyCompanies] = useLazyQuery(GET_MY_COMPANIES, {
    onCompleted({ getMyCompanies: companies }) {
      setMyCompanies(companies);
    },
    onError(err) {
      console.error(err);
      logout(history);
    },
  });

  const handleLoginOtp = async () => {
    if (identifier && otpEncoded) {
      logout();
      try {
        let identifierData = identifier;
        globalBackdropVar({
          open: true,
          text: 'Obteniendo información de usuario...',
        });
        const decodeId = Buffer?.from(otpEncoded, 'base64').toString('utf-8');
        const userType = emailRegex.test(decodeURIComponent(identifierData))
          ? 'email'
          : 'rfc';
        if (userType === 'email') {
          identifierData = decodeURIComponent(identifierData);
        }
        const sifeRes = await SIFELogin(identifierData, decodeId, userType);
        const allowedFieldsToUpdate = sifeDataFilter(sifeRes);
        const loginRes = await login({
          variables: {
            input: {
              ...allowedFieldsToUpdate,
            },
          },
        });
        handleSoraLogin(loginRes);
        getMyCompanies();
        globalBackdropVar({ open: false });
      } catch (error) {
        console.log(error);
        globalBackdropVar({ open: false });
        logout(history);
      }
    }
  };

  const handleTooltip = () => {
    if (password < 8)
      return 'La contraseña debe contener al menos 8 caracteres';
    if (password !== passwordConfirm)
      return 'Ambas contraseñas deben coincidir';
    return '';
  };

  const handleChangePrivacyPolicy = (event) => {
    const { checked } = event.target;
    setPrivacyPolicyAccepted(checked);
  };

  const handleSubmit = async () => {
    try {
      globalBackdropVar({ open: true, text: 'Cambiando contraseña...' });

      const res = await changeOTP({
        variables: {
          newPassword: password,
        },
      });

      if (
        ['PasswordChanged', 'CompleteContactInfo'].includes(
          res.data?.sifeChangeOtp?.__typename,
        )
      ) {
        const oldUser = JSON.parse(localStorage.user);
        const newUser = {
          ...oldUser,
          rhPod: { ...oldUser.rhPod, otpChanged: true, hasPassword: true },
        };
        localStorage.user = JSON.stringify(newUser);
        userVar(newUser);
        history.push('/rhpod');
      } else {
        globalSnackbarVar({
          show: true,
          message: res.data?.sifeChangeOtp?.message,
          severity: 'error',
        });
      }
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  if (user?.rhPod.otpChanged) return <Redirect to={{ pathname: '/rhpod' }} />;

  return (
    <Wrapper
      height="100vh"
      center
      justifyContent="center"
      onLoad={handleLoginOtp}
    >
      <SpacedWrapper spacing="28px" column center>
        <Imagotipo
          height="91px"
          onClick={() =>
            genericDialogVar({
              open: true,
              title: '¿Deseas salir?',
              message:
                'Para regresar aquí deberás repetir el proceso de activación',
              onConfirm: () => logout(history),
            })
          }
        />
        <SpacedWrapper
          column
          spacing="8px"
          center
          style={{ textAlign: 'center' }}
        >
          <H4>Cambia tu contraseña temporal</H4>
          <BodyLarge color={theme.customPalette.primary.blue}>
            Debe contener al menos 8 caracteres
          </BodyLarge>
        </SpacedWrapper>
        <SpacedWrapper
          column
          width="355px"
          spacing="16px"
          style={{
            padding: '8px',
          }}
        >
          <TextInput
            id="password"
            label="Ingresa tu nueva contraseña"
            name="password"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon
                    icon="lock_line"
                    fill={theme.customPalette.grayColors.gray_40}
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextInput
            id="confirm-password"
            label="Verifica tu nueva contraseña"
            name="passwordConfirm"
            value={passwordConfirm}
            type="password"
            onChange={(e) => setPasswordConfirm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon
                    icon="lock_line"
                    fill={theme.customPalette.grayColors.gray_40}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Stack direction="row" alignItems="center" justifyContent="center">
            <CustomCheckbox
              checkedcolor={theme.customPalette.primary.blue}
              name="privacyPoliciAccepted"
              onChange={handleChangePrivacyPolicy}
              inputProps={{
                'aria-labelledby': 'privacyPoliciAcceptedCreatePassword',
              }}
              //   {...row.allCheckboxs.editCheckbox}
            />
            <Stack sx={{ ml: 0.4 }} direction="row" spacing={0.5}>
              <Body2 color={theme.customPalette.textColor.text_black_light}>
                He leído y acepto el{' '}
              </Body2>
              <Body2 color={theme.customPalette.primary.blue}>
                <a
                  href="https://sora.mx/politica-de-privacidad"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  aviso de privacidad.
                </a>
              </Body2>
            </Stack>
          </Stack>
          <SpacedWrapper column spacing="8px">
            <Tooltip title={handleTooltip()}>
              <span>
                <PrimaryButton
                  fullWidth
                  disabled={
                    password.length < 8 ||
                    password !== passwordConfirm ||
                    !privacyPoliciAccepted
                  }
                  onClick={handleSubmit}
                >
                  Continuar
                </PrimaryButton>
              </span>
            </Tooltip>
            <SecondaryButton onClick={() => logout(history)}>
              Cancelar
            </SecondaryButton>
          </SpacedWrapper>
        </SpacedWrapper>
      </SpacedWrapper>
    </Wrapper>
  );
};

export default ChangeOTP;
