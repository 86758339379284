// @ts-check
import React from 'react';
import { ButtonBase, Alert as MuiAlert, Stack } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { Tooltip } from '../Tooltip';

const iconMapping = {
  info: <Icon pathProps={{ fillRule: 'evenodd' }} icon="ic_info" />,
  success: <Icon pathProps={{ fillRule: 'evenodd' }} icon="ic_check" />,
  warning: <Icon pathProps={{ fillRule: 'evenodd' }} icon="ic_alert" />,
  error: <Icon pathProps={{ fillRule: 'evenodd' }} icon="ic_info" />,
};

/**
 * Alert component.
 * @param {import('./Alert.types').AlertType} props - The props for the Alert component.
 * @returns {React.JSX.Element}
 */
export const Alert = ({
  onAction,
  onDismiss,
  actionLabel,
  onActionDisabled,
  onActionTooltip,
  dismissLabel,
  onDismissTooltip,
  onDismissDisabled,
  ...props
}) => {
  return (
    <MuiAlert
      variant="standard"
      severity="info"
      iconMapping={{ ...iconMapping }}
      action={
        <Stack flexDirection="row" gap={1}>
          {onAction && (
            <Tooltip title={onActionTooltip}>
              <span>
                <ButtonBase
                  className="Action-button"
                  onClick={onAction}
                  disabled={onActionDisabled}
                >
                  {actionLabel}
                </ButtonBase>
              </span>
            </Tooltip>
          )}
          {onDismiss && (
            <Tooltip title={onDismissTooltip}>
              <span>
                <ButtonBase
                  className="Action-button-dismiss"
                  onClick={onDismiss}
                  disabled={onDismissDisabled}
                >
                  {dismissLabel}
                </ButtonBase>
              </span>
            </Tooltip>
          )}
        </Stack>
      }
      {...props}
    />
  );
};
