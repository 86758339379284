// @ts-check
import React from 'react';
import { useController } from 'react-hook-form';
import { Autocomplete } from '../../../newComponents/Autocomplete';
import { TextInput } from '../../../newComponents/TextInput';

const OPTIONS = [
  { value: 'SINGLE', label: 'Soltero' },
  { value: 'MARRIED', label: 'Casado' },
  { value: 'FREE_UNION', label: 'Unión libre' },
  { value: 'SEPARATED', label: 'Separado' },
  { value: 'DIVORCED', label: 'Divorciado' },
  { value: 'WIDOW', label: 'Viudo' },
];

/** @param {import('./ControlledMaritalStatusInput.types').ControlledMaritalStatusInputProps} props */
export const ControlledMaritalStatusInput = ({
  control,
  rules,
  textFieldProps = {},
}) => {
  const name = 'maritalStatus';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  return (
    <Autocomplete
      id={textFieldProps.id || name}
      options={OPTIONS}
      getOptionLabel={(option) => option.label}
      value={OPTIONS.find((option) => option.value === value) || null}
      onChange={(_, newValue) => {
        if (!newValue) return onChange('');
        onChange(newValue.value);
      }}
      onBlur={onBlur}
      disabled={textFieldProps.disabled}
      renderInput={(params) => (
        <TextInput
          {...params}
          error={hasError}
          helperText={helperText}
          {...textFieldProps}
        />
      )}
    />
  );
};
