// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { Tooltip } from '../../newComponents/Tooltip';
import { Typography } from '../../newComponents/Typography';
import { EmployeeAvatar } from '../EmployeeAvatar';
import { defaultTextSx } from './EmployeeListItem.styles';

/** @param {import('./EmployeeListItem.types').EmployeeListItemProps} props */
export const EmployeeListItem = (props) => {
  const {
    name,
    secondaryText,
    EmployeeAvatarProps = {},
    PrimaryTextProps = {},
    SecondaryTextProps = {},
  } = props;

  const { sx: primaryTextSx, ...primaryTextProps } = PrimaryTextProps;
  const { sx: secondaryTextSx, ...secondaryTextProps } = SecondaryTextProps;

  return (
    <Stack direction="row" alignItems="center" gap={2} width="100%">
      <EmployeeAvatar name={name} {...EmployeeAvatarProps} />
      <Stack>
        <Tooltip title={name} placement="top">
          <span>
            <Typography
              variant="subtitle2"
              {...primaryTextProps}
              sx={[
                defaultTextSx,
                ...(Array.isArray(primaryTextSx)
                  ? primaryTextSx
                  : [primaryTextSx]),
              ]}
            >
              {name}
            </Typography>
          </span>
        </Tooltip>
        {secondaryText && (
          <Typography
            variant="body2"
            color="text.secondary"
            {...secondaryTextProps}
            sx={[
              defaultTextSx,
              ...(Array.isArray(secondaryTextSx)
                ? secondaryTextSx
                : [secondaryTextSx]),
            ]}
          >
            {secondaryText}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
