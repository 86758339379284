import IconButton from '@mui/material/IconButton';
import { Icon } from '../../../../components/Icons/Icons';

export const ViewFileIcon = ({ docId, userId, dispatch }) => {
  const onClick = () => {
    dispatch({ type: 'OPEN_SIGNER', payload: { documentId: docId, userId } });
  };
  return (
    <IconButton onClick={onClick}>
      <Icon icon={'eye_line'} />
    </IconButton>
  );
};
