// @ts-check
import { styled } from '@mui/system';
import { Stack } from '@mui/material';

export const SoriSection = styled(Stack)(({ theme }) => ({
  flex: 1,
  width: '640px',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 3,
  // @ts-expect-error
  backgroundColor: theme.newPalette.grey.transparent8,
}));

export const InfoSection = styled(Stack)(() => ({
  flex: 1,
  width: '640px',
  alignItems: 'center',
  overflow: 'auto',
}));

export const CloseButtonWrapper = styled(Stack)(() => ({
  width: '100%',
  position: 'absolute',
  alignItems: 'flex-end',
  top: '0',
  right: '0',
  padding: '8px',
  background: 'white',
  zIndex: 2,
}));
