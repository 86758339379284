import { gql } from '@apollo/client';

export const GET_USER_WORKCENTERS = gql`
  query getWorkCenters {
    allWorkCenters {
      _id
      companyId
      name
    }
  }
`;

export const GET_DASHBOARD = gql`
  query GetDashboard($filter: DashboardFilter) {
    getDashboard(filter: $filter) {
      documentCount {
        signed
        total
      }
      userCount {
        active
        total
        baja
        inactive
      }
      receiptCount {
        signed
        total
      }
    }
  }
`;

export const EXPORT_DASHBOARD = gql`
  mutation exportDashboard($input: ExportDashboardInput) {
    exportDashboard(input: $input) {
      __typename
      ... on ErrorToExportDashboard {
        message
      }
      ... on FinalResponseExportDashboard {
        message
        presignedUrl
      }
    }
  }
`;

export const EXPORT_GLOBAL_DASHBOARD = gql`
  mutation exportGlobalDashboard($filter: ExportGlobalDashboardFilterInput!) {
    exportGlobalDashboard(filter: $filter) {
      __typename
      ... on ErrorToExportGlobalDashboard {
        message
      }
      ... on ExportGlobalDashboardResult {
        success
        link
      }
    }
  }
`;

export const GET_ADMIN_DASHBOARD = gql`
  query getAdminDashboard($input: GetAdminDashboardFilter) {
    getAdminDashboard(input: $input) {
      documentProgressDashboard {
        documentsCount {
          total
          signed
          pending
          percentage
        }
        data {
          total
          signed
          pending
          percentage
          timestamps
        }
      }
      receiptProgressDashboard {
        receiptsCounter {
          total
          signed
          pending
          percentage
        }
        data {
          total
          signed
          percentage
          timestamps
        }
      }
      onboardingProgressDashboard {
        employeeCounters {
          total
          active
          inactive
          baja
          absent
          sued
          percentage
        }
        data {
          total
          active
          inactive
          percentage
          timestamps
        }
      }
      checkListProgressDashboard {
        generalPercentage
        individualPercentages {
          companyId
          companyName
          clientName
          recordChecklistId
          recordChecklistName
          percentage
        }
      }
    }
  }
`;

export const EXPORT_NEW_DASHBOARD = gql`
  mutation exportNewDashboard($filter: DocumentsByCompanyIdsFilterInput) {
    exportNewDashboard(filter: $filter) {
      __typename
      ... on ExportNewDashboardResult {
        success
        link
      }
    }
  }
`;

export const GET_RECORD_CHECKLIST_GENERAL_PERCENTAGE = gql`
  query GetRecordChecklistGeneralPercentage(
    $filter: GetRecordChecklistGeneralPercentageFilterInput!
  ) {
    getRecordChecklistGeneralPercentage(filter: $filter) {
      generalPercentage
      individualPercentages {
        companyId
        companyName
        clientName
        recordChecklistId
        recordChecklistName
        percentage
      }
    }
  }
`;

export const GET_RECORD_CHECKLIST_DOCUMENT_TAG_PERCENTAGES = gql`
  query GetRecordChecklistDocumentTagPercentages(
    $filter: GetRecordChecklistDocumentTagPercentagesFilterInput!
  ) {
    getRecordChecklistDocumentTagPercentages(filter: $filter) {
      documentTagPercentages {
        categoryColor
        category
        subcategory
        percentage
      }
    }
  }
`;

export const GET_PENDING_DOCUMENTS_BY_RECORD_CHECKLIST = gql`
  query GetPendingDocumentsByRecordChecklist(
    $filter: GetPendingDocumentsByRecordChecklistFilterInput!
    $pagination: PaginationInput
  ) {
    getPendingDocumentsByRecordChecklist(
      filter: $filter
      pagination: $pagination
    ) {
      pagination {
        totalItems
        totalPages
        currentPage
      }
      documents {
        _id
        title
        canBeSigned
        signed
        canBeSeen
        seen
        createdAt
        rfc
        names
        lastNameP
        lastNameM
        fullName
        profilePicture
      }
    }
  }
`;

export const GET_EMPLOYEES_WITHOUT_RECORD_CHECKLIST_DOCUMENT = gql`
  query GetEmployeesWithoutRecordChecklistDocument(
    $filter: GetEmployeesWithoutChecklistDocumentFilterInput!
    $pagination: PaginationInput
  ) {
    getEmployeesWithoutRecordChecklistDocument(
      filter: $filter
      pagination: $pagination
    ) {
      pagination {
        totalItems
        totalPages
        currentPage
      }
      employees {
        rfc
        names
        lastNameP
        lastNameM
        fullName
        profilePicture
      }
    }
  }
`;

export const GET_COMPLETED_DOCUMENTS_BY_RECORD_CHECKLIST = gql`
  query GetCompletedDocumentsByRecordChecklist(
    $filter: GetCompletedDocumentsByRecordChecklistFilterInput!
    $pagination: PaginationInput
  ) {
    getCompletedDocumentsByRecordChecklist(
      filter: $filter
      pagination: $pagination
    ) {
      pagination {
        totalItems
        totalPages
        currentPage
      }
      documents {
        _id
        title
        onlyStorage
        canBeSigned
        signed
        canBeSeen
        seen
        completedAt
        rfc
        names
        lastNameP
        lastNameM
        fullName
        profilePicture
      }
    }
  }
`;
