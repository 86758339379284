import { useMutation } from '@apollo/client';
import { CREATE_TEMPLATE } from '../../gql';
import type { TemplateToCreate } from '../Template.types';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export type CreateTemplateInput = {
  data: TemplateToCreate;
  onSuccess: () => void;
};

export const useTemplateCreation = () => {
  const [CreateTemplate] = useMutation(CREATE_TEMPLATE);

  const handleCreateTemplate = async (input: CreateTemplateInput) => {
    try {
      const { data, onSuccess } = input;
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Guardando plantilla...',
      });

      const creationRes = await CreateTemplate({
        variables: {
          input: {
            title: data.title,
            description: data.description,
            htmlContent: data.myEditor,
            color: {
              primary: data.color,
              secondary: data.secondaryColor,
            },
            categories: {
              category: data.category || '',
              subcategory: data.subcategory || '',
            },
          },
        },
      });

      const { createTemplate } = creationRes.data;
      if (createTemplate.__typename === 'TemplateCreated') {
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Plantilla creada',
        });
        onSuccess();
      }
    } catch (error) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleCreateTemplate };
};
