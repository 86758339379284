import { styled } from '@mui/material/styles';

export const ProfileWrapper = styled('div')({
  display: 'flex',
  minWidth: '375px',
  minHeight: '79vh',
  height: '100%',
  '> iframe': {
    flexGrow: 1,
    border: 'none',
    touchAction: 'none',
  },
});
