import { DialogContent, DialogTitle } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { ErrorBoundary } from '@sentry/react';
import { DefaultErrorComponent } from '../../../../components/ErrorBoundary/ErrorBoundary';
import { ReceiptPDFUpload } from '../../Documents/PDFUpload/ReceiptPDFUpload';
import { Dialog } from '../../../../components/Dialogs/Dialog';
import { useState } from 'react';
import { IconButton } from '../../../../newComponents/IconButton';

export const UploadReceiptsDialogPdf = ({ open, onClose, refetchReceipts }) => {
  const [uploadError, setUploadError] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={uploadError ? 'md' : 'xs'}
      onCloseXButton={onClose}
      slideMode
      showCloseButton={false}
    >
      <DialogTitle
        display="flex"
        alignItems="center"
        component="div"
        gap="16px"
      >
        <IconButton
          aria-label="send"
          onClick={onClose}
          icon="close_line"
          color="grey"
        />
        <Typography variant="h6">Importar recibos .pdf</Typography>
      </DialogTitle>
      <DialogContent>
        <ErrorBoundary fallback={<DefaultErrorComponent />}>
          <ReceiptPDFUpload
            onClose={onClose}
            handleCloseAll={onClose}
            uploadError={uploadError}
            setUploadError={setUploadError}
            refetchReceipts={refetchReceipts}
          />
        </ErrorBoundary>
      </DialogContent>
    </Dialog>
  );
};
