import { PrimaryButton } from '../../../components/Buttons/Buttons';
import { Icon } from '../../../components/Icons/Icons';
import { BodySmall, H5 } from '../../../components/Typographies/Typography';
import { SpacedWrapper, Wrapper } from '../../../components/Utils/Utils';
import { UnstyledLink } from '../utils';
import { supportDialogVar } from '../../../businessComponents/SupportDialog/SupportDialog.vars';
import { Box } from '@mui/material';
import { useCustomTheme } from '../../../Hooks/useCustomTheme';

export const RecoverySent = ({ method }) => {
  const theme = useCustomTheme();
  const handleSupportOpen = () => {
    supportDialogVar({
      ...supportDialogVar(),
      open: true,
    });
  };

  let content = {
    icon: 'phone_line',
    title: 'Te enviamos un mensaje de texto',
    message: (
      <p>
        Si no lo recibes,{' '}
        <Box
          style={{ ...linkStyles, color: theme.customPalette.primary.blue }}
          onClick={handleSupportOpen}
        >
          contacta a soporte
        </Box>
      </p>
    ),
  };
  if (method === 'email') {
    content.icon = 'mail_send_line';
    content.title = 'Te enviamos un correo electrónico';
    content.message = 'Verifica tu bandeja de entrada o SPAM';
  }
  return (
    <Wrapper height="100vh" center justifyContent="center">
      <SpacedWrapper column spacing="32px" width="365px">
        <Icon
          height="120px"
          icon={content.icon}
          fill={theme.customPalette.grayColors.gray_40}
        />
        <SpacedWrapper column spacing="16px">
          <H5 style={{ textAlign: 'center' }}>{content.title}</H5>
          <BodySmall style={{ textAlign: 'center' }}>
            {content.message}
          </BodySmall>
        </SpacedWrapper>
        <UnstyledLink to="/login">
          <PrimaryButton fullWidth>Ir a login</PrimaryButton>
        </UnstyledLink>
      </SpacedWrapper>
    </Wrapper>
  );
};

const linkStyles = {
  fontWeight: 700,
  cursor: 'pointer',
};
