// @ts-check
import { useReactiveVar } from '@apollo/client';
import { useUserOnboarding } from './useUserOnboarding';
import { LogoutDialog } from '../../../businessComponents/LogoutDialog';
import { ProfilePictureUploaderDialog } from './ProfilePictureUploaderDialog';
import { UserInfoRequestedDialog } from './UserInfoRequestedDialog';
import { PendingDocumentsDialog } from './PendingDocumentsDialog';
import { DocumentUploadCompletionDialog } from './DocumentUploadCompletionDialog';
import { userOnboardingVar, setUserOnboardingVar } from './UserOnboarding.vars';
import { usePermissionChecker } from '../../../Hooks';
import {
  FullOnboardingWelcomeDialog,
  PersonalInfoWelcomeDialog,
  PendingDocumentsWelcomeDialog,
} from './WelcomeDialogs';

export const UserOnboarding = () => {
  const userOnboarding = useReactiveVar(userOnboardingVar);
  const { showLogoutDialog, showOnboarding } = userOnboarding;
  const userCanApproveDocsFromEmployees = usePermissionChecker({
    permission: 'approveDocsFromEmployees',
    action: 'update',
  });

  const { refetchUserOnboarding, pauseOnboardingWorkflow } =
    useUserOnboarding();

  const handleCloseLogoutDialog = () => {
    setUserOnboardingVar({ showLogoutDialog: false });
  };

  /** @type {import('./UserOnboarding.types').OnClose} */
  const handleClose = (params) => {
    const { force = false } = params || {};
    if (userCanApproveDocsFromEmployees || force) pauseOnboardingWorkflow();
    else setUserOnboardingVar({ showLogoutDialog: true });
  };

  if (!showOnboarding) return null;

  return (
    <>
      <FullOnboardingWelcomeDialog />
      <PersonalInfoWelcomeDialog />
      <PendingDocumentsWelcomeDialog />
      <LogoutDialog open={showLogoutDialog} onClose={handleCloseLogoutDialog} />
      <ProfilePictureUploaderDialog
        onPictureUploaded={refetchUserOnboarding}
        onClose={handleClose}
      />
      <UserInfoRequestedDialog
        onUserInfoCompleted={refetchUserOnboarding}
        onClose={handleClose}
      />
      <PendingDocumentsDialog
        onDocumentUploaded={refetchUserOnboarding}
        onClose={handleClose}
      />
      <DocumentUploadCompletionDialog onClose={handleClose} />
    </>
  );
};
