//@ts-check
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useTheme } from '@mui/system';
import './calendarStyle.css';
import * as rdrLocales from 'react-date-range/dist/locale';
import { Calendar as RDRCalendar } from 'react-date-range';
import { format } from 'date-fns';
import {
  Dialog,
  InputAdornment,
  Menu,
  Stack,
  useMediaQuery,
} from '@mui/material';

import { TextInput } from '../TextInput/TextInput';
import { DatepickerNavigator } from './DatepickerNavigator';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { Icon } from '../../components/Icons/Icons';

/**
 * @typedef {import('@mui/material').TextFieldProps} TextFieldProps
 * @typedef {import('react-date-range').CalendarProps} CalendarProps
 */

/**
 * @param {object} props
 * @param {Date | null} [props.date]
 * @param {(date: Date | null) => void} [props.setDate]
 * @param {string} [props.formatDate='EEE d MMM yy']
 * @param {TextFieldProps['size']} [props.size]
 * @param {string} [props.label = '']
 * @param {boolean} [props.error = false]
 * @param {string} [props.helperText = '']
 * @param {boolean} [props.showIconToRemove = false]
 * @param {boolean} [props.showStartCalendarIcon = false]
 * @param {boolean} [props.showEndCalendarIcon = false]
 * @param {Date} [props.minDate]
 * @param {Date} [props.maxDate]
 * @param {TextFieldProps} [props.textFieldProps]
 * @param {CalendarProps} [props.calendarProps]
 */
export const Calendar = ({
  date,
  setDate = () => {},
  formatDate = 'EEE d MMM yy',
  size = 'medium',
  label = '',
  error = false,
  helperText = '',
  showIconToRemove = false,
  showStartCalendarIcon = false,
  showEndCalendarIcon = false,
  textFieldProps = {},
  calendarProps = {},
  minDate,
  maxDate,
}) => {
  /** @type {import("../../theme").CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateInput, setDateInputValue] = useState('');
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    if (mobile) {
      return setOpenDialog(true);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeDate = (date) => {
    setDate(date);
    const dateString = getStringFormattedDate(date);
    setDateInputValue(dateString);
    handleClose();
  };

  /**
   *
   *
   * @param {*} date
   * @return {string}
   */

  const getStringFormattedDate = useCallback(
    (date) => {
      if (!date) return '';

      const stringDate = format(date, formatDate, {
        locale: rdrLocales.es,
      });
      return stringDate;
    },
    [formatDate],
  );

  const handleAccept = () => {
    handleCloseDialog();
  };
  const handleCancel = () => {
    handleCloseDialog();
  };

  const handleClean = () => {
    setDate(null);
    setDateInputValue('');
  };

  useEffect(() => {
    if (date) {
      const dateString = getStringFormattedDate(date);
      setDateInputValue(dateString);
      return;
    }
    setDateInputValue('');
  }, [date, getStringFormattedDate]);

  return (
    <>
      <TextInput
        id="basic-button"
        placeholder="Fecha"
        label={label}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
        value={dateInput}
        size={size}
        error={error}
        helperText={helperText}
        {...textFieldProps}
        InputProps={{
          readOnly: true,
          sx: {
            cursor: 'pointer!important',
            '& .MuiOutlinedInput-input': {
              cursor: 'pointer!important',
            },
            '& .Mui-disabled': {
              cursor: 'pointer',
              color: 'black',
            },
          },
          ...(showStartCalendarIcon && {
            startAdornment: (
              <InputAdornment position="start" disablePointerEvents>
                <Icon icon="calendar_line" />
              </InputAdornment>
            ),
          }),
          ...(showEndCalendarIcon && {
            endAdornment: (
              <InputAdornment position="end" disablePointerEvents>
                <Icon icon="calendar_line" />
              </InputAdornment>
            ),
          }),
          ...(showIconToRemove &&
            !Boolean(textFieldProps.disabled) && {
              endAdornment: (
                <InputAdornment
                  sx={{ cursor: 'pointer' }}
                  position="end"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClean();
                  }}
                >
                  <Icon icon="close_line" />
                </InputAdornment>
              ),
            }),
        }}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        slotProps={{
          paper: {
            sx: {
              mt: 1,
              width: '320px',
              borderRadius: '16px',
              boxShadow: palette.shadow.dropdown,
            },
          },
        }}
      >
        <Stack>
          <RDRCalendar
            onChange={handleChangeDate}
            // @ts-ignore
            date={date}
            locale={rdrLocales.es}
            fixedHeight
            weekStartsOn={0}
            showSelectionPreview={false}
            minDate={minDate ? new Date(minDate) : undefined}
            maxDate={maxDate ? new Date(maxDate) : undefined}
            navigatorRenderer={(
              currFocusedDate,
              changeShownDate,
              calendarProps,
            ) => (
              <DatepickerNavigator
                currFocusedDate={currFocusedDate}
                changeShownDate={changeShownDate}
                calendarProps={calendarProps}
              />
            )}
            {...calendarProps}
          />
        </Stack>
      </Menu>
      <Dialog
        maxWidth="xs"
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            mt: 1,
            width: '320px',
            borderRadius: '16px',
            boxShadow: palette.shadow.dropdown,
          },
        }}
        sx={{
          borderRadius: '10px',
        }}
      >
        <Stack minHeight="518px" alignItems="space-between">
          <Stack padding="16px 24px 16px 24px" gap={1}>
            <Typography variant="overline" color="text.secondary">
              seleccionar fecha
            </Typography>
            <Stack flexDirection="row" justifyContent="space-between">
              <Typography variant="h4">
                {getStringFormattedDate(date)}
              </Typography>
              <Icon icon="pencil_line"></Icon>
            </Stack>
          </Stack>
          <Stack>
            <RDRCalendar
              onChange={handleChangeDate}
              // @ts-ignore
              date={date}
              locale={rdrLocales.es}
              fixedHeight
              weekStartsOn={0}
              navigatorRenderer={(
                currFocusedDate,
                changeShownDate,
                calendarProps,
              ) => (
                <DatepickerNavigator
                  currFocusedDate={currFocusedDate}
                  changeShownDate={changeShownDate}
                  calendarProps={calendarProps}
                />
              )}
              {...calendarProps}
            />
          </Stack>
          <Stack justifyContent="flex-end" flexDirection="row" mt={10}>
            <Button onClick={handleAccept}>Cancelar</Button>
            <Button onClick={handleCancel}>OK</Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};
