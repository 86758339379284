//@ts-check
import { makeVar } from '@apollo/client';

/**
 * Reactive variable for managing the state of the add user email dialog.
 * @typedef {object} SendOnboardingNotifDialogVar
 * @property {boolean} open - Indicates whether the dialog is open or not.
 * @property {number|null} employeeId - The ID of the employee to add an email for.
 * @property {Function} refetchFunc - An async function to refetch data.
 */
const sendOnboardingNotifDialogVarDefault = {
  open: false,
  employeeId: null,
  refetchFunc: async () => {},
};
export const sendOnboardingNotifDialogVar = makeVar(
  sendOnboardingNotifDialogVarDefault,
);

export const resetSendOnboardingNotifDialogVar = () => {
  sendOnboardingNotifDialogVar(sendOnboardingNotifDialogVarDefault);
};

const sendRecoveryDialogVarDefault = {
  open: false,
  employeeId: null,
  employeeWorkSpaceId: null,
  refetchFunc: async () => {},
};

export const sendRecoveryDialogVar = makeVar(sendRecoveryDialogVarDefault);

export const resetSendRecoveryDialogVar = () => {
  sendRecoveryDialogVar(sendRecoveryDialogVarDefault);
};

const employeeInfoDialogVarDefault = {
  open: false,
  employeeId: null,
  employeeWorkSpaceId: null,
  onClose: () => {},
  refetchFunc: async () => {},
};
export const employeeInfoDialogVar = makeVar(employeeInfoDialogVarDefault);
export const resetEmployeeInfoDialogVar = () => {
  employeeInfoDialogVar(employeeInfoDialogVarDefault);
};

/**
 * Reactive variable for managing the state of the add user email dialog.
 * @typedef {object} SendMassiveOnboardingNotificationDialogVar
 * @property {boolean} open - Indicates whether the dialog is open or not.
 */
const sendMassiveOnboardingNotificationDialogVarDefault = {
  open: false,
};

export const sendMassiveOnboardingNotificationDialogVar = makeVar(
  sendMassiveOnboardingNotificationDialogVarDefault,
);

export const resetSendMassiveOnboardingNotificationDialogVar = () => {
  sendMassiveOnboardingNotificationDialogVar(
    sendMassiveOnboardingNotificationDialogVarDefault,
  );
};
