//@ts-check
import React from 'react';
import { ScreenTitle } from '../../../layoutComponents/ScreenTitle/ScreenTitle';
import { IconButton } from '../../../newComponents/IconButton/IconButton';
import { Button } from '../../../newComponents/Button/Button';
import { useApolloClient, useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../cache.reactiveVars';
import { Icon } from '../../../components/Icons/Icons';
import { sendMassiveNotificationsDialogVar } from '../../Settings/Preferences/Notifications/Notifications.vars';
import { downloadURL } from '../../../utils/downloadURL';
import { loggerUtil } from '../../../utils/loggerUtil';
import { documentsTableColumnsDialogVar } from './DocumentsTable/DocumentsTableColumnsDialog/DocumentsTableColumnsDialog.vars';
import {
  EXPORT_COMPANY_DOCUMENTS,
  GET_COMPANY_DOCUMENTS,
  GET_COMPANY_DOCUMENTS_COUNTERS,
} from './DocumentsTable/DocumentsTable.gql';
import { useGetCompanyDocumentsInput } from './DocumentsTable/Hooks/useGetCompanyDocumentsInput.hooks';
import { getExportColumns } from './Documents.helpers';
import { SORA_MODULES } from '../../../components/ModuleBlockableComponent/ModuleBlockable.constants';
import { useBlockableActions } from '../../../components/ModuleBlockableComponent/useBlockableActions';
import { usePermissionChecker, useScreenSize } from '../../../Hooks';
import { ActionBarPopMenu } from '../../../layoutComponents/ScreenTitle/ScreenTitleActionBar/ActionBarPopMenu/ActionBarPopMenu';
import { setUploadDocumentsDialogVar } from '../../../businessComponents/Dialogs/UploadDocumentsDialog/UploadDocumentsDialog.vars';
import { setDocumentCreatorDialogVar } from '../../../businessComponents/Dialogs/DocumentCreatorDialog/DocumentCreatorDialog.vars';
import { GET_EMPLOYEE_INFO } from '../Employees/EmployeeInfo/EmployeeInfo.gql';
import { ScreenHeaderWrapper } from '../../../layoutComponents/ScreenHeader/ScreenHeaderWrapper';
import { ScreenHeaderMobileButtonsWrapper } from '../../../layoutComponents/ScreenHeader/ScreenHeaderMobileButtonsWrapper';
import { ScreenHeaderDesktopButtonsWrapper } from '../../../layoutComponents/ScreenHeader/ScreenHeaderDesktopButtonsWrapper';

export function DocumentsHeader() {
  return (
    <ScreenHeaderWrapper>
      <ScreenTitle title="Documentos" icon="file_text_fill" />
      <DocumentsHeaderButtons />
    </ScreenHeaderWrapper>
  );
}

/**
 *
 * @param {object} props
 * @param {boolean} [props.allColumns]
 * @param {null | any} [props.employeeData]
 */
export const DocumentsHeaderButtons = ({
  allColumns = false,
  employeeData = null,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isEmployeeBaja = employeeData?.currentStatus?.name === 'BAJA';

  const {
    isModuleAvailable: isDocumentsModuleAvailable,
    onClick: moduleBlockedOnClick,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);
  const { enabledColumns } = useReactiveVar(documentsTableColumnsDialogVar);

  const { getCompanyDocumentsInput } = useGetCompanyDocumentsInput();
  const [exportCopmpanyDocuments] = useLazyQuery(EXPORT_COMPANY_DOCUMENTS);

  const client = useApolloClient();

  const handleOnUploadDocuments = async () => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    if (employeeData) {
      await client.refetchQueries({
        include: [
          GET_EMPLOYEE_INFO,
          GET_COMPANY_DOCUMENTS,
          GET_COMPANY_DOCUMENTS_COUNTERS,
        ],
      });
    } else {
      await client.refetchQueries({
        include: [GET_COMPANY_DOCUMENTS, GET_COMPANY_DOCUMENTS_COUNTERS],
      });
    }
  };
  const handleOpenUploadDocumentDialog = () => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    const mappedEmployeeData = employeeData
      ? { ...employeeData, id: employeeData.userId, _id: employeeData.userId }
      : null;
    setUploadDocumentsDialogVar({
      open: true,
      employeeData: mappedEmployeeData,
      onUploadSuccess: handleOnUploadDocuments,
    });
  };

  const handleOpenDocumentCreatorDialog = () => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    const mappedEmployeeData = employeeData
      ? { ...employeeData, id: employeeData.userId }
      : null;
    // resetAddDocumentsOptionsDialog();
    setDocumentCreatorDialogVar({
      open: true,
      employeeId: employeeData?.userId,
      employeeData: mappedEmployeeData,
      onUploadSuccess: handleOnUploadDocuments,
    });
  };

  const handleExportDocuments = async () => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    const exportColumns = getExportColumns({ enabledColumns, allColumns });
    try {
      globalBackdropVar({ open: true, text: 'Exportando documentos...' });
      const response = await exportCopmpanyDocuments({
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            documentsTableEnabledColumns: exportColumns,
            documentsTableFilters:
              getCompanyDocumentsInput.documentsTableFilters,
          },
        },
      });

      if (
        response.data?.exportCompanyDocuments?.__typename ===
        'ExportCompanyDocumentsSuccess'
      ) {
        downloadURL(response.data.exportCompanyDocuments.link);
        return globalSnackbarVar({
          show: true,
          message: 'Exportación exitosa',
          severity: 'success',
        });
      }

      globalSnackbarVar({
        show: true,
        message: 'Oucrrió un problema al exportar documentos. Intente de nuevo',
        severity: 'error',
      });
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message:
          'Ocurrió un problema con la petición. Intente de nuevo o contacte a soporte',
      });
      loggerUtil.error(error);
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleSendDocumentsNotification = () => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    sendMassiveNotificationsDialogVar({
      open: true,
    });
  };
  const [permissionToUpdateDocuments, permissionsToReadDocuments] =
    usePermissionChecker([
      { permission: 'docs', action: 'update' },
      { permission: 'docs', action: 'read' },
    ]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const { xs } = useScreenSize();
  const popoverOpen = Boolean(anchorEl);
  return (
    <>
      {xs ? (
        <DocumentsHeaderMobileButtons
          handleSendDocumentsNotification={handleSendDocumentsNotification}
          handleExportDocuments={handleExportDocuments}
          handlePopoverOpen={handlePopoverOpen}
          permissionToUpdateDocuments={permissionToUpdateDocuments}
          permissionsToReadDocuments={permissionsToReadDocuments}
        />
      ) : (
        <DoucmentsHeaderDesktopButtons
          handleSendDocumentsNotification={handleSendDocumentsNotification}
          handleExportDocuments={handleExportDocuments}
          handlePopoverOpen={handlePopoverOpen}
          popoverOpen={popoverOpen}
          permissionToUpdateDocuments={permissionToUpdateDocuments}
          permissionsToReadDocuments={permissionsToReadDocuments}
          isEmployeeBaja={isEmployeeBaja}
        />
      )}
      <ActionBarPopMenu
        popoverOpen={popoverOpen}
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        menuItems={[
          {
            label: 'Enviar documentos',
            icon: 'file_edit_fill',
            iconColor: 'success',
            onClick: () => handleOpenUploadDocumentDialog(),
          },
          {
            label: 'Crear documentos',
            icon: 'send_plane_fill',
            iconColor: 'info',
            onClick: () => handleOpenDocumentCreatorDialog(),
          },
        ]}
      />
    </>
  );
};

const DoucmentsHeaderDesktopButtons = ({
  handleSendDocumentsNotification,
  handleExportDocuments,
  handlePopoverOpen,
  popoverOpen,
  permissionToUpdateDocuments,
  permissionsToReadDocuments,
  isEmployeeBaja,
}) => {
  return (
    <ScreenHeaderDesktopButtonsWrapper>
      <IconButton
        icon="mail_send_line"
        onClick={handleSendDocumentsNotification}
        tooltipArrow
        tooltipPlacement="top"
        tooltipText={
          !permissionToUpdateDocuments
            ? 'Necesitas permisos para esta acción'
            : 'Enviar recordatorio a todos los empleados con pendientes de firmar'
        }
      />
      <Button
        variant="outlined"
        color="default"
        startIcon={<Icon icon="file_excel_2_line" />}
        onClick={handleExportDocuments}
        disabled={!permissionsToReadDocuments}
        tooltipText={
          !permissionsToReadDocuments
            ? 'Necesitas permisos para esta acción'
            : 'Exportar tabla de documentos'
        }
        tooltipArrow
        tooltipPlacement="top"
      >
        Exportar
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={!permissionToUpdateDocuments || isEmployeeBaja}
        onClick={handlePopoverOpen}
        endIcon={
          <Icon icon={popoverOpen ? 'arrow_up_s_line' : 'arrow_down_s_line'} />
        }
        tooltipArrow
        tooltipPlacement="top"
        tooltipText={
          !permissionToUpdateDocuments
            ? 'Necesitas permisos para esta acción'
            : isEmployeeBaja
              ? 'Da de alta al empleado para poder enviar documentos'
              : 'Carga o crea documentos y envíalos a tus empleados'
        }
      >
        Agregar documentos
      </Button>
    </ScreenHeaderDesktopButtonsWrapper>
  );
};

const DocumentsHeaderMobileButtons = ({
  handleSendDocumentsNotification,
  handleExportDocuments,
  handlePopoverOpen,
  permissionToUpdateDocuments,
  permissionsToReadDocuments,
}) => {
  return (
    <ScreenHeaderMobileButtonsWrapper>
      <IconButton
        icon="mail_send_line"
        onClick={handleSendDocumentsNotification}
        tooltipArrow
        tooltipPlacement="top"
        tooltipText={
          !permissionToUpdateDocuments
            ? 'Necesitas permisos para esta acción'
            : 'Enviar recordatorio a todos los empleados con pendientes de firmar'
        }
      />
      <IconButton
        icon="file_excel_2_line"
        onClick={handleExportDocuments}
        disabled={!permissionsToReadDocuments}
        tooltipArrow
        tooltipPlacement="top"
        tooltipText={
          !permissionToUpdateDocuments
            ? 'Necesitas permisos para esta acción'
            : 'Exportar tabla de documentos'
        }
      />
      <IconButton
        icon="send_plane_fill"
        color="primary"
        disabled={!permissionToUpdateDocuments}
        onClick={handlePopoverOpen}
        tooltipArrow
        tooltipPlacement="top"
        tooltipText={
          !permissionToUpdateDocuments
            ? 'Necesitas permisos para esta acción'
            : 'Carga o crea documentos y envíalos a tus empleados'
        }
      />
    </ScreenHeaderMobileButtonsWrapper>
  );
};
