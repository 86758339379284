// @ts-check

/**
 * @typedef {'Employee'} EMPLOYEE_ROW
 * @typedef {'WorkCenter'} WORK_CENTER_ROW
 * @typedef {'WorkTitle'} WORK_TITLE_ROW
 * @typedef {'Group'} GROUP_ROW
 *
 * @typedef {'EMPLOYEES'} FIND_BY_EMPLOYEES
 * @typedef {'WORKCENTERS'} FIND_BY_WORKCENTERS
 * @typedef {'WORKTITLES'} FIND_BY_WORKTITLES
 * @typedef {'GROUPS'} FIND_BY_GROUPS
 *
 * @typedef {FIND_BY_EMPLOYEES | FIND_BY_WORKCENTERS | FIND_BY_WORKTITLES | FIND_BY_GROUPS} FIND_BY
 * @typedef {MULTIPLE_IDS | SINGLE_ID} SEARCHTYPE
 */

export const EMPLOYEE_ROW = 'Employee';
export const WORK_CENTER_ROW = 'WorkCenter';
export const WORK_TITLE_ROW = 'WorkTitle';
export const GROUP_ROW = 'Group';

export const FIND_BY_EMPLOYEES = 'EMPLOYEES';
export const FIND_BY_WORKCENTERS = 'WORKCENTERS';
export const FIND_BY_WORKTITLES = 'WORKTITLES';
export const FIND_BY_GROUPS = 'GROUPS';

export const MULTIPLE_IDS = 'MULTIPLE_IDS';
export const SINGLE_ID = 'SINGLE_ID';
