export const DEFAULT_FORM_VALUES = {
  _id: '',
  title: '',
  description: '',
  color: '',
  secondaryColor: '',
  category: '',
  subcategory: '',
  myEditor: '',
};
