import { LinearProgress, Stack } from '@mui/material';
import { Icon } from '../../../../components/Icons/Icons';
import {
  BodyLarge,
  BodySmall,
  CaptionSmall,
} from '../../../../components/Typographies/Typography';
import { SpacedWrapper } from '../../../../components/Utils/Utils';
import { useTheme } from '@mui/material/styles';
import { StyledSecondaryButton } from '../Tables/style';
import { ErrorsTable } from '../Tables/ErrorsTable';

const UploadProgress = ({
  file,
  completed,
  error,
  errorMsg,
  onClose,
  importError,
  errorsInFiles,
}) => {
  const theme = useTheme();
  const areThereErrorMessagePerFiles = error && errorsInFiles?.length > 0;

  return (
    <SpacedWrapper column center spacing="40px" p="36px 48px">
      <Icon
        fill={
          error
            ? theme.customPalette.status.error_red_60
            : !completed
              ? theme.customPalette.grayColors.gray_40
              : theme.customPalette.status.success_green_70
        }
        height="60px"
        icon={
          error
            ? 'close_circle_line'
            : !completed
              ? 'upload_2_line'
              : 'checkbox_circle_line'
        }
      />
      <Stack gap={2} alignItems="center" width="100%">
        <BodyLarge style={{ fontWeight: '700' }}>
          {error && 'La importación falló'}
          {!error && completed && 'Importación completada'}
          {!error && !completed && 'Importando recibos'}
        </BodyLarge>
        <BodySmall>
          {error &&
            !errorMsg &&
            'Hubo un problema con la importación de tus recibos'}
          {error && errorMsg && (
            <p style={{ textAlign: 'center' }}>{errorMsg}</p>
          )}
          {!error && file && `Cargando archivo: ${file.name}`}
          {!error && !file && `Cargando archivos...`}
        </BodySmall>
        {areThereErrorMessagePerFiles && <ErrorsTable errors={errorsInFiles} />}
        {importError?.length > 0 && <ErrorsTable errors={importError} />}
      </Stack>
      {!error ? (
        <SpacedWrapper column>
          <LinearProgress
            sx={{
              '&.MuiLinearProgress-colorPrimary': {
                backgroundColor: theme.customPalette.redColors.red_20,
              },
              '.MuiLinearProgress-bar	': {
                background: theme.customPalette.gradients.yellow_red,
                borderRadius: 5,
              },
            }}
            variant={!completed ? 'indeterminate' : 'determinate'}
            value={100}
          />
          <CaptionSmall
            color={theme.customPalette.status.info_purple_60}
            style={{ textAlign: 'center' }}
          >
            NOTA: Los recibos con folio duplicado o asignados a nóminas no
            existentes en la sección de Empleados seran omitidos.
          </CaptionSmall>
        </SpacedWrapper>
      ) : (
        <Stack
          width="100%"
          alignItems="flex-end"
          justifyContent="flex-end"
          flexDirection="row"
          gap={2}
        >
          <StyledSecondaryButton onClick={() => onClose()}>
            Volver
          </StyledSecondaryButton>
          <StyledSecondaryButton disabled>Continuar</StyledSecondaryButton>
        </Stack>
      )}
    </SpacedWrapper>
  );
};

export default UploadProgress;
