import React, { useEffect, useState, Suspense } from 'react';
import Header from '../../components/Header/Header';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import RHPod from '../RHPod/RHPod';
import ProfileDialog from './Profile/ProfileDialog';
import OnboardingDocument from './PendingDocuments/OnboardingDocuments';
import { useMutation, useReactiveVar } from '@apollo/client';
import HighPriorityDocuments from './PendingDocuments/HighPriorityDocuments';
import WelcomeDialog from './Dialogs/WelcomeDialog';
import { Box } from '@mui/material';
import AdminDocuments from './PendingDocuments/AdminDocuments';
import { landingContentStyles, landingStyles } from './style';
import VerificationCompanyDialog from '../../components/CreateCompany/VerificationCompanyDialog';
import useAdminTour from '../../components/OnBoardingTour/useTourAdmin';
import useEmployeeTour from '../../components/OnBoardingTour/useTourEmployee';
import { SET_TOUR_COMPLETIONTYPE } from './gql';
import { getUserDocs } from '../../utils/userDocs';
import LegalPod from '../LegalPod';
import Reports from '../Reports';
import { DefaultErrorComponent } from '../../components/ErrorBoundary/ErrorBoundary';
import { ErrorBoundary } from '@sentry/react';
import { useTracking } from '../../Hooks/useTracking';
import { WarningsModal } from '../../components/WarningsModal/WarningsModal';
import useRouteChange from '../../Hooks/useRouteChange';
import { HideMultipleDocumentsModal } from '../../components/Dialogs/HideDocumentsDialog';
import { DeleteDocumentModal } from '../../components/Dialogs/DeleteDocumentModal';
import { Settings } from '../MyCompany/Settings/Settings';
import { UserDataChangeRequestDialog } from '../../components/Dialogs/UserDataChangeRequestDialog';
import { PrivacyPolicyDialog } from './Dialogs/PrivacyPolicyDialog';
import SuspenseFallback from '../../components/SuspenseFallback/SuspenseFallback';
import { isJsonString } from '../../utils/utils';
import { loggerUtil } from '../../utils/loggerUtil';
import {
  currentCompanyVar,
  dialogOpenVar,
  globalSnackbarVar,
  pendingDocsVar,
  userPermissionsVar,
  userVar,
} from '../../cache.reactiveVars';
import { NavBar } from '../../businessComponents/NavBar/NavBar';
import { ModuleBlockedDialog } from '../../components/ModuleBlockableComponent/ModuleBlockedDialog';
import { EmployeeInfo } from '../RHPod/Employees/EmployeeInfo/EmployeeInfo';
import { ChangeEmployeeLegalStatusDialog } from '../RHPod/Employees/Dialogs/ChangeEmployeeLegalStatusDialog/ChangeEmployeeLegalStatusDialog';
import { DownloadEmployeeRecordsDialog } from '../RHPod/Employees/Dialogs/DownloadEmployeeRecordsDialog/DownloadEmployeeRecordsDialog';
import { ChangeEmployeeStatusDialog } from '../RHPod/Employees/Dialogs/ChangeEmployeeStatusDialog/ChangeEmployeeStatusDialog';
import { SendRecoveryDialog } from '../RHPod/Employees/Dialogs/SendRecoveryDialog/SendRecoveryDialog';
import { SendMassiveNotificationDialog } from '../Settings/Preferences/Notifications/SendMassiveNotificationDialog/SendMassiveNotificationDialog';
import { AddEmailForEmployeeDialog } from '../Settings/Preferences/Notifications/AddEmailForEmployeeDialog/AddEmailForEmployeeDialog';
import { SendMassiveNotificationDialogByCompanyIds } from '../Settings/Preferences/Notifications/SendMassiveNotificationDialog/SendMassiveNotificationDialogByCompanyIds';
import { SendDocumentsOptionsDialog } from '../../businessComponents/Dialogs/SendDocumentsOptionsDialog/SendDocumentsOptionsDialog';
import { SingleDocumentUploadDialog } from '../../businessComponents/Dialogs/SingleDocumentUploadDialog/SingleDocumentUploadDialog';
import { SingleDocumentConfigurationUploadDialog } from '../../businessComponents/Dialogs/SingleDocumentConfigurationUploadDialog/SingleDocumentConfigurationUploadDialog';
import { AddDocumentOptionsDialog } from '../../businessComponents/Dialogs/AddDocumentOptionsDialog/AddDocumentOptionsDialog';
import { DocumentCreatorDialog } from '../../businessComponents/Dialogs/DocumentCreatorDialog/DocumentCreatorDialog';
import { UserOnboarding } from './UserOnboarding';
import { UploadDocumentsDialog } from '../../businessComponents/Dialogs/UploadDocumentsDialog/UploadDocumentsDialog';
import { SendMassiveOnboardingNotificationDialog } from '../RHPod/Employees/Dialogs/SendMassiveOnboardingNotificationDialog/SendMassiveOnboardingNotificationDialog';
import { useScreenSize } from '../../Hooks';
const Development = React.lazy(() => import('./Development/Development'));
const AdminPanelPage = React.lazy(() => import('./AdminPanel/AdminPanelPage'));
const AdminSupportPage = React.lazy(
  () => import('./SupportPanel/AdminSupportPage'),
);
const CommercialPanelContainer = React.lazy(
  () => import('./CommercialPanel/CommercialPanelContainer'),
);

const Landing = () => {
  const [showTourEmployee, setShowTourEmployee] = useState(false);
  const { isMobile } = useScreenSize();
  const { welcomeDialog } = useReactiveVar(dialogOpenVar);

  const [shouldShowDialogs, setShouldShowDialogs] = useState({
    welcomeDialog: false,
    askForOnboarding: true,
    askForAdminConsent: true,
    askForHighPriority: true,
  });

  const [stepState, setStepState] = useState({
    run: false,
    stepIndex: 0,
    steps: [],
  });

  const [stepStateEmployee, setStepStateEmployee] = useState({
    run: false,
    stepIndex: 0,
    steps: [],
  });

  useTracking('page_view');
  const location = useLocation();
  const roleURL = `/${location.pathname.split('/')[2]}`;
  const pendingDocs = useReactiveVar(pendingDocsVar);
  const user = useReactiveVar(userVar);
  const currentCompany = useReactiveVar(currentCompanyVar);
  // const multipleMasterAdmins = getMasterAdminsRoles();
  const copiedPendingDocs = { ...pendingDocs };
  const responseDocs = getUserDocs({ copiedPendingDocs, currentCompany });
  copiedPendingDocs.adminDocs = responseDocs.filteredDocsAdmin;
  copiedPendingDocs.onboardingDocs = responseDocs.filteredOnboardingDocs;
  copiedPendingDocs.highPriorityDocs = responseDocs.filteredPriorityDocs;
  useRouteChange();

  useEffect(() => {
    if (isJsonString(localStorage.userRoles)) {
      const roles = JSON.parse(localStorage.userRoles);
      const userRole = roles[currentCompany?._id] ?? {};
      if (Object.keys(userRole.permissions || {}).length > 0) {
        userPermissionsVar(userRole?.permissions);
      }
    }
  }, [currentCompany?._id]);

  useEffect(() => {
    const shouldShowWelcomeDialog = copiedPendingDocs.onboardingDocs.length > 0;
    setShouldShowDialogs({
      ...shouldShowDialogs,
      welcomeDialog: shouldShowWelcomeDialog,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldAskForOnboarding =
    copiedPendingDocs?.onboardingDocs?.length > 0 &&
    shouldShowDialogs.askForOnboarding;
  const shouldAskForAdminConsent =
    !shouldAskForOnboarding &&
    copiedPendingDocs?.adminDocs?.length > 0 &&
    shouldShowDialogs.askForAdminConsent &&
    (roleURL === '/admin' || roleURL === '/empleado');
  const shouldAskForHighPriority =
    !shouldAskForOnboarding &&
    copiedPendingDocs?.highPriorityDocs?.length > 0 &&
    shouldShowDialogs.askForHighPriority &&
    roleURL === '/empleado';
  const showOnboardingDialog =
    !shouldAskForOnboarding &&
    !(shouldAskForAdminConsent || shouldAskForHighPriority);
  const shouldShowWelcomeDialog =
    !shouldAskForOnboarding &&
    !(shouldAskForAdminConsent || shouldAskForHighPriority) &&
    !showOnboardingDialog &&
    !user?.signatures?.cryptoSignature?.active &&
    shouldShowDialogs.welcomeDialog;

  useEffect(() => {
    dialogOpenVar({
      ...dialogOpenVar(),
      welcomeDialog: shouldShowWelcomeDialog,
    });
  }, [shouldShowWelcomeDialog]);

  const [setTourCompletion] = useMutation(SET_TOUR_COMPLETIONTYPE);

  const updateCompletionTour = async (completionType) => {
    try {
      await setTourCompletion({
        variables: {
          completionType: completionType,
        },
      });
      globalSnackbarVar({
        show: true,
        message: '¡Bien hecho! Has completado el tour.',
        severity: 'success',
      });
    } catch (error) {
      loggerUtil.error(error);
    }
  };

  let cantShowTour =
    shouldAskForOnboarding ||
    shouldAskForAdminConsent ||
    shouldAskForHighPriority ||
    shouldShowWelcomeDialog;

  const tour = useAdminTour({
    stepState,
    setStepState,
    updateCompletionTour,
    isMobile,
    cantShowTour,
    roleURL,
  });

  const tourEmployee = useEmployeeTour({
    stepStateEmployee,
    setStepStateEmployee,
    updateCompletionTour,
    showTourEmployee,
    isMobile,
    roleURL,
  });

  return (
    <Box sx={landingStyles}>
      <ProfileDialog />
      {/* {localStorage?.soraAdmin === 'true' && <AdminPanelDialog />} */}
      {/* <SessionExpiredDialog /> */}
      {!!shouldAskForOnboarding && (
        <OnboardingDocument
          shouldShowDialogs={shouldShowDialogs}
          setShouldShowDialogs={setShouldShowDialogs}
          onboardingDocs={responseDocs.filteredOnboardingDocs}
        />
      )}
      {!!shouldAskForAdminConsent && (
        <AdminDocuments
          shouldShowDialogs={shouldShowDialogs}
          setShouldShowDialogs={setShouldShowDialogs}
          adminDocuments={responseDocs.filteredDocsAdmin}
        />
      )}
      {!!shouldAskForHighPriority && (
        <HighPriorityDocuments
          shouldShowDialogs={shouldShowDialogs}
          setShouldShowDialogs={setShouldShowDialogs}
          priorityDocs={responseDocs.filteredPriorityDocs}
        />
      )}
      {!!showOnboardingDialog && <UserOnboarding />}
      {!!shouldShowWelcomeDialog && welcomeDialog && (
        <WelcomeDialog
          shouldShowDialogs={shouldShowDialogs}
          setShouldShowDialogs={setShouldShowDialogs}
        />
      )}

      {tour}
      {tourEmployee}

      {<VerificationCompanyDialog open={false} />}
      <NavBar />
      <PrivacyPolicyDialog />
      <WarningsModal />
      <SendMassiveNotificationDialog />
      <SendMassiveNotificationDialogByCompanyIds />
      <AddEmailForEmployeeDialog />
      <HideMultipleDocumentsModal />
      <ChangeEmployeeStatusDialog />
      <ChangeEmployeeLegalStatusDialog />
      <SendRecoveryDialog />
      <DownloadEmployeeRecordsDialog />
      <DeleteDocumentModal />
      <UserDataChangeRequestDialog />
      <ModuleBlockedDialog />
      <SendDocumentsOptionsDialog />
      <SingleDocumentUploadDialog />
      <SingleDocumentConfigurationUploadDialog />
      <DocumentCreatorDialog />
      <AddDocumentOptionsDialog />
      <UploadDocumentsDialog />
      <SendMassiveOnboardingNotificationDialog />

      <Box sx={landingContentStyles}>
        <Header setShowTourEmployee={setShowTourEmployee} />
        <ErrorBoundary fallback={<DefaultErrorComponent />}>
          <Switch>
            <Route path="/rhpod">
              <RHPod />
            </Route>
            <Route path="/legalpod">
              <LegalPod />
            </Route>
            <Route path="/reportes">
              <Reports />
            </Route>
            <Route path="/ajustes">
              <Settings />
            </Route>
            {localStorage?.soraAdmin === 'true' && (
              <Route path="/sora-admin">
                <Suspense fallback={<SuspenseFallback />}>
                  <AdminPanelPage />
                </Suspense>
              </Route>
            )}
            {localStorage?.soraAdmin === 'true' && (
              <Route path="/sora-support">
                <Suspense fallback={<SuspenseFallback />}>
                  <AdminSupportPage />
                </Suspense>
              </Route>
            )}
            {localStorage?.soraAdmin === 'true' && (
              <Route path="/sora-analytics">
                <Suspense fallback={<SuspenseFallback />}>
                  <CommercialPanelContainer />
                </Suspense>
              </Route>
            )}
            {localStorage?.soraAdmin === 'true' && (
              <Route path="/sora-development">
                <Suspense fallback={<SuspenseFallback />}>
                  <Development />
                </Suspense>
              </Route>
            )}

            <Redirect from="*" to="/rhpod" />
          </Switch>
          <EmployeeInfo />
        </ErrorBoundary>
      </Box>
    </Box>
  );
};

export default Landing;
