// @ts-check
import React, { useState, useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { EmployeesFinderAutocomplete } from './EmployeesFinderAutocomplete';
import { employeesFinderVar } from './EmployeesFinder.vars';
import { useAllEmployees } from './useAllEmployees';
import { useAllWorkCenters } from './useAllWorkCenters';
import { useAllWorkTitles } from './useAllWorkTitles';
import { useAllGroups } from './useAllGroups';
import {
  FIND_BY_EMPLOYEES,
  FIND_BY_WORKCENTERS,
  FIND_BY_WORKTITLES,
  FIND_BY_GROUPS,
  EMPLOYEE_ROW,
  WORK_CENTER_ROW,
  WORK_TITLE_ROW,
  GROUP_ROW,
} from './EmployeesFinder.constants';
import { useEmployeesIdsValidator } from './useEmployeesIdsValidator';
import { cleanText } from '../../containers/RHPod/Documents/PdfDocuments/PdfDocuments.utils';

/** @param {import('./EmployeesFinder.types').EmployeesFinderProps} props */
export const EmployeesFinder = (props) => {
  const {
    selectedRows,
    onSelectRow,
    onSelectedRowsOnPaste,
    handlePasterErrors,
    isEmployeeSelected,
    findBy,
    disabled,
    excludeAdminsAsEmployees,
    handleSingleItem,
    unSelectRow,
    hideMenu = false,
    allowDeselect = false,
  } = props;
  const [searchValue, setSearchValue] = useState('');
  /** @type {import('./EmployeesFinder.types').DebounceRef} */
  const debounceRef = useRef(null);
  const excludeAdmins = Boolean(excludeAdminsAsEmployees);

  const { open, data, findBySelected } = useReactiveVar(employeesFinderVar);

  const { handleFetchAllEmployees, handleLoadMoreEmployees } = useAllEmployees({
    searchValue,
    excludeAdminsAsEmployees: excludeAdmins,
  });
  const { handleFetchAllWorkCenters, handleLoadMoreWorkCenters } =
    useAllWorkCenters({
      searchValue,
    });
  const { handleFetchAllWorkTitles } = useAllWorkTitles({ searchValue });
  const { handleFetchAllGroups } = useAllGroups({ searchValue });
  const onEmployeesValidated = (employeesIds) => {
    if (onSelectedRowsOnPaste) {
      onSelectedRowsOnPaste(employeesIds);
    }
  };
  const onEmployeesValidatedError = (errors) => {
    if (handlePasterErrors) {
      handlePasterErrors(errors);
    }
  };

  const { validateEmployeesIds } = useEmployeesIdsValidator({
    onEmployeesValidated,
    onEmployeesValidatedError,
  });

  const onPaste = async (event) => {
    if (findBySelected !== FIND_BY_EMPLOYEES) return;
    const pastedText = event.clipboardData.getData('text/plain');
    const formattedText = cleanText(pastedText);
    let uniqueUsers = [];
    if (formattedText?.length) {
      if (!formattedText.includes(',')) return;
      const users = formattedText.split(',');
      const setUsers = new Set(users);
      uniqueUsers = Array.from(setUsers);
      uniqueUsers = uniqueUsers.filter((user) => user?.length >= 1);
    }
    await validateEmployeesIds(uniqueUsers);
    setSearchValue('');
  };

  const openQueryMapper = {
    [FIND_BY_EMPLOYEES]: handleFetchAllEmployees,
    [FIND_BY_WORKCENTERS]: handleFetchAllWorkCenters,
    [FIND_BY_WORKTITLES]: handleFetchAllWorkTitles,
    [FIND_BY_GROUPS]: handleFetchAllGroups,
  };
  const searchQueryMapper = {
    [FIND_BY_EMPLOYEES]: handleFetchAllEmployees,
    [FIND_BY_WORKCENTERS]: handleFetchAllWorkCenters,
  };
  const loadMoreQueryMapper = {
    [FIND_BY_EMPLOYEES]: handleLoadMoreEmployees,
    [FIND_BY_WORKCENTERS]: handleLoadMoreWorkCenters,
  };

  const handleLoadMore = () => {
    if (loadMoreQueryMapper[findBySelected]) {
      loadMoreQueryMapper[findBySelected]();
    }
  };

  useEffect(() => {
    if (open && data.options.length === 0) {
      if (openQueryMapper[findBySelected]) {
        employeesFinderVar({ ...employeesFinderVar(), loading: true });
        openQueryMapper[findBySelected]();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (open) {
      if (searchQueryMapper[findBySelected]) {
        employeesFinderVar({ ...employeesFinderVar(), loading: true });
        searchQueryMapper[findBySelected]();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  /**  @param {string} value */
  const handleInputChange = (value) => {
    if (value.includes(',')) return;
    if (debounceRef.current) clearTimeout(debounceRef.current);
    debounceRef.current = window.setTimeout(() => {
      // work centers name can be 2 characters long
      if (value.length >= 2 && findBySelected === FIND_BY_WORKCENTERS) {
        setSearchValue(value);
      } else if (value.length >= 3) {
        setSearchValue(value);
      }
      if (!value) setSearchValue('');
    }, 700);
  };

  /** @type {import('./EmployeesFinder.types').IsRowSelected} */
  const isRowSelected = (row) => {
    if (row.type === EMPLOYEE_ROW && isEmployeeSelected) {
      return isEmployeeSelected(row);
    }
    return selectedRows.some((item) => {
      if (
        (item.type === EMPLOYEE_ROW && row.type === EMPLOYEE_ROW) ||
        (item.type === WORK_CENTER_ROW && row.type === WORK_CENTER_ROW)
      ) {
        return item._id === row._id;
      }
      if (
        (item.type === WORK_TITLE_ROW && row.type === WORK_TITLE_ROW) ||
        (item.type === GROUP_ROW && row.type === GROUP_ROW)
      ) {
        return item.name === row.name;
      }

      return false;
    });
  };

  return (
    <EmployeesFinderAutocomplete
      selectedRows={selectedRows}
      onInputChange={handleInputChange}
      onSelectRow={onSelectRow}
      onLoadMore={handleLoadMore}
      isRowSelected={isRowSelected}
      search={searchValue}
      findBy={findBy}
      disabled={disabled}
      onPaste={async (event) => {
        await onPaste(event);
      }}
      handleSingleItem={Boolean(handleSingleItem)}
      unSelectRow={unSelectRow}
      hideMenu={hideMenu}
      allowDeselect={allowDeselect}
    />
  );
};
