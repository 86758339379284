// @ts-check
import { Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { withErrorBoundary } from '@sentry/react';
import { useTheme } from '@mui/material/styles';
import { DefaultErrorComponent } from '../../../../../components/ErrorBoundary/ErrorBoundary';
import { loggerUtil } from '../../../../../utils/loggerUtil';
import { Typography } from '../../../../../newComponents/Typography';
import { Button } from '../../../../../newComponents/Button';
import { IconButton } from '../../../../../newComponents/IconButton';
import { userUpdatedVar } from '../../../../MyCompany/Settings/UsersAndRoles/UserAndRoles.vars';
import { useGetCompanyEmployeesInput } from '../../Hooks/useGetCompanyEmployeesInput.hooks';
import { GET_EMPLOYEE_INFO } from '../EmployeeInfo.gql';
import { ControlledPhoneInput } from '../../../../../components/ControlledInputs/ControlledPhoneInput';
import {
  ControlledCountryInput,
  ControlledEmailInput,
  ControlledZipCodeInput,
  ControlledStreetInput,
  ControlledStateInput,
  ControlledCityInput,
  ControlledColonyInput,
  ControlledInternalNumberInput,
  ControlledExternalNumberInput,
} from '../../../../../businessComponents/ControlledInputs';
import {
  UPDATE_EMPLOYEE_DATA,
  UPDATE_ACTIVE_USER_DATA,
  GET_COMPANY_EMPLOYEES,
  GET_EMPLOYEE_DATA_CHANGE_REQUEST,
} from '../../Employees.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import {
  getContactDataModified,
  updateUserResHandler,
} from './EmployeeInfoGeneralData.utils';

export const EmployeeContactDataForm = withErrorBoundary(
  /**
   * @param {object} props
   * @param {import('../EmployeeInfo.types').EmployeeInfo} props.employeeData
   * @param {import("../../../../../types/permissions.types").UserPermissions} props.userPermissions
   * @param {import('../EmployeeInfo.types').ContactDataForm} props.employeeContactDataForm
   * @param {() => void} props.resetEmployeeContactDataForm
   */
  ({
    employeeData,
    userPermissions,
    employeeContactDataForm,
    resetEmployeeContactDataForm,
  }) => {
    /** @type {import('../../../../../theme').CustomTheme} */
    const theme = useTheme();
    const [isFormEnabled, setIsFormEnabled] = useState(false);
    const [contactMethodRequired, setContactMethodRequired] = useState(false);
    const userHasEverBeenActive = !!employeeData?.hasEverBeenActive;
    const [isZipCodeEmpty, setIsZipCodeEmpty] = useState(true);
    const [addressRequired, setAddressRequired] = useState(false);
    const {
      watch,
      handleSubmit,
      control,
      getValues,
      setValue,
      formState: { isDirty, dirtyFields },
      reset,
    } = employeeContactDataForm;
    const country = watch('country');

    const { getCompanyEmployeesInput } = useGetCompanyEmployeesInput();
    const [updateEmployeeData] = useMutation(UPDATE_EMPLOYEE_DATA, {
      refetchQueries: [
        {
          query: GET_COMPANY_EMPLOYEES,
          variables: { input: getCompanyEmployeesInput },
        },
        {
          query: GET_EMPLOYEE_INFO,
          variables: { input: { employeeId: employeeData.userId } },
        },
        {
          query: GET_EMPLOYEE_DATA_CHANGE_REQUEST,
          variables: { userId: employeeData.userId },
        },
      ],
    });
    const [updateActiveUserData] = useMutation(UPDATE_ACTIVE_USER_DATA, {
      refetchQueries: [
        {
          query: GET_COMPANY_EMPLOYEES,
          variables: { input: getCompanyEmployeesInput },
        },
        {
          query: GET_EMPLOYEE_INFO,
          variables: { input: { employeeId: employeeData.userId } },
        },
        {
          query: GET_EMPLOYEE_DATA_CHANGE_REQUEST,
          variables: { userId: employeeData.userId },
        },
      ],
    });

    useEffect(() => {
      const isRemoteUser = employeeData?.alta === 'SIFE';
      const subscription = watch((value) => {
        const emailEmpty = !value.email;
        const phoneEmpty = !value.phone;
        const countryEmpty = !value.country;
        const zipCodeEmpty = !value.zipCode;
        const streetEmpty = !value.street;
        const intNumberEmpty = !value.internalNumber;
        const extNumberEmpty = !value.externalNumber;

        setIsZipCodeEmpty(zipCodeEmpty);
        setAddressRequired(
          !countryEmpty ||
            !zipCodeEmpty ||
            !streetEmpty ||
            !intNumberEmpty ||
            !extNumberEmpty,
        );
        setContactMethodRequired(isRemoteUser && emailEmpty && phoneEmpty);
      });
      return () => subscription.unsubscribe();
    }, [watch, employeeData]);

    const onSubmitActiveUser = async () => {
      const userValues = getValues();
      const modifiedFields = getContactDataModified({
        dirtyFields,
        formData: userValues,
      });

      try {
        globalBackdropVar({
          open: true,
          clickable: false,
          text: 'Procesando',
        });
        const { data } = await updateActiveUserData({
          variables: {
            inputData: {
              ...modifiedFields,
              _id: employeeData.userId,
            },
          },
        });
        const { message, status } = updateUserResHandler({
          data,
        });

        globalSnackbarVar({
          show: true,
          severity: status,
          message: message,
        });
        if (
          [
            'Success',
            'UserDataChangeRequestSent',
            'UserDataChangeRequestSentContactMethodsUpdated',
          ].includes(data.updateActiveUserData.__typename)
        ) {
          reset(userValues);
        }
        const typeName = data.updateActiveUserData.__typename;
        if (
          typeName === 'UserDataChangeRequestSentContactMethodsUpdated' ||
          typeName === 'Success'
        ) {
          userUpdatedVar({ ...employeeData, ...modifiedFields });
        }
      } catch (error) {
        loggerUtil.error(error);
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Hubo un error. Contacte a Sora',
        });
      } finally {
        globalBackdropVar({ open: false });
        setIsFormEnabled(false);
      }
    };

    const onSubmitInactiveUser = async (formData) => {
      try {
        globalBackdropVar({
          open: true,
          clickable: false,
          text: 'Actualizando datos',
        });

        const modifiedFields = getContactDataModified({
          dirtyFields,
          formData,
        });

        const { data } = await updateEmployeeData({
          variables: {
            input: {
              ...modifiedFields,
              _id: employeeData.userId,
            },
          },
        });
        const resultType = data?.updateEmployeeData;
        if (resultType?.__typename === 'Success') {
          globalSnackbarVar({
            show: true,
            message: 'Datos actualizados con exito',
            severity: 'success',
          });
          reset(formData);
          setIsFormEnabled(false);
          userUpdatedVar({ ...employeeData, ...modifiedFields });
          return;
        }

        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: resultType?.message || 'Ocurrió un error, contacte a Sora',
        });
      } catch (error) {
        loggerUtil.error(error);
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Ocurrió un error, contacte a Sora',
        });
      } finally {
        globalBackdropVar({ open: false });
        setIsFormEnabled(false);
      }
    };

    const onSubmitError = async () => {
      globalSnackbarVar({
        show: true,
        severity: 'warning',
        message: 'Verifica los campos',
      });
    };

    const resetCountryDependentFields = () => {
      setValue('zipCode', '');
      setValue('state', '');
      setValue('city', '');
      setValue('colony', '');
    };

    const userCanEditForm = userPermissions && userPermissions?.mply?.update;

    return (
      <Stack gap={2}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Typography
            variant="h6"
            color={theme.customPalette.textColor.text_title_black}
          >
            Datos de contacto
          </Typography>
          {!isFormEnabled ? (
            <IconButton
              tooltipText={
                userCanEditForm
                  ? 'Da click aquí para editar'
                  : 'Necesitas permisos para esta acción'
              }
              tooltipArrow
              tooltipPlacement="top"
              icon="edit_line"
              aria-label="edit"
              size="small"
              onClick={() => setIsFormEnabled(true)}
              disabled={!userCanEditForm}
            />
          ) : null}
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ControlledEmailInput
              control={control}
              rules={{
                required: {
                  value: contactMethodRequired,
                  message: 'Correo electrónico o celular requerido',
                },
              }}
              textFieldProps={{
                disabled: !isFormEnabled,
                label: 'Correo electrónico',
                required: contactMethodRequired,
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledPhoneInput
              control={control}
              rules={{
                required: {
                  value: contactMethodRequired,
                  message: 'Correo electrónico o celular requerido',
                },
              }}
              muiProps={{
                disabled: !isFormEnabled,
                label: 'Celular',
                required: contactMethodRequired,
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledCountryInput
              control={control}
              textFieldProps={{
                disabled: !isFormEnabled,
                label: 'País',
                fullWidth: true,
              }}
              onChange={resetCountryDependentFields}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledZipCodeInput
              control={control}
              country={country}
              rules={{
                required: {
                  value: addressRequired,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{
                disabled: !isFormEnabled || !Boolean(country),
                required: addressRequired,
                label: 'Código Postal',
                fullWidth: true,
              }}
              onReceiveLocationData={(data) => {
                setValue('city', data.city);
                setValue('state', data.state);
                setValue('colony', data.colony);
              }}
              onClearZipCode={() => {
                resetCountryDependentFields();
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledStateInput
              control={control}
              rules={{
                required: {
                  value: addressRequired,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{
                disabled: true,
                required: addressRequired,
                label: 'Estado',
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledCityInput
              control={control}
              rules={{
                required: {
                  value: addressRequired,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{
                disabled: true,
                required: addressRequired,
                label: 'Ciudad',
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledColonyInput
              control={control}
              rules={{
                required: {
                  value: addressRequired,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{
                disabled: !isFormEnabled || isZipCodeEmpty,
                required: addressRequired,
                label: 'Colonia',
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledStreetInput
              control={control}
              rules={{
                required: {
                  value: addressRequired,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{
                disabled: !isFormEnabled,
                required: addressRequired,
                label: 'Calle',
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledExternalNumberInput
              control={control}
              rules={{
                required: {
                  value: addressRequired,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{
                disabled: !isFormEnabled,
                required: addressRequired,
                label: 'Número exterior',
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledInternalNumberInput
              control={control}
              textFieldProps={{
                disabled: !isFormEnabled,
                label: 'Número interior',
                fullWidth: true,
              }}
            />
          </Grid>
        </Grid>
        <Stack direction={'row'} justifyContent={'end'} spacing={2}>
          {isFormEnabled ? (
            <Button
              color="default"
              variant="outlined"
              onClick={() => {
                setIsFormEnabled(false);
                resetEmployeeContactDataForm();
              }}
            >
              Cancelar
            </Button>
          ) : null}

          <Button
            tooltipText={
              userCanEditForm ? '' : 'Necesitas permisos para esta acción'
            }
            tooltipArrow
            tooltipPlacement="top"
            color="primary"
            variant="contained"
            style={{ alignSelf: 'flex-end' }}
            disabled={!isDirty}
            onClick={() => {
              handleSubmit(
                userHasEverBeenActive
                  ? onSubmitActiveUser
                  : onSubmitInactiveUser,
                onSubmitError,
              )();
            }}
          >
            Actualizar
          </Button>
        </Stack>
      </Stack>
    );
  },
  {
    fallback: <DefaultErrorComponent />,
  },
);
