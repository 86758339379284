import {
  BodyMedium,
  H5,
  H6,
} from '../../../../components/Typographies/Typography';
import { Icon } from '../../../../components/Icons/Icons';
import { SpacedWrapper } from '../../../../components/Utils/Utils';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useReducer, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ViewFileIcon } from './ViewFileIcon';
import DocumentSigner from '../../../../components/DocumentSigner/DocumentSigner';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../components/Buttons/Buttons';
import { xmlResumeReducer, initialState } from './LinkingXmlReducer';
import { UploadPdfOnlyComponent } from './UploadPdfOlnlyComponent';
import ConfirmationDialog from '../../../../components/Dialogs/ConfirmationDialog';
import { useMutation } from '@apollo/client';
import { CONFIRM_XML_RECEIPTS_TO_LINK, GET_RECEIPTS } from '../gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

const ReceiptFoundSelector = ({
  receiptsFound = [],
  dispatch,
  stampUuid,
  xmlDetails,
  userId,
  globalState,
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const onSelectPdf = (e, value) => {
    setSelectedOption(value);
    dispatch({
      type: 'SELECT_PDF',
      payload: { selectedReceipt: value, stampUuid },
    });
  };

  switch (receiptsFound.length) {
    case 0:
      return (
        <UploadPdfOnlyComponent
          stampUuid={stampUuid}
          dispatch={dispatch}
          xmlDetails={xmlDetails}
          userId={userId}
          globalState={globalState}
        />
      );
    case 2:
    default:
      return (
        <>
          <FormControl>
            <RadioGroup value={selectedOption} onChange={onSelectPdf}>
              {receiptsFound.map((receiptFound, i) => (
                <Stack key={i} direction={'row'} alignItems={'center'}>
                  <FormControlLabel
                    key={receiptFound._id}
                    value={receiptFound._id}
                    control={<Radio />}
                    label={`Recibo ${i + 1}`}
                  />
                  <ViewFileIcon
                    dispatch={dispatch}
                    docId={receiptFound._id}
                    userId={userId}
                  />
                </Stack>
              ))}
            </RadioGroup>
          </FormControl>
        </>
      );
  }
};

export const LinkingXmlImportResume = ({
  xmlValidationData,
  setCurrentStage,
  setCompleted,
}) => {
  const theme = useTheme();
  const [receiptsToResolve, setReceiptsToResolve] = useState([]);
  const [state, dispatch] = useReducer(xmlResumeReducer, initialState);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmXmlReceiptsToLink] = useMutation(CONFIRM_XML_RECEIPTS_TO_LINK, {
    refetchQueries: [{ query: GET_RECEIPTS }],
  });

  useEffect(() => {
    setReceiptsToResolve(
      xmlValidationData.fileLookupResume.filter(
        (r) => r.receiptsFound.length !== 1,
      ),
    );
  }, [xmlValidationData]);

  const getErrorMessage = (receiptsFoundLength = 0) => {
    if (receiptsFoundLength >= 2) {
      return `Existen ${receiptsFoundLength} recibos PDF con el mismo periodo de pago. Elige el PDF correcto.`;
    } else {
      return `No se encontró el recibo PDF. Carga el archivo PDF.`;
    }
  };

  const confirmLinkingXmlImport = async () => {
    const pendingWarnings = xmlValidationData.fileLookupResume.length;
    const solvedWarnings = Object.keys(state.receiptsSelection).filter(
      (uuid) => state.receiptsSelection[uuid] !== undefined,
    ).length;
    if (pendingWarnings > solvedWarnings) {
      setOpenConfirmDialog(true);
    } else {
      await uploadLinkingXml();
    }
  };

  const onOmitWarnings = async (value) => {
    setOpenConfirmDialog(false);
    if (value) {
      await uploadLinkingXml();
    }
  };

  const uploadLinkingXml = async () => {
    const foundReceipts = xmlValidationData.fileLookupResume
      .filter((r) => r.receiptsFound.length === 1)
      .map((r) => ({
        uuid: r.stampUuid,
        id: r.receiptsFound[0]._id,
        type: 'FOUND',
      }));
    const solvedReceipts = Object.keys(state.receiptsSelection).map((key) => ({
      uuid: key,
      type: state.receiptsSelection[key].type,
      id: state.receiptsSelection[key].id,
      requestId: state.receiptsSelection[key].requestId,
    }));
    const solvingReceipts = [...foundReceipts, ...solvedReceipts];
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Procesando recibos',
      });
      const { data } = await confirmXmlReceiptsToLink({
        variables: {
          input: {
            requestId: xmlValidationData.requestKey,
            stampList: xmlValidationData.requestStamps,
            solvingReceipts: solvingReceipts,
          },
        },
      });
      switch (data.confirmXmlReceiptsToLink.__typename) {
        case 'Success':
          setCompleted(true);
          globalSnackbarVar({
            message: 'Recibos importados exitosamente',
            show: true,
            severity: 'success',
          });
          break;
        case 'SelectedPdfConflict':
          globalSnackbarVar({
            message:
              'Hay un conflicto en los PDF encontrados y los seleccionados',
            severity: 'error',
            show: true,
          });
          break;
        default:
          globalSnackbarVar({
            message: 'Hubo un error al importar los recibos. Contacte a Sora.',
            severity: 'error',
            show: true,
          });
      }
    } catch (e) {
      console.error(e);
    } finally {
      globalBackdropVar({
        open: false,
      });
    }
  };

  return (
    <>
      <SpacedWrapper column m="32px 24px 32px 24px">
        <H5>Importar recibos</H5>
        <div>
          <div style={{ textAlign: 'center' }}>
            <div>
              <Icon
                icon={'error_warning_line'}
                fill={theme.customPalette.primary.yellow}
              />
            </div>
            <H6>
              <strong>Se encontraron algunos errores</strong>
            </H6>
            <p>
              <BodyMedium>
                Se encontraron los siguientes errores al momento de importar tus
                recibos:{' '}
              </BodyMedium>
            </p>
          </div>
        </div>
        <div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Archivo</TableCell>
                  <TableCell>Error</TableCell>
                  <TableCell style={{ minWidth: '220px' }}>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {receiptsToResolve.map((fileResume) => (
                  <TableRow key={fileResume.stampUuid}>
                    <TableCell component="th" scope="row">
                      {fileResume.filename}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {getErrorMessage(fileResume.receiptsFound.length)}
                    </TableCell>
                    <TableCell>
                      <ReceiptFoundSelector
                        receiptsFound={fileResume.receiptsFound}
                        dispatch={dispatch}
                        globalState={state}
                        stampUuid={fileResume.stampUuid}
                        xmlDetails={fileResume.receiptDetails}
                        userId={fileResume.userId}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack mt={2} spacing={2} direction="row" justifyContent={'end'}>
            <SecondaryButton onClick={() => setCurrentStage('SELECT_TYPE')}>
              Cancelar importación
            </SecondaryButton>
            <PrimaryButton onClick={confirmLinkingXmlImport}>
              Continuar
            </PrimaryButton>
          </Stack>
        </div>
        {state.signer.documentId && (
          <DocumentSigner
            signed={true}
            handleClose={() => {
              dispatch({ type: 'CLOSE_SIGNER' });
            }}
            company={''}
            title={'Recibo PDF'}
            docId={state.signer.documentId}
            admin={true}
            userId={state.signer.userId}
            signable={false}
            requestType="RECEIPT"
            open={state.signer.open}
            onSuccess={() => {}}
            viewOnly={true}
          />
        )}
        <ConfirmationDialog
          open={openConfirmDialog}
          onClose={onOmitWarnings}
          title="¿Quieres omitir los recibos con errores?"
          content="Si continuas lo recibos XML con errores serán omitidos."
        />
      </SpacedWrapper>
    </>
  );
};
