import { useReactiveVar } from '@apollo/client';
import { Container, Stack } from '@mui/material';
import React from 'react';
import { NoPermissions } from '../../../RHPod/NoPermissions';
import { Accordion } from './Accordion';
import { EmployeeRecordTagsCatalog } from '../../../Settings/Preferences/EmployeeRecordTagsCatalog';
import { Permissions } from './Permissions/Permissions';
import { CreateTemplateContainer } from '../../../RHPod/Documents/Templates/CreateTemplateContainer';
import { WorkTitles } from '../../../Settings/Preferences/WorkTitles/WorkTitles';
import { RecordChecklist } from '../../../Settings/Preferences/RecordChecklist/RecordChecklist';
import { Grupos } from '../../../Settings/Preferences/Groups/Groups';
import { userPermissionsVar } from '../../../../cache.reactiveVars';
import { Billing } from './Billing/Billing';
import { Notifications } from '../../../Settings/Preferences/Notifications/Notifications';

export const Preferences = () => {
  const userPermissions = useReactiveVar(userPermissionsVar);

  if (
    userPermissions &&
    !userPermissions.compData?.update &&
    !userPermissions.compData?.read
  ) {
    return <NoPermissions />;
  }

  return (
    <Container maxWidth="xl" disableGutters>
      <Stack gap={2} mt={2} p={{ xs: 0, md: 5 }}>
        <Accordion icon="folders_line" label="Catálogo de documentos">
          <EmployeeRecordTagsCatalog />
        </Accordion>
        <Accordion icon="file_text_line" label="Plantillas">
          <CreateTemplateContainer />
        </Accordion>
        <Accordion icon="survey_line" label="Expedientes laborales">
          <RecordChecklist />
        </Accordion>
        <Accordion icon="suitcase_line" label="Puestos de trabajo">
          <WorkTitles />
        </Accordion>
        <Accordion icon="team_line" label="Grupos">
          <Grupos />
        </Accordion>
        <Accordion icon="notification_line" label="Notificaciones">
          <Notifications />
        </Accordion>
        <Accordion icon="quill_pen_line" label="Permisos de firmado">
          <Permissions value="signingPermissions" />
        </Accordion>
        <Accordion icon="admin_line" label="Permisos a empleados">
          <Permissions value="employeePermissions" />
        </Accordion>
        <Accordion icon="file_chart_line" label="Facturación">
          <Billing />
        </Accordion>
      </Stack>
    </Container>
  );
};
