// @ts-check
import { useState, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import { useQuery } from '@apollo/client';
import { Button } from '../../../../../newComponents/Button';
import { Typography } from '../../../../../newComponents/Typography';
import { SoraGrid } from '../../../../../components/Datagrid/Datagrid';
import { SearchForm } from '../../../../../components/Inputs/SearchForm';
import { addCreatorInfoToTemplateItems } from './TemplateTable.helpers';
import { columns } from './TemplateTable.columns';
import { usePermissionChecker, useScreenSize } from '../../../../../Hooks';
import { TemplateEmptySpace } from '../TemplateEmptySpace';
import { ALL_TEMPLATES_FROM_TABLE } from '../../gql';
import { tableStyles } from './TemplateTable.styles';
import type { TemplateTableProps } from './TemplateTable.types';
import {
  globalBackdropVar,
  globalSnackbarVar,
  refetchFunctionsVar,
} from '../../../../../cache.reactiveVars';

export const TemplateTable = (props: TemplateTableProps) => {
  const { isMobile } = useScreenSize();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const {
    handleEditSettingsAction,
    handleEditContentAction,
    handleDeleteAction,
    handleCreateAction,
  } = props;
  const userHasPermissionToCreateOrEdit = usePermissionChecker({
    permission: 'templates',
    action: 'update',
  });

  const modifiedColumns = columns({
    handleEditSettingsAction,
    handleEditContentAction,
    handleDeleteAction,
    userHasPermissionToCreateOrEdit: Boolean(userHasPermissionToCreateOrEdit),
  });

  const {
    loading: allTemplatesLoading,
    refetch: refetchAllTemplates,
    fetchMore: fetchMoreTemplates,
    data: allTemplatesData,
  } = useQuery(ALL_TEMPLATES_FROM_TABLE, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: { page: 0, perPage: 10, search: '' },
    onError: (err) => {
      // @ts-ignore
      if (err.networkError?.statusCode === 401) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Acceso denegado',
        });
      }
    },
  });
  const {
    total = 0,
    creatorUsers = [],
    templates = [],
  } = allTemplatesData?.allTemplates || {};
  const newTemplates = addCreatorInfoToTemplateItems({
    creatorUsers,
    templates,
  });
  const templatesEmpty = total === 0;
  const allTemplates = { total, templates: newTemplates };

  const handlePageChange = (newPage) => {
    const maxPageVisited = allTemplates.templates.length / perPage;
    setPage(newPage);
    if (newPage < maxPageVisited) return;
    if (newPage < page) return;
    fetchMoreTemplates({ variables: { perPage, page: newPage } });
  };

  const handlePageSizeChange = (newPerPage) => {
    setPage(0);
    refetchAllTemplates({ perPage: newPerPage });
    setPerPage(newPerPage);
  };

  const handleChangeSearch = (newSearch) => {
    setPage(0);
    setPerPage(10);
    setSearch(newSearch);
    refetchAllTemplates({ page: 0, perPage: 10, search: newSearch });
  };

  useEffect(() => {
    globalBackdropVar({
      open: allTemplatesLoading,
      text: 'Obteniendo plantillas...',
    });
  }, [allTemplatesLoading]);

  useEffect(() => {
    if (refetchAllTemplates) {
      refetchFunctionsVar({
        ...refetchFunctionsVar(),
        refetchAllTemplates: () => {
          setPage(0);
          setPerPage(10);
          refetchAllTemplates();
        },
      });
    }
  }, [refetchAllTemplates]);

  if (templatesEmpty && search === '' && !allTemplatesLoading) {
    return <TemplateEmptySpace onCreateTemplate={handleCreateAction} />;
  }

  return (
    <Stack sx={{ height: 'auto', width: '100%' }} gap="24px">
      <Stack
        direction={{ sm: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        gap={1}
      >
        <Stack>
          <Typography variant="h5">Plantillas</Typography>
          <Typography variant="body1">
            Las plantillas te ayudan a diseñar documentos con un aspecto y
            formato profesional de acuerdo a las necesidades y lineamientos de
            tu empresa.
          </Typography>
        </Stack>

        <Button
          sx={{ minWidth: '150px' }}
          variant="contained"
          size={isMobile ? 'small' : 'large'}
          fullWidth={isMobile}
          onClick={props.handleCreateAction}
          disabled={!userHasPermissionToCreateOrEdit}
          tooltipText={
            !userHasPermissionToCreateOrEdit &&
            'No cuentas con los permisos para esta acción'
          }
          tooltipPlacement="top"
          tooltipArrow
        >
          Crear plantilla
        </Button>
      </Stack>
      <Stack>
        <SearchForm
          fullWidth={true}
          handleSearch={(value) => handleChangeSearch(value)}
        />
      </Stack>
      <Box>
        <SoraGrid
          getRowId={(row) => row._id}
          autoHeight
          disableColumnMenu
          disableSelectionOnClick
          checkboxSelection={false}
          columns={modifiedColumns}
          rows={allTemplates.templates}
          rowCount={allTemplates.total}
          page={page}
          pageSize={perPage}
          rowBuffer={perPage}
          onPageChange={(newPage) => handlePageChange(newPage)}
          onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
          disableColumnFilter
          disableColumnSelector
          headerHeight={60}
          hideFooter={false}
          rowHeight={74}
          rowsPerPageOptions={[10, 20, 50]}
          sx={tableStyles}
          hideConfig
        />
      </Box>
    </Stack>
  );
};
