import { useTheme } from '@mui/system';
import styled from 'styled-components';
import { SecondaryButton } from '../../../../components/Buttons/Buttons';

export const GradientButtonBaseComponent = styled.div`
  ${(props) =>
    props.selected &&
    `color: ${
      props.receipts
        ? props.palette.primary.red
        : props.palette.status.success_green_70
    };
    background: ${
      props.receipts
        ? 'linear-gradient(white, white) padding-box, linear-gradient(to right, rgba(255, 180, 50, 1.0), rgba(249, 92, 94, 1.0)) border-box'
        : 'linear-gradient(white, white) padding-box, linear-gradient(to right, rgba(30, 149, 255, 1.0), rgba(21, 240, 176, 1.0)) border-box'
    };
    box-shadow: 0px 4px 8px 0px #41435B33, 0px 2px 4px 0px #41435B33;`};
  height: 57px;
  width: 100%;
  flex-grow: 1;
  border: 1px solid transparent;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  ${(props) => `padding-left: ${props.subItem ? '35px' : null}`};
  ${(props) =>
    `background: ${props.bgColor && props.selected ? props.bgColor : null}`};
  ${(props) => `color: ${props.color && props.selected ? props.color : null}`};
  ${(props) =>
    `border: ${
      props.bgColor && props.selected
        ? `1px solid ${props.color}`
        : '1px solid transparent'
    }`};
  // padding-left: 20px
`;

export const GradientButton = (props) => {
  const theme = useTheme();
  return (
    <GradientButtonBaseComponent {...props} palette={theme.customPalette} />
  );
};

export const menuProps = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: '240px',
        maxWidth: '300px',
      },
    },
  },
};

export const TableIconBaseComponent = styled.div`
  height: ${(props) => props.size || '40px'};
  width: ${(props) => props.size || '40px'};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.gradient || props.palette.gradients.yellow_red};
  border-radius: 50%;
`;
export const TableIcon = (props) => {
  const theme = useTheme();
  return <TableIconBaseComponent {...props} palette={theme.customPalette} />;
};

export const StyledSecondaryButton = ({ ...props }) => {
  const palette = useTheme().customPalette;
  return (
    <SecondaryButton
      sx={{
        borderRadius: '8px',
        '&:disabled': {
          color: palette.textColor.text_light_disabled,
          border: '0px',
          backgroundColor: palette.grayColors.gray_10,
        },
      }}
      {...props}
    />
  );
};
