// @ts-check
import { useEffect } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { ALL_COMPANY_WORK_TITLES } from '../../containers/Settings/Preferences/WorkTitles/WorkTitles.gql';
import { globalSnackbarVar } from '../../cache.reactiveVars';
import {
  WORK_TITLE_ROW,
  FIND_BY_WORKTITLES,
} from './EmployeesFinder.constants';
import { employeesFinderVar } from './EmployeesFinder.vars';

/**  @param {import('./EmployeesFinder.types').QueryHookProps} params */
export const useAllWorkTitles = ({ searchValue }) => {
  const { workTitles, findBySelected } = useReactiveVar(employeesFinderVar);

  const [allWorkTitlesQuery] = useLazyQuery(ALL_COMPANY_WORK_TITLES, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,

    onCompleted: (response) => {
      if (response?.allCompanyWorkTitles) {
        const { workTitles } = response.allCompanyWorkTitles;
        const mapFunc = (row) => ({ ...row, type: WORK_TITLE_ROW });
        const newWorkTitles = workTitles.map(mapFunc);

        const filteredWorkTitles = newWorkTitles.filter((workTitle) =>
          workTitle.name.toLowerCase().includes(searchValue.toLowerCase()),
        );

        employeesFinderVar({
          ...employeesFinderVar(),
          workTitles: newWorkTitles,
          data: { options: filteredWorkTitles, total: workTitles.length },
          loading: false,
        });
      }
    },
    onError: (error) => {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Hubo un error al obtener puestos de trabajo',
        duration: 5000,
      });
    },
  });

  const handleFetchAllWorkTitles = () => {
    allWorkTitlesQuery({
      variables: {
        filter: { excludeEmpty: true, excludeEmployeesData: true },
      },
    });
  };

  useEffect(() => {
    if (findBySelected === FIND_BY_WORKTITLES) {
      const filteredWorkTitles = workTitles.filter((workTitle) =>
        workTitle.name.toLowerCase().includes(searchValue.trim().toLowerCase()),
      );
      employeesFinderVar({
        ...employeesFinderVar(),
        data: { options: filteredWorkTitles, total: filteredWorkTitles.length },
        loading: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, findBySelected]);

  return {
    handleFetchAllWorkTitles,
  };
};
