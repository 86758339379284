// @ts-check
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { ALL_WORKCENTERS_LIST } from '../../containers/RHPod/Employees/Employees.gql';
import { sidebarGradients } from '../../components/Colors/Colors';
import { globalSnackbarVar } from '../../cache.reactiveVars';
import { WORK_CENTER_ROW } from './EmployeesFinder.constants';
import { employeesFinderVar } from './EmployeesFinder.vars';

/**  @param {import('./EmployeesFinder.types').QueryHookProps} params */
export const useAllWorkCenters = ({ searchValue }) => {
  const { data } = useReactiveVar(employeesFinderVar);

  const [allWorkCentersQuery, { fetchMore: fetchMoreWorkCenters }] =
    useLazyQuery(ALL_WORKCENTERS_LIST, {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onCompleted: (response) => {
        if (response?.getAllWorkCenters) {
          const { workCenters, total } = response.getAllWorkCenters;
          const mapFunc = (row, index) => ({
            ...row,
            color: sidebarGradients[index % sidebarGradients.length],
            type: WORK_CENTER_ROW,
          });
          const newWorkCenters = workCenters.map(mapFunc);
          employeesFinderVar({
            ...employeesFinderVar(),
            data: { options: newWorkCenters, total },
            loading: false,
          });
        }
      },
      onError: (error) => {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Hubo un error al obtener centros de trabajo',
          duration: 5000,
        });
      },
    });

  const handleFetchAllWorkCenters = () => {
    allWorkCentersQuery({
      variables: {
        page: 0,
        perPage: 10,
        filter: { search: searchValue, excludeBajaUsers: false },
      },
    });
  };

  const handleLoadMoreWorkCenters = () => {
    const workCentersLength = data.options.length;
    if (workCentersLength === data.total) return;
    const newWorkCenterPage = Math.ceil(workCentersLength / 10);
    if (workCentersLength < data.total) {
      fetchMoreWorkCenters({
        variables: {
          perPage: 10,
          page: newWorkCenterPage,
        },
      });
    }
  };

  return {
    handleFetchAllWorkCenters,
    handleLoadMoreWorkCenters,
  };
};
