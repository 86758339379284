import { useReactiveVar } from '@apollo/client';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { environment } from '../../../API/instance/createInstance';
import DocumentSigner from '../../../components/DocumentSigner/DocumentSigner';
import logout from '../../../utils/logout';
import {
  currentCompanyVar,
  currentRoleVar,
  globalSnackbarVar,
  pendingDocsVar,
} from '../../../cache.reactiveVars';

const HighPriorityDocuments = ({
  shouldShowDialogs,
  setShouldShowDialogs,
  priorityDocs,
}) => {
  const pendingDocs = useReactiveVar(pendingDocsVar);
  const [open, setOpen] = useState(true);
  const currentCompany = useReactiveVar(currentCompanyVar);
  const history = useHistory();
  const currentRole = useReactiveVar(currentRoleVar);
  const hasAdminProfile =
    currentRole?.isMaster ||
    currentRole?.isAdminRh ||
    currentRole?.isAdmin?.length;

  /**
   * Params
   * @param {[{_id: string}]} docIds
   * @param {boolean} wasSigned false when document was marked as seen
   */
  const handleSuccessfulSign = (docIds, wasSigned = true) => {
    //Copia de todos los documentos
    const newHighPriorityDocs = [...(pendingDocs?.highPriorityDocs || [])];

    //Filtra por los documentos de la empresa actual
    const currentPriorityDocs = priorityDocs.filter(
      (doc) => doc?._id !== docIds[0]?.id,
    );
    //Filtra los documentos de todas las empresas que tiene asignadas el usuario
    const filteredNewPriority = newHighPriorityDocs.filter(
      (doc) => doc._id !== docIds[0].id,
    );
    const newPendingDocsVars = {
      ...pendingDocs,
      highPriorityDocs: filteredNewPriority,
    };
    pendingDocsVar(newPendingDocsVars);
    localStorage.pendingDocs = JSON.stringify(newPendingDocsVars);
    setShouldShowDialogs({
      ...shouldShowDialogs,
      askForHighPriority: false,
    });
    setOpen(false);
    if (currentPriorityDocs.length) {
      setTimeout(() => {
        setShouldShowDialogs({
          ...shouldShowDialogs,
          askForHighPriority: true,
        });
        setOpen(true);
      }, 500);
    }
    if (!currentPriorityDocs.length)
      globalSnackbarVar({
        show: true,
        status: 'success',
        message: wasSigned
          ? 'Firmaste tus documentos de alta prioridad'
          : 'Confirmaste de enterado tus documentos de alta prioridad',
      });
  };

  const handleClose = () => {
    if (environment === 'dev' || environment === 'prod') return;
    setShouldShowDialogs({
      ...shouldShowDialogs,
      askForHighPriority: false,
    });
    setOpen(false);
  };

  const handleCloseConfirmDialog = () => {
    if (environment !== 'dev' && environment !== 'prod') return handleClose();
    if (hasAdminProfile) return history.push('/admin');
    logout(history);
  };

  if (!priorityDocs.length) return null;

  return (
    <DocumentSigner
      handleCloseConfirmDialog={handleCloseConfirmDialog}
      pdf={priorityDocs[0]?.type === 'pdf'}
      docId={priorityDocs[0]?._id}
      title={priorityDocs[0]?.title}
      company={currentCompany.clientName}
      signed={false}
      requestType="DOCUMENT"
      signable={Boolean(priorityDocs[0]?.canBeSigned)}
      onSuccess={handleSuccessfulSign}
      open={open}
      handleClose={handleClose}
      showCloseButton={false}
      message="Este es un documento de alta prioridad que debe ser firmado a la brevedad"
    />
  );
};

export default HighPriorityDocuments;
