import React from 'react';
import { IconButton, Stack } from '@mui/material';
import { PrimaryButton } from '../Buttons/Buttons';
import { Icon } from '../Icons/Icons';
import { BodyLarge, BodyMedium } from '../Typographies/Typography';
import { SpacedWrapper } from '../Utils/Utils';
import SifeIFrameButton from '../SifeIFrameButton/SifeIFrameButton';

export const DocumentActionButtons = ({
  handleClose,
  showCloseButton,
  isSigned,
  signable,
}) => {
  const content =
    signable && isSigned
      ? 'Firmado'
      : !signable && isSigned
        ? 'Marcado como visto'
        : null;

  if (!showCloseButton && !content) return null;

  return (
    <Stack
      spacing={1}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{
        padding: '5px',
        top: 0,
        width: '100%',
        backgroundColor: 'white',
      }}
    >
      <div>
        {showCloseButton && (
          <IconButton size="large" onClick={handleClose}>
            <Icon fill="gray" icon="arrow_left_s_line" pointer />
          </IconButton>
        )}
      </div>

      {content && (
        <div>
          <SpacedWrapper spacing="8px" center>
            <BodyMedium>{content}</BodyMedium>
            <Icon icon="check_line" color="success" />
          </SpacedWrapper>
        </div>
      )}

      <div></div>
    </Stack>
  );
};

export const DocumentSignButton = ({
  requestedDocuments,
  handleDocumentSeen,
  handleCloseIframe = () => {},
  onSuccess,
  signable,
  isSigned,
  isActive = true,
  viewOnly = false,
  signLabel = undefined,
  tooltipText = '',
  disabled = false,
  shouldBlockOnSign = false,
  onClickModuleBlocked = () => {},
  ...props
}) => {
  if ((!isActive && !isSigned) || viewOnly) {
    return <></>;
  }

  return (
    <React.Fragment>
      {signable && isSigned && (
        <SpacedWrapper spacing="8px" center>
          <BodyLarge>Firmado</BodyLarge>
          <Icon icon="check_line" color="success" />
        </SpacedWrapper>
      )}
      {signable && !isSigned && (
        <SifeIFrameButton
          requestedDocuments={requestedDocuments}
          onSuccess={onSuccess}
          handleCloseIframe={handleCloseIframe}
          buttonWidth={props?.buttonWidth}
          signLabel={signLabel}
          tooltipText={tooltipText}
          disabled={disabled}
          shouldBlockOnSign={shouldBlockOnSign}
          onClickModuleBlocked={onClickModuleBlocked}
        />
      )}
      {!signable && isSigned && (
        <SpacedWrapper spacing="8px" center>
          <BodyLarge>Marcado como visto</BodyLarge>
          <Icon icon="check_line" color="success" />
        </SpacedWrapper>
      )}
      {!signable && !isSigned && (
        <PrimaryButton onClick={handleDocumentSeen} width={props?.buttonWidth}>
          Confirmar de enterado
        </PrimaryButton>
      )}
    </React.Fragment>
  );
};
