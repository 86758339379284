//@ts-check

import React, { Fragment } from 'react';
import { documentViewerInfoVar } from '../EmployeeAbsence.vars';
import { useReactiveVar } from '@apollo/client';
import { DocumentImageViewer } from '../../../../components/DocumentImageViewer.js/DocumentImageViewer';
import DocumentSigner from '../../../../components/DocumentSigner/DocumentSigner';

export const AbsenceViewer = () => {
  const documentViewer = useReactiveVar(documentViewerInfoVar);

  const openPdf =
    documentViewer.open &&
    documentViewer.documentType === 'PDF' &&
    documentViewer.employeeDocumentId;

  const openImage =
    documentViewer.open &&
    documentViewer.documentType === 'IMAGE' &&
    documentViewer.employeeDocumentId;

  return (
    <Fragment>
      {openPdf ? (
        <DocumentSigner
          open={documentViewer.open}
          docId={documentViewer.employeeDocumentId || undefined}
          userId={documentViewer.employeeId}
          requestType={'DOCUMENT'}
          viewOnly
          admin
          handleClose={() =>
            documentViewerInfoVar({
              ...documentViewer,
              open: false,
            })
          }
        />
      ) : null}

      {openImage ? (
        <DocumentImageViewer
          open={documentViewer.open}
          docId={documentViewer.employeeDocumentId}
          requestType="DOCUMENT"
          userId={documentViewer.employeeId}
          onClose={() =>
            documentViewerInfoVar({
              ...documentViewer,
              open: false,
            })
          }
        />
      ) : null}
    </Fragment>
  );
};
