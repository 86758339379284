// @ts-check
import React from 'react';

/**
 * @typedef {import('react').SVGProps<SVGSVGElement>} WorkTitleProps
 * @param {WorkTitleProps} props
 */
export const WorkTitle = (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_152_15889)">
        <path
          d="M19.9993 4C20.7353 4 21.3327 4.59733 21.3327 5.33333V8L27.9993 8C28.7353 8 29.3327 8.59733 29.3327 9.33333V26.6667C29.3327 27.4027 28.7353 28 27.9993 28H3.99935C3.26335 28 2.66602 27.4027 2.66602 26.6667L2.66602 9.33333C2.66602 8.59733 3.26335 8 3.99935 8H10.666V5.33333C10.666 4.59733 11.2633 4 11.9993 4H19.9993ZM10.666 10.6667H7.99935L7.99935 25.3333H10.666V10.6667ZM23.9993 10.6667H21.3327V25.3333H23.9993V10.6667ZM18.666 6.66667L13.3327 6.66667V8H18.666V6.66667Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_152_15889">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
