import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  Slide,
  useMediaQuery,
  IconButton,
  Stack,
} from '@mui/material';
import DOMPurify from 'dompurify';
import { Icon } from '../Icons/Icons';
import {
  DOWNLOAD_MY_FILE,
  GET_MY_HTML_FILE,
  MY_DASHBOARD,
  MY_DOCUMENTS,
  MY_DOCUMENTS_AND_RECEIPTS,
  MY_RECEIPTS,
  SET_DOCUMENT_SEEN,
  SIFE_SEND_DOCUMENTS,
  OLDEST_UNSIGNED_RECEIPT,
} from '../../containers/RHPod/EmployeePortal/gql';
import {
  DocumentActionButtons,
  DocumentSignButton,
} from './DocumentSignerButtons';
import '../../containers/RHPod/Documents/css/editor.css';
import '../../containers/RHPod/Documents/css/receipt.css';
import {
  DOWNLOAD_USER_FILE,
  GET_HTML_FILE,
} from '../../containers/RHPod/Documents/gql';
import { PdfViewer } from '../PdfViewer';
import { SifeIframe } from '../SifeIFrameButton/SifeIFrameButton';
import { deviceUtil } from '../../utils/deviceUtil';
import {
  Content,
  ErrorWrapper,
  HeaderWrapper,
  LoaderWrapper,
  TitleWrapperMobile,
  Wrapper,
} from './style';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';
import { getRandomDialogContent } from './utils';
import { DocumentSignerHeader } from './DocumentSignerHeader';
import { Typography } from '../../newComponents/Typography';
import {
  globalBackdropVar,
  globalSnackbarVar,
  sifeIframeVar,
} from '../../cache.reactiveVars';
import { Tooltip } from '../../newComponents/Tooltip';
import { Button } from '../../newComponents/Button';
import { SoraAngryIllustration } from '../Illustrations/Illustrations';
import { useCustomTheme } from '../../Hooks/useCustomTheme';

/**
 *
 * @param {object} props
 * @param {boolean} props.open
 * @param {boolean} [props.showCloseButton]
 * @param {string} [props.pdf]
 * @param {boolean} [props.signed]
 * @param {string} [props.title]
 * @param {string} [props.requestType]
 * @param {string} [props.company]
 * @param {string} [props.docId]
 * @param {boolean} [props.signable]
 * @param {Function} [props.onSuccess]
 * @param {string} [props.message]
 * @param {boolean} [props.admin]
 * @param {boolean} [props.creator]
 * @param {string | null} [props.userId]
 * @param {boolean} [props.isActive]
 * @param {Function} [props.handleClose]
 * @param {Function} [props.handleCloseConfirmDialog]
 * @param {boolean} [props.viewOnly]
 * @param {boolean} [props.isUserTurnToSign]
 * @param {boolean} [props.userHasSeen]
 * @param {boolean} [props.documentUpdated]
 * @param {string} [props.signLabel]
 * @param {boolean} [props.shouldBlockOnSign]
 * @param {() => void} [props.onClickModuleBlocked]
 *
 */
const DocumentSigner = ({
  open,
  showCloseButton = true,
  pdf,
  signed,
  title,
  requestType,
  company,
  docId,
  signable,
  onSuccess,
  message,
  admin,
  creator,
  userId,
  isActive = true,
  handleClose,
  handleCloseConfirmDialog,
  viewOnly = false,
  isUserTurnToSign = true,
  userHasSeen = false,
  documentUpdated,
  signLabel,
  shouldBlockOnSign = false,
  onClickModuleBlocked = () => {},
  ...props
}) => {
  let isPdf = Boolean(pdf);
  isPdf = true;
  const getQuery = () => {
    if (admin) {
      if (isPdf) return DOWNLOAD_USER_FILE;
      return GET_HTML_FILE;
    }
    if (isPdf) return DOWNLOAD_MY_FILE;
    return GET_MY_HTML_FILE;
  };

  const [sifeSendDocument] = useMutation(SIFE_SEND_DOCUMENTS);
  const [showCloseIframe, setShowCloseIframe] = useState(false);
  const { open: iframeOpen } = useReactiveVar(sifeIframeVar);
  const [isSigned, setIsSigned] = useState(signed || userHasSeen);
  const theme = useCustomTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = deviceUtil.isMobile() || sm;
  const [showDialog, setShowDialog] = useState(false);
  const [acceptLabel, setAcceptLabel] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogEmoji, setDialogEmoji] = useState('');

  const canBeSigned = !viewOnly
    ? !isSigned && isUserTurnToSign && isActive
    : false;

  const showSignButton = canBeSigned && !viewOnly;

  const [controlledError, setControlledError] = useState(null);

  const { data, loading, error, refetch } = useQuery(getQuery(), {
    fetchPolicy: 'network-only',
    variables: {
      type: requestType,
      searchId: docId,
      userId,
      asAttachment: false,
    },
  });

  const [setDocumentSeen] = useMutation(SET_DOCUMENT_SEEN, {
    refetchQueries: [
      { query: MY_DOCUMENTS_AND_RECEIPTS },
      { query: MY_DOCUMENTS },
      { query: MY_RECEIPTS },
      { query: MY_DASHBOARD },
      { query: OLDEST_UNSIGNED_RECEIPT },
    ],
  });
  const [downloadLink, setDownloadLink] = useState('');

  useEffect(() => {
    if (
      data?.downloadMyFile &&
      data?.downloadMyFile.__typename === 'DownloadUserFileException'
    ) {
      setControlledError(data?.downloadMyFile?.message);
    }
    if (data?.downloadUserFile || data?.downloadMyFile) {
      setDownloadLink(data?.downloadUserFile || data?.downloadMyFile.url);
    }
  }, [data]);

  const onCloseFunction = () => {
    handleClose && handleClose();
    if (iframeOpen) {
      sifeIframeVar({ open: false });
    }
  };

  const setDialogCopies = () => {
    let label = signable ? 'Firmar' : 'Marcar como visto';
    let type = requestType === 'RECEIPT' ? 'recibo' : 'documento';
    let content = getRandomDialogContent({ signable, type });
    setDialogTitle(content?.title);
    setDialogEmoji(content?.emoji);
    setAcceptLabel(label);
  };

  const onCloseWithAlert = () => {
    if (canBeSigned && !(requestType === 'DOCUMENT' && !isUserTurnToSign)) {
      setDialogCopies();
      setShowDialog(true);
      return;
    }
    onCloseFunction();
  };

  const handleCloseDialog = (accept) => {
    setShowDialog(false);
    onCloseFunction();
  };

  const handleCloseIframe = (fn) => {
    setShowDialog(false);
  };

  const handleSuccess = async (requestedDocuments) => {
    onSuccess && onSuccess(requestedDocuments);
    if (requestedDocuments?.length === 1) {
      await refetch();
      setIsSigned(true);
      sifeIframeVar({ open: false });
      setShowDialog(false);
    }
  };

  const handleDocumentSeen = async () => {
    try {
      globalBackdropVar({ open: true, text: 'Cargando...' });

      await setDocumentSeen({
        variables: {
          documentId: docId,
        },
      });
      globalSnackbarVar({
        show: true,
        message: 'Marcado como visto correctamente',
        severity: 'success',
      });
      onSuccess && onSuccess([{ id: docId }], false);
      onCloseFunction();
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleSendDocuments = async () => {
    try {
      globalBackdropVar({ open: true, text: 'Cargando...' });

      const res = await sifeSendDocument({
        variables: {
          input: [{ type: requestType, documentId: docId }],
        },
      });
      if (res.data?.sifeSendDocuments?.__typename === 'SentDocument') {
        sifeIframeVar({ open: true });
      } else {
        globalSnackbarVar({
          show: true,
          message: res.data?.sifeSendDocuments?.message,
          severity: 'error',
        });
      }
    } catch (e) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message:
          e.message ||
          'Hubo un problema al obtener tu documento, contacta a Sora.',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  if (error || controlledError) {
    return (
      <Dialog
        open={open}
        {...props}
        onClose={onCloseFunction}
        maxWidth="xs"
        fullWidth
        TransitionComponent={Transition}
      >
        <ErrorWrapper>
          <Typography variant="h5">No pudimos obtener tu archivo</Typography>
          {controlledError && (
            <Typography variant="body1" color="gray_70" textAlign={'center'}>
              {controlledError}
            </Typography>
          )}
          <SoraAngryIllustration height="300px" />
          <Button variant="outlined" onClick={onCloseFunction}>
            Cerrar
          </Button>
        </ErrorWrapper>
      </Dialog>
    );
  }

  if (loading) {
    return (
      <Dialog
        open={open}
        {...props}
        onClose={onCloseFunction}
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
        fullScreen={sm}
        sx={{
          '.MuiDialog-paper': {
            borderRadius: sm ? '0!important' : 2,
          },
        }}
      >
        <Wrapper>
          <LoaderWrapper>
            <CircularProgress size={100} />
          </LoaderWrapper>
        </Wrapper>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onCloseWithAlert}
      maxWidth="lg"
      fullWidth
      TransitionComponent={Transition}
      fullScreen={isMobile}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: isMobile ? '0!important' : 2,
        },
      }}
    >
      <ConfirmationDialog
        open={showDialog}
        onClose={handleCloseConfirmDialog || handleCloseDialog}
        title={dialogTitle}
        content="¿Estás seguro que quieres salir?"
        acceptLabel={acceptLabel}
        cancelLabel="Salir"
        emoji={dialogEmoji}
        closeOnBackdropClick={false}
        closeOnEscKey={false}
      >
        <DocumentSignButton
          requestedDocuments={[{ type: requestType, id: docId }]}
          handleDocumentSeen={handleDocumentSeen}
          handleCloseIframe={handleCloseIframe}
          onSuccess={handleSuccess}
          signable={signable}
          isSigned={isSigned}
          isActive={isActive}
          buttonWidth="100%"
          shouldBlockOnSign={shouldBlockOnSign}
          onClickModuleBlocked={onClickModuleBlocked}
        />
      </ConfirmationDialog>

      {iframeOpen && (
        <React.Fragment>
          {!showCloseIframe && isMobile && (
            <div
              style={{
                height: '50px',
                width: '100%',
                position: 'fixed',
                borderBottom: `1px solid ${theme.customPalette.grayColors.gray_40}`,
              }}
            >
              <IconButton
                size="large"
                onClick={() => sifeIframeVar({ open: false })}
              >
                <Icon
                  fill={theme.customPalette.grayColors.gray_70}
                  icon="close_line"
                  pointer
                />
              </IconButton>
            </div>
          )}
          <SifeIframe
            requestedDocuments={[{ type: requestType, id: docId }]}
            onSuccess={handleSuccess}
            onCameraCallback={(message) => {
              if (message === 'CAMERA_STARTED') setShowCloseIframe(true);
              if (message === 'CAMERA_STOPPED') setShowCloseIframe(false);
            }}
          />
        </React.Fragment>
      )}

      {!iframeOpen && (
        <React.Fragment>
          <HeaderWrapper>
            {isMobile && (
              <DocumentActionButtons
                handleClose={onCloseWithAlert}
                showCloseButton={showCloseButton}
                isSigned={isSigned}
                signable={signable}
              />
            )}

            <DocumentSignerHeader
              message={message}
              documentUpdated={documentUpdated}
              theme={theme}
            />

            {!isMobile && (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                m={1}
                sx={{
                  ':first-of-type > div': { marginRight: 'auto' },
                  ':last-child > div': { marginLeft: 'auto' },
                }}
              >
                <div style={{ flex: 1, textAlign: 'left' }}>
                  {showCloseButton && (
                    <IconButton size="large" onClick={onCloseWithAlert}>
                      <Icon
                        fill={theme.customPalette.grayColors.gray_70}
                        icon="close_line"
                        pointer
                      />
                    </IconButton>
                  )}
                </div>
                <div style={{ flex: 1, textAlign: 'center' }}>
                  {title && (
                    <React.Fragment>
                      {company && (
                        <Typography variant="overline" color="success">
                          {company}
                        </Typography>
                      )}
                      <Typography variant="h5">{title}</Typography>
                    </React.Fragment>
                  )}
                </div>
                <div style={{ flex: 1, textAlign: 'right' }}>
                  {/* {!admin && ( */}
                  <DocumentSignButton
                    requestedDocuments={[{ type: requestType, id: docId }]}
                    handleDocumentSeen={handleDocumentSeen}
                    onSuccess={handleSuccess}
                    signable={signable}
                    isSigned={isSigned}
                    viewOnly={viewOnly}
                    isActive={isActive}
                    signLabel={signLabel}
                    tooltipText={
                      requestType === 'DOCUMENT' && !isUserTurnToSign
                        ? 'Espera tu turno para firmar'
                        : ''
                    }
                    disabled={requestType === 'DOCUMENT' && !isUserTurnToSign}
                    shouldBlockOnSign={shouldBlockOnSign}
                    onClickModuleBlocked={onClickModuleBlocked}
                  />
                  {/* )} */}
                </div>
              </Stack>
            )}

            {isMobile && title && (
              <TitleWrapperMobile>
                {company && (
                  <Typography variant="overline" color="success">
                    {company}
                  </Typography>
                )}
                <Typography variant="body2">
                  <strong>{title}</strong>
                </Typography>
              </TitleWrapperMobile>
            )}
          </HeaderWrapper>

          <Wrapper pdf={isPdf} style={{ height: '100%' }}>
            {isPdf ? (
              <PdfViewer pdfUrl={downloadLink} />
            ) : (
              <Content
                className={requestType === 'RECEIPT' ? 'recibo' : 'document'}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    data?.[admin ? 'getFileAsHtml' : 'getMyFileAsHtml'],
                  ),
                }}
              />
            )}
          </Wrapper>

          {isMobile && showSignButton && (
            <DialogActions>
              {signable ? (
                <Tooltip
                  title={
                    requestType === 'DOCUMENT' && !isUserTurnToSign
                      ? 'Espera tu turno para firmar'
                      : ''
                  }
                  arrow
                >
                  <span>
                    <Button
                      fullWidth
                      onClick={handleSendDocuments}
                      disabled={requestType === 'DOCUMENT' && !isUserTurnToSign}
                      variant="contained"
                    >
                      {signLabel || 'Firmar'}
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleDocumentSeen}
                >
                  Confirmar de enterado
                </Button>
              )}
            </DialogActions>
          )}
        </React.Fragment>
      )}
    </Dialog>
  );
};
const Transition = React.forwardRef(
  /**
   * @param {import('@mui/material/transitions').TransitionProps & { children: React.ReactElement<any, any> }} props - The props passed to the Slide component.
   * @param {React.Ref<unknown>} ref - The ref passed to the Slide component for access to the DOM element.
   * @returns {React.ReactElement} A Slide component wrapped in React.forwardRef.
   */
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

export default DocumentSigner;
