//@ts-check
import React from 'react';
import { EmployeeAvatar } from '../../../../businessComponents/EmployeeAvatar/EmployeeAvatar';
import { Typography } from '../../../../newComponents/Typography';
import { es } from 'date-fns/locale';
import { format } from 'date-fns';
import { IconButton } from '../../../../newComponents/IconButton';
import { Chip } from '../../../../newComponents/Chip';
import { Label } from '../../../../newComponents/Label';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { AbsenceActions } from './AbsenceActions';
import { PopoverComments } from './PopoverComments';
import { Tooltip } from '../../../../newComponents/Tooltip';
import { documentViewerInfoVar } from '../EmployeeAbsence.vars';
import { getFileExtension } from '../../../../utils/utils';

const handleOpenImage = (row) => {
  if (row.employeeDocumentId && row.documentTitle) {
    const extension = getFileExtension(row.documentTitle);
    const documentType = extension === 'pdf' ? 'PDF' : 'IMAGE';
    const documentViewer = {
      open: true,
      documentType,
      employeeId: row.user._id,
      employeeDocumentId: row.employeeDocumentId,
    };
    return { success: true, documentViewer };
  }

  return { success: false };
};

/**
 * @param {object} params
 * @param {import('../../../../theme').CustomTheme} params.theme
 * @returns {import('../EmployeeAbsence.types').CustomGridColDef[]}
 */
export const ABSENCE_COLUMNS = ({ theme }) => [
  {
    field: 'actions',
    headerName: '',
    hideable: true,
    width: 60,
    sortable: false,
    align: 'center',
    renderHeader: () => {
      return <IconButton icon="more_2_line" color="primary" />;
    },
    renderCell: (params) => {
      const { row } = params;
      return <AbsenceActions absence={row} />;
    },
  },
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    type: 'string',
  },
  {
    field: 'typeOfAbsence',
    headerName: 'Tipo de ausencia',
    hideable: true,
    width: 250,
    sortable: false,
    renderCell: (params) => {
      const { row } = params;
      const document = handleOpenImage(row);
      if (document.success) {
        return (
          <Chip
            label={row?.reason || 'N/A'}
            variant="soft"
            color="secondary"
            onClick={() => {
              documentViewerInfoVar(document.documentViewer);
            }}
          />
        );
      }
      return (
        <Chip label={row?.reason || 'N/A'} variant="soft" color="secondary" />
      );
    },
  },
  {
    field: 'status',
    headerName: 'Estatus',
    hideable: true,
    width: 200,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const { row } = params;
      const statusMap = { PENDING: 'Pendiente', COMPLETED: 'Finalizado' };
      const name = row?.name;
      const status = statusMap[row?.name] ?? 'N/A';
      const statusColor = name === 'PENDING' ? 'warning' : 'default';
      const document = handleOpenImage(row);
      if (document.success) {
        return (
          <Label
            variant="soft"
            color={statusColor}
            label={status}
            startIcon="rest_time_line"
            //@ts-ignore
            onClick={() => {
              documentViewerInfoVar(document.documentViewer);
            }}
          />
        );
      }

      return (
        <Label
          variant="soft"
          color={statusColor}
          label={status}
          startIcon="rest_time_line"
        />
      );
    },
  },
  {
    field: 'employee',
    headerName: 'Empleado',
    hideable: true,
    width: 150,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const { row } = params;
      const fullName = `${row.user.names} ${row.user.lastNameP} ${row.user.lastNameM}`;
      return (
        <Tooltip title={fullName} placement="top">
          <span>
            <EmployeeAvatar
              profilePicture={row.user?.profilePicture}
              name={row.user.names}
              size="tiny"
            />
          </span>
        </Tooltip>
      );
    },
  },
  {
    field: 'startDate',
    headerName: 'Inicio',
    hideable: true,
    width: 250,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const { row } = params;
      const startDate = row?.start ?? new Date();
      const date = startDate
        ? format(new Date(startDate), 'dd MMM yyyy', {
            locale: es,
          })
        : 'N/A';
      const document = handleOpenImage(row);

      if (document.success) {
        return (
          <Typography
            variant="body2"
            onClick={() => {
              documentViewerInfoVar(document.documentViewer);
            }}
          >
            {date}
          </Typography>
        );
      }

      return <Typography variant="body2">{date}</Typography>;
    },
  },
  {
    field: 'endDate',
    headerName: 'Termino',
    hideable: true,
    width: 250,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const { row } = params;
      const endDate = row?.end ?? new Date();
      const date = endDate
        ? format(new Date(endDate), 'dd MMM yyyy', {
            locale: es,
          })
        : 'N/A';

      const document = handleOpenImage(row);

      if (document.success) {
        return (
          <Typography
            variant="body2"
            onClick={() => {
              documentViewerInfoVar(document.documentViewer);
            }}
          >
            {date}
          </Typography>
        );
      }

      return <Typography variant="body2">{date}</Typography>;
    },
  },
  {
    field: 'comments',
    headerName: 'Comentarios',
    hideable: true,
    width: 150,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const { row } = params;
      return <PopoverComments absence={row} />;
    },
  },
  {
    field: 'document',
    headerName: 'Comprobante',
    hideable: true,
    width: 300,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const { row } = params;
      const document = handleOpenImage(row);

      if (document.success) {
        return (
          <Chip
            label={row?.documentTitle}
            variant="soft"
            color="default"
            onClick={() => {
              documentViewerInfoVar(document.documentViewer);
            }}
          />
        );
      }

      return <Typography variant="body2">---</Typography>;
    },
  },
];
