import gql from 'graphql-tag';

export const GET_MY_COMPANIES = gql`
  query getMyCompanies {
    getMyCompanies {
      _id
      clientName
      name
      nameToInvoice
      alias
      rfc
      web
      isItAMexicanCompany
      isItASubsidiaryCompany
      industry
      plants
      employees
      logoUrl
      largeLogoUrl
      isAdmin
      billingData {
        period {
          start
          end
        }
      }
      workCenters {
        name
      }
      configs {
        showXMLSalary {
          active
        }
        showRegisteredSalary {
          active
        }
        blockBajaEmployees {
          active
        }
        blockAllUsers {
          active
        }
        blockNewReceipts {
          active
        }
        signatureType {
          type
        }
        hideCompanyFromSelector {
          active
        }
        hideCreditsAndSavings {
          active
        }
        skipGeolocationRequest {
          active
          __typename
        }
        requestVerificationAtSign {
          active
          __typename
        }
        employeeRecordsTags {
          _id
          category
          color
          description
          subcategories {
            _id
            description
            subcategory
          }
        }
        notifications {
          active
          periodicity
          weekDay
        }
        userInfoCompletion {
          active
          userInfoRequested {
            type
            optional
          }
        }
        absenceReasons
        showCompanyLogoInReceipt {
          active
          __typename
        }
      }
      notices {
        id
        title
        message
        imgUrl
        destinationUrl
        clicks {
          userId
        }
        companyId
      }
      workSpaceRoles {
        companyId
        isMaster
        isAdminRh
        isAdmin
        isEmployee
        permissions {
          code
          read
          create
          update
          delete
        }
      }
      modules {
        documents {
          active
        }
        rest {
          active
        }
      }
    }
  }
`;

export const GET_MY_PENDING_USER_DATA_CHANGE_REQUEST = gql`
  query getMyPendingDataChangeRequest {
    getMyPendingDataChangeRequest {
      _id
      employeeDocumentId
      companyName
      input {
        email
        rfc
        names
        lastNameP
        lastNameM
        curp
        phone
      }
    }
  }
`;

export const LOGIN = gql`
  mutation Login($input: SifeDataInput) {
    login(input: $input) {
      token
      privacyPolicy {
        accepted
        updatedAt
      }
      workSpaceRoles {
        companyId
        isMaster
        isAdminRh
        isAdmin
        isEmployee
        permissions {
          code
          read
          create
          update
          delete
        }
      }
      onboardingDocs {
        _id
        companyId
        title
        body
        constantType
        updated
      }
      highPriorityDocs {
        _id
        id
        documentId
        type
        companyId
        title
        body
        constantType
        updated
        canBeSigned
      }
      adminDocs {
        _id
        id
        documentId
        type
        companyId
        title
        body
        constantType
        updated
      }
      tourCompletion {
        tourName
        completitionDate
        completition
      }
      updateData {
        rfc
        curp
        names
        lastNameP
        lastNameM
      }
      updateLogins
    }
  }
`;

export const RENEW_TOKEN = gql`
  mutation RenewToken {
    renewToken {
      token
    }
  }
`;

export const CONTACT_METHODS = gql`
  query sifeContactMethods($input: SifeContactMethodsInput) {
    sifeContactMethods(input: $input) {
      __typename
      ... on SifeContactMethods {
        email
        phone
      }
    }
  }
`;

export const SEND_RECOVERY = gql`
  mutation sendRecovery($input: SifeSendRecoveryInput) {
    sifeSendRecovery(input: $input) {
      __typename
      ... on Success {
        message
      }
      ... on UserNotFound {
        message
      }
      ... on UserAlreadyActive {
        message
      }
      ... on UserIsCarta {
        message
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: SifeChangePasswordInput) {
    sifeChangePassword(input: $input) {
      __typename
      ... on UserNotFound {
        message
      }
      ... on PasswordChanged {
        message
      }
      ... on InvalidToken {
        message
        token
      }
      ... on TokenExpired {
        message
      }
      ... on NewPasswordMustBeDifferent {
        message
      }
      ... on UserIncompleteRegistration {
        message
      }
    }
  }
`;

export const CHANGE_OTP = gql`
  mutation SifeChangeOtp($newPassword: String!) {
    sifeChangeOtp(newPassword: $newPassword) {
      ... on UserNotFound {
        message
      }
      ... on PasswordChanged {
        message
      }
      ... on OtpNotFound {
        message
      }
      ... on NewPasswordMustBeDifferent {
        message
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation SifeChangePassword($input: SifeChangePasswordInput) {
    sifeChangePassword(input: $input) {
      __typename
      ... on UserNotFound {
        message
      }
      ... on PasswordChanged {
        message
      }
      ... on InvalidToken {
        message
        token
      }
      ... on RegistrationNotAllowed {
        message
      }
      ... on UserAlreadyExists {
        message
      }
    }
  }
`;

export const SIFE_SEND_VERIFICATION_CODE = gql`
  mutation SifeSendVerificationCode($input: SifeSendVerificationCodeInput) {
    sifeSendVerificationCode(input: $input) {
      __typename
      ... on Success {
        message
      }
      ... on ValidationError {
        field
        validationMessage
      }
      ... on UserNotFound {
        message
      }
      ... on UserAlreadyExists {
        message
      }
    }
  }
`;

export const SIFE_RESEND_VERIFICATION_LINK = gql`
  mutation SifeResendVerificationLink(
    $input: SifeResendVerificationLinkInput!
  ) {
    sifeResendVerificationLink(input: $input) {
      __typename
      ... on Success {
        message
      }
      ... on NotEmployeeError {
        message
      }
      ... on ValidationError {
        field
        validationMessage
      }
      ... on UserNotFound {
        message
      }
      ... on UserAlreadyExists {
        message
      }
    }
  }
`;

export const SIFE_VERIFY_CONTACT_METHOD = gql`
  mutation sifeVerifyContactMethod($input: SifeVerifyContactMethodInput) {
    sifeVerifyContactMethod(input: $input) {
      __typename
      ... on Success {
        message
      }
      ... on ValidationError {
        field
        validationMessage
      }
      ... on UserNotFound {
        message
      }
      ... on TokenNotValid {
        message
      }
      ... on TokenExpired {
        message
      }
      ... on OTPNotValid {
        message
      }
    }
  }
`;

export const UPDATE_COMPANY_ACTIVE_MODULES = gql`
  mutation updateCompanyActiveModules(
    $input: updateCompanyActiveModulesInput!
  ) {
    updateCompanyActiveModules(input: $input) {
      __typename
      ... on Success {
        message
      }
      ... on ErrorUpdateCompanyActiveModules {
        message
      }
    }
  }
`;
