// @ts-check
import React, { forwardRef, memo } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  Autocomplete,
  createFilterOptions,
  InputAdornment,
  Paper,
  Stack,
} from '@mui/material';
import { EmployeesFinderList } from './EmployeesFinderList';
import { EmployeesFinderMenu } from './EmployeesFinderMenu';
import { CircularProgress } from '../../newComponents/Progress';
import { TextInput } from '../../newComponents/TextInput';
import { Typography } from '../../newComponents/Typography';
import { employeesFinderVar } from './EmployeesFinder.vars';
import {
  FIND_BY_EMPLOYEES,
  FIND_BY_WORKCENTERS,
  FIND_BY_WORKTITLES,
  FIND_BY_GROUPS,
} from './EmployeesFinder.constants';
import { Icon } from '../../components/Icons/Icons';
import { Chip } from '../../newComponents/Chip';
import { Avatar } from '../../newComponents/Avatar';

const MAP_FIND_BY = {
  EMPLOYEES: 'Seleccionar empleado',
  WORKCENTERS: 'Seleccionar centro de trabajo',
  WORKTITLES: 'Seleccionar puesto de trabajo',
  GROUPS: 'Seleccionar grupo',
};

const CustomPaper = (props) => {
  return <Paper sx={{ borderRadius: '12px' }} {...props} />;
};

const NoOptionsText = ({
  data,
  loadingText,
  loading,
  noOptionsText,
  search,
}) => {
  return (
    <Stack>
      <Typography variant="subtitle2" color="text.secondary">
        {loadingText}
      </Typography>

      {!data?.options?.length && !loading ? (
        <>
          <Typography variant="subtitle2">
            No existe: "{search.trim()}"
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {noOptionsText}
          </Typography>
        </>
      ) : null}

      {loading ? (
        <Typography variant="subtitle2" color="text.secondary">
          {noOptionsText}
        </Typography>
      ) : null}
    </Stack>
  );
};
const defaultFilterOptions = createFilterOptions();

export const EmployeesFinderAutocomplete = memo(
  /** @param {import('./EmployeesFinder.types').EmployeesFinderAutocompleteProps} props */
  (props) => {
    const { open, loading, data, findBySelected } =
      useReactiveVar(employeesFinderVar);

    const {
      disabled,
      search,
      findBy,
      selectedRows,
      onInputChange,
      onSelectRow,
      onLoadMore,
      isRowSelected,
      onPaste,
      handleSingleItem,
      unSelectRow,
      hideMenu,
      allowDeselect,
    } = props;

    let loadingText = 'Buscando...';
    let placeholder = '';
    let noOptionsText = '';
    if (findBySelected === FIND_BY_EMPLOYEES) {
      loadingText = 'Buscando empleados...';
      placeholder = 'Buscar empleados';
      noOptionsText = 'Para crear un empleado debes ir al expediente laboral.';
    }
    if (findBySelected === FIND_BY_WORKCENTERS) {
      loadingText = 'Buscando centros de trabajo...';
      placeholder = 'Buscar centros de trabajo';
      noOptionsText =
        'Para crear un centro de trabajo debes ir al expediente laboral.';
    }
    if (findBySelected === FIND_BY_WORKTITLES) {
      loadingText = 'Buscando puestos de trabajo...';
      placeholder = 'Buscar puestos de trabajo';
      noOptionsText =
        'Para crear un puesto de trabajo debes ir a preferencias de empresa.';
    }
    if (findBySelected === FIND_BY_GROUPS) {
      loadingText = 'Buscando grupos...';
      placeholder = 'Buscar grupos';
      noOptionsText = 'Para crear un grupo debes ir a preferencias de empresa.';
    }

    placeholder = handleSingleItem ? MAP_FIND_BY[findBy] : placeholder;
    const label =
      handleSingleItem && selectedRows?.length ? MAP_FIND_BY[findBy] : '';

    /** @type { React.ForwardRefExoticComponent } */
    const EmployeesFinderListsAdditionalProps = forwardRef((props, ref) => {
      return (
        <EmployeesFinderList
          allowDeselect={allowDeselect}
          {...props}
          ref={ref}
        />
      );
    });

    return (
      <Stack
        alignItems="center"
        direction={{ xs: 'column', sm: 'row' }}
        width="100%"
      >
        {hideMenu !== true && (
          <EmployeesFinderMenu findBy={findBy} disabled={disabled} />
        )}
        <Stack flex="1" width="100%">
          <Autocomplete
            multiple
            value={handleSingleItem ? selectedRows : []}
            onPaste={onPaste}
            disabled={disabled}
            clearOnBlur={false}
            open={open}
            disableClearable
            onOpen={() => {
              employeesFinderVar({ ...employeesFinderVar(), open: true });
            }}
            onClose={() => {
              employeesFinderVar({ ...employeesFinderVar(), open: false });
            }}
            onInputChange={(event, value) => onInputChange(value)}
            loading={loading}
            PaperComponent={CustomPaper}
            ListboxComponent={EmployeesFinderListsAdditionalProps}
            filterOptions={(options, state) => {
              const searchValue = state.inputValue.trim();
              if (!searchValue.includes(',')) {
                return defaultFilterOptions(options, state);
              } else {
                //returns all options cause the options were filtered in the backend
                return options;
              }
            }}
            ListboxProps={{
              // @ts-ignore
              selectedRows,
              onLoadMore,
              onSelectRow,
              isRowSelected,
            }}
            options={data.options}
            getOptionLabel={(option) => `${JSON.stringify(option)}`}
            isOptionEqualToValue={(option, value) => {
              //@ts-ignore
              if (value && value?.userId) return option?._id === value?.userId;
              //@ts-ignore
              return option?._id === value?._id;
            }}
            loadingText={
              <Typography variant="subtitle2" color="text.secondary">
                {loadingText}
              </Typography>
            }
            renderTags={(values) => {
              return (
                <RenderTags
                  values={values}
                  unSelectRow={unSelectRow}
                  disabled={disabled}
                />
              );
            }}
            noOptionsText={
              <NoOptionsText
                loadingText={loadingText}
                data={data}
                loading={loading}
                noOptionsText={noOptionsText}
                search={search}
              />
            }
            popupIcon={<Icon icon="arrow_down_s_line" height="20px" />}
            renderInput={(params) => {
              const hidePlaceholder = handleSingleItem && selectedRows?.length;

              return (
                <TextInput
                  {...params}
                  variant="outlined"
                  label={label}
                  placeholder={hidePlaceholder ? '' : placeholder}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    ...inputPropsTextField({ selectedRows, handleSingleItem }),
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      py: hideMenu ? '10px' : '5px',
                      ...(hideMenu !== true && {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: { xs: '8px', sm: 0 },
                        borderTopRightRadius: { xs: 0, sm: '8px' },
                      }),
                    },
                  }}
                />
              );
            }}
          />
        </Stack>
      </Stack>
    );
  },
);

const inputPropsTextField = ({ selectedRows = [], handleSingleItem }) => {
  if (handleSingleItem && selectedRows?.length) return {};
  if (handleSingleItem) {
    return {
      startAdornment: (
        <InputAdornment position="start">
          <Icon icon="search_line" />
        </InputAdornment>
      ),
      height: '80px',
    };
  }

  return {};
};

const RenderTags = (props) => {
  const { values, unSelectRow, disabled } = props;
  return values?.map((option, index) => {
    return (
      <Chip
        key={index}
        avatar={<Avatar alt={option?.fullName} src={option?.profilePicture} />}
        label={option?.fullName}
        variant="outlined"
        size="small"
        onDelete={() => unSelectRow && unSelectRow(option)}
        disabled={disabled}
      />
    );
  });
};
