//@ts-check
import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Icon } from '../../../components/Icons/Icons';
import { Box, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ErrorBoundary } from '@sentry/react';
import { useTheme } from '@mui/material/styles';
import { DefaultErrorComponent } from '../../../components/ErrorBoundary/ErrorBoundary';
import { Dialog } from '../../../components/Dialogs/Dialog';
import { Typography } from '../../../newComponents/Typography';
import { useScreenSize } from '../../../Hooks';
import {
  resetSendDocumentsOptionsDialog,
  sendDocumentsOptionsDialogVar,
} from './SendDocumentsOptionsDialog.vars';
import { useReactiveVar } from '@apollo/client';

export function SendDocumentsOptionsDialog() {
  const sendDocumentsOptionsDialog = useReactiveVar(
    sendDocumentsOptionsDialogVar,
  );
  const { open, onUploadDocument, onCreateDocument } =
    sendDocumentsOptionsDialog;

  const handleClose = () => {
    resetSendDocumentsOptionsDialog();
  };
  const handleUploadDocumentsDialog = () => {
    onUploadDocument();
    resetSendDocumentsOptionsDialog();
  };

  const handleCreateDocumentDialog = () => {
    onCreateDocument();
    resetSendDocumentsOptionsDialog();
  };

  /**@type {import('../../../theme').CustomTheme} */
  const theme = useTheme();
  const { isMobile } = useScreenSize();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={isMobile}
      maxWidth="sm"
      showCloseButton={false}
    >
      <Stack flexDirection="column" alignItems="flex-start" m={4} mb={8}>
        <ErrorBoundary fallback={<DefaultErrorComponent />}>
          <Stack
            style={{ width: '100%' }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h6">Enviar documento</Typography>
            <IconButton aria-label="send" onClick={handleClose}>
              <Icon icon="close_line" height="25px" />
            </IconButton>
          </Stack>
          <Box sx={{ ...buttonWraperStyle }}>
            <AddDocumentButton
              icon="file_edit_fill"
              fill={theme.customPalette.status.success_green_70}
              title="Crear documento"
              content="Usa el editor de texto para crear un documento personalizado."
              onClick={() => {
                handleCreateDocumentDialog();
              }}
            />
            <AddDocumentButton
              icon="file_upload_fill"
              fill={theme.customPalette.primary.purple}
              title="Enviar documentos"
              content="Carga y envía un documento en formato JPEG, PNG o PDF."
              onClick={() => {
                handleUploadDocumentsDialog();
              }}
            />
          </Box>
        </ErrorBoundary>
      </Stack>
    </Dialog>
  );
}

const Wrapper = styled(Paper)(() => ({
  margin: '16px 0px',
  width: '100%',
  padding: '24px 20px 24px 10px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  ':hover': {
    boxShadow: '0px 20px 40px -4px rgba(145, 158, 171, 0.16)',
  },
}));

const IconItem = styled(Box)(() => ({
  padding: '10px',
  marginRight: '10px',
}));

const buttonWraperStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const AddDocumentButton = ({ icon, fill, title, content, onClick }) => {
  return (
    <Wrapper onClick={onClick} variant="outlined">
      <IconItem>
        <Icon height="30px" fill={fill || 'black'} icon={icon} />
      </IconItem>
      <Box>
        <Typography variant="subtitle2" style={{ display: 'block' }}>
          {title}
        </Typography>
        <Typography variant="caption">{content}</Typography>
      </Box>
    </Wrapper>
  );
};
