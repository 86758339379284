// @ts-check
import React from 'react';
import { TableContainer, Table, TableBody, Box } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList } from 'react-window';
import { useLazyLoadCheckboxSelection } from '../../Hooks/useLazyLoadCheckboxSelection';
import { Checkbox } from '../Checkbox';
import { HEADER_HEIGHT } from './ScrollableTable.constants';
import { ScrollableTableHead } from './ScrollableTableHead';
import { ScrollableTableRow } from './ScrollableTableRow';

/** @param {import('./ScrollableTable.types').ScrollableTableProps} props */
export const ScrollableTable = (props) => {
  const {
    columns,
    rows,
    rowHeight,
    totalRows,
    onLoadMore = () => {},
    getRowId = (row) => row.id,
    checkboxSelection = true,
    toolbarComponentCallback = () => null,
    ToolbarComponent = null,
    showToolbarWhenCheckboxSelected = false,
    onCheckboxSelectionChange = () => {},
    checkboxSelectionState: externalCheckboxSelectionState,
    renderMainCheckbox = (params) => <Checkbox {...params} />,
    renderRowCheckbox = (params) => <Checkbox {...params} />,
  } = props;

  const {
    checkboxSelectionState,
    onSelectCheckbox,
    isCheckboxSelected,
    onSelectAll,
    mainCheckboxChecked,
    mainCheckboxIndeterminate,
  } = useLazyLoadCheckboxSelection({
    totalItems: totalRows,
    externalState: externalCheckboxSelectionState,
    onChange: onCheckboxSelectionChange,
  });

  // validate if getRowId returns a valid string
  if (rows.length > 0 && typeof getRowId(rows[0]) !== 'string') {
    console.error(
      `every row must have an id property, use getRowId prop to provide one if it's different from 'id'`,
    );
  }

  return (
    <Box width="100%" height="100%">
      <AutoSizer>
        {({ height, width }) => (
          <TableContainer sx={{ width, height, overflowY: 'hidden' }}>
            <Table component="div">
              <ScrollableTableHead
                columns={columns}
                verticalScroll={totalRows * rowHeight > height - HEADER_HEIGHT}
                checkboxSelection={checkboxSelection}
                mainCheckboxChecked={mainCheckboxChecked}
                mainCheckboxIndeterminate={mainCheckboxIndeterminate}
                showToolbarWhenCheckboxSelected={
                  showToolbarWhenCheckboxSelected
                }
                ToolbarComponent={
                  ToolbarComponent ||
                  toolbarComponentCallback(checkboxSelectionState)
                }
                onSelectAll={onSelectAll}
                renderMainCheckbox={renderMainCheckbox}
              />
              <TableBody component="div">
                <InfiniteLoader
                  isItemLoaded={(index) => index < rows.length}
                  itemCount={totalRows}
                  threshold={0}
                  loadMoreItems={onLoadMore}
                >
                  {({ onItemsRendered, ref: refLoader }) => (
                    <FixedSizeList
                      ref={refLoader}
                      height={height - HEADER_HEIGHT}
                      width="100%"
                      itemCount={totalRows}
                      itemSize={rowHeight}
                      itemData={{
                        columns,
                        rows,
                        checkboxSelection,
                        getRowId,
                        onSelect: onSelectCheckbox,
                        isSelected: isCheckboxSelected,
                        renderRowCheckbox,
                      }}
                      onItemsRendered={onItemsRendered}
                    >
                      {ScrollableTableRow}
                    </FixedSizeList>
                  )}
                </InfiniteLoader>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </AutoSizer>
    </Box>
  );
};
