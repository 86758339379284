import { useMutation } from '@apollo/client';
import { Box, InputAdornment, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../components/Buttons/Buttons';
import { Icon } from '../../../components/Icons/Icons';
import { SoraHappy } from '../../../components/Illustrations/Illustrations';
import TextInput from '../../../components/Inputs/TextInput';
import { Imagotipo } from '../../../components/Logos/Imagotipo/Imagotipo';
import { BodyLarge, H4, H5 } from '../../../components/Typographies/Typography';
import { SpacedWrapper } from '../../../components/Utils/Utils';
import logout from '../../../utils/logout';
import { RESET_PASSWORD } from '../gql';
import { useTheme } from '@mui/material/styles';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../cache.reactiveVars';

const PasswordReset = () => {
  const theme = useTheme();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordChanged, setPasswordChanged] = useState(false);

  const history = useHistory();

  const { rfc, token } = useParams();

  const [resetPassword] = useMutation(RESET_PASSWORD);

  const handleTooltip = () => {
    if (password < 8)
      return 'La contraseña debe contener al menos 8 caracteres';
    if (password !== passwordConfirm)
      return 'Ambas contraseñas deben coincidir';
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      globalBackdropVar({ open: true, text: 'Cambiando contraseña...' });
      const res = await resetPassword({
        variables: {
          input: {
            token,
            rfc,
            type: 'SIFE_RESET_PASSWORD',
            newPassword: password,
          },
        },
      });

      if (res.data?.sifeChangePassword?.__typename === 'PasswordChanged') {
        setPasswordChanged(true);
        logout();
      } else {
        globalSnackbarVar({
          show: true,
          message: res.data?.sifeChangePassword?.message,
          severity: 'error',
        });
      }
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  if (passwordChanged)
    return (
      <SpacedWrapper column height="100vh" center justifyContent="center">
        <SoraHappy height="300px" />
        <H5>Contraseña actualizada exitosamente</H5>
        <SecondaryButton onClick={() => history.push('/login')}>
          Volver
        </SecondaryButton>
      </SpacedWrapper>
    );

  return (
    <Box
      height="100vh"
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
      as="form"
      onSubmit={handleSubmit}
    >
      <SpacedWrapper spacing="28px" column center>
        <Imagotipo height="91px" onClick={() => history.push('/login')} />
        <SpacedWrapper
          column
          spacing="8px"
          center
          style={{ textAlign: 'center' }}
        >
          <H4>Cambia tu contraseña</H4>
          <BodyLarge color={theme.customPalette.primary.blue}>
            Debe contener al menos 8 caracteres
          </BodyLarge>
        </SpacedWrapper>
        <SpacedWrapper column width="355px" spacing="16px">
          <TextInput
            label="Ingresa tu nueva contraseña"
            name="password"
            variant="outlined"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon
                    icon="lock_line"
                    fill={theme.customPalette.grayColors.gray_40}
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextInput
            label="Verifica tu nueva contraseña"
            name="passwordConfirm"
            value={passwordConfirm}
            variant="outlined"
            type="password"
            onChange={(e) => setPasswordConfirm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon
                    icon="lock_line"
                    fill={theme.customPalette.grayColors.gray_40}
                  />
                </InputAdornment>
              ),
            }}
          />
          <SpacedWrapper column spacing="8px">
            <Tooltip title={handleTooltip()}>
              <span>
                <PrimaryButton
                  fullWidth
                  disabled={password.length < 8 || password !== passwordConfirm}
                  type="submit"
                >
                  Continuar
                </PrimaryButton>
              </span>
            </Tooltip>
            <SecondaryButton onClick={() => history.push('/login')}>
              Cancelar
            </SecondaryButton>
          </SpacedWrapper>
        </SpacedWrapper>
      </SpacedWrapper>
    </Box>
  );
};

export default PasswordReset;
