import { Box, FormControlLabel, Grid, Radio, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { withErrorBoundary } from '@sentry/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { PrimaryButton } from '../../../../../components/Buttons/Buttons';
import { ControlledRadio } from '../../../../../components/ControlledInputs/ControlledRadio/ControlledRadio';
import { ControlledSwitch } from '../../../../../components/ControlledInputs/ControlledSwitch/ControlledSwitch';
import { DefaultErrorComponent } from '../../../../../components/ErrorBoundary/ErrorBoundary';
import {
  BodyMedium,
  Caption,
  Typographies,
} from '../../../../../components/Typographies/Typography';
import { signingPermissions } from '../constants';

export const SigningPermissions = withErrorBoundary(
  ({ companyData, updateSigningPermissions }) => {
    const theme = useTheme();

    const {
      handleSubmit: configHandleSubmit,
      control: configControl,
      reset,
      formState: { isDirty },
    } = useForm({
      mode: 'onChange',
      shouldFocusError: true,
      defaultValues: {
        requestGeolocation: false,
        requestVerificationAtSign: false,
        signatureType: 'SIFE_CRYPTOGRAPHIC',
        showCompanyLogoInReceipt: false,
      },
    });

    useEffect(() => {
      reset({
        requestGeolocation:
          !companyData.configs?.skipGeolocationRequest?.active,
        requestVerificationAtSign:
          companyData.configs?.requestVerificationAtSign?.active || false,
        signatureType:
          companyData.configs?.signatureType?.type || 'SIFE_CRYPTOGRAPHIC',
        showCompanyLogoInReceipt:
          companyData.configs?.showCompanyLogoInReceipt?.active || false,
      });
    }, [companyData, reset]);

    return (
      <form onSubmit={configHandleSubmit(updateSigningPermissions)}>
        <Grid container spacing={2} flexDirection="column">
          {signingPermissions.map((config) => (
            <Stack key={config.name} p={1}>
              {config.name === 'signatureType' ? (
                <Grid item xs={12}>
                  {config.section && (
                    <Stack pb={1} pt={3}>
                      <BodyMedium
                        style={{
                          color: theme.customPalette.status.inactive_gray,
                          fontWeight: 600,
                        }}
                      >
                        {config.section}
                      </BodyMedium>
                    </Stack>
                  )}
                  <Box mt="10px">
                    <Caption color={theme.customPalette.status.inactive_gray}>
                      {config.description}
                    </Caption>
                  </Box>
                  <ControlledRadio control={configControl} name="signatureType">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="left"
                      gap={{ lg: 50 }}
                    >
                      <FormControlLabel
                        value="SIFE_CRYPTOGRAPHIC"
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: theme.customPalette.primary.blue,
                              },
                            }}
                          />
                        }
                        label="Firma electrónica criptográfica"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            ...Typographies.Body2,
                          },
                        }}
                      />
                      <FormControlLabel
                        value="SAT_CRYPTOGRAPHIC"
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: theme.customPalette.primary.blue,
                              },
                            }}
                          />
                        }
                        label="e.firma (SAT)"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            ...Typographies.Body2,
                          },
                        }}
                      />
                    </Box>
                  </ControlledRadio>
                </Grid>
              ) : (
                <Grid item xs={12} key={config.name}>
                  <ControlledSwitch
                    control={configControl}
                    name={config.name}
                    description={config.description}
                    renderText={(description) => (
                      <Caption
                        color={theme.customPalette.status.inactive_gray}
                        style={{ textAlign: 'start' }}
                      >
                        {description}
                      </Caption>
                    )}
                    muiProps={{
                      switchProps: {},
                      labelProps: {
                        label: config.label,
                        labelPlacement: 'end',
                        sx: {
                          '& .MuiFormControlLabel-label': {
                            ...Typographies.Body2,
                            color:
                              theme.customPalette.textColor.text_black_light,
                          },
                        },
                      },
                    }}
                  />
                </Grid>
              )}
            </Stack>
          ))}

          <Grid item xs={12} textAlign="right">
            <PrimaryButton type="submit" disabled={!isDirty}>
              Actualizar
            </PrimaryButton>
          </Grid>
        </Grid>
      </form>
    );
  },
  {
    fallback: <DefaultErrorComponent />,
  },
);
