// @ts-check
import { publicSans } from '../components/Typographies/Fonts';

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiChip']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme, iconNoBackground }) => ({
    ...fontStyles,
    '& .MuiChip-deleteIcon': {
      opacity: '.56',
      color: theme.newPalette.common.white,
    },
    '& .MuiChip-deleteIconSmall': {
      height: '18px',
    },
    '& .MuiChip-iconSmall': {
      height: '18px',
    },
    '& .MuiChip-icon': {
      padding: 3,
      ...(iconNoBackground && { padding: 1 }),
      borderRadius: '50%',
    },
    '& .MuiChip-avatar': {
      ...avatarFontStyles,
    },
    '&.MuiChip-sizeSmall': {
      height: '26px',
    },

    '&.MuiChip-filled': {
      '&.MuiChip-colorDefault': {
        color: theme.newPalette.text.primary,
        backgroundColor: theme.newPalette.grey.transparent16,
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.grey.transparent48,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.common.white }),
          color: theme.newPalette.text.primary,
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.grey.transparent32,
          color: theme.newPalette.text.secondary,
        },
        '& .MuiChip-deleteIcon': {
          opacity: 0.24,
          color: theme.newPalette.text.primary,
        },
      },
      '&.MuiChip-colorPrimary': {
        color: theme.newPalette.common.white,
        backgroundColor: theme.newPalette.primary.main,
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.primary.dark,
          color: theme.newPalette.primary.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.common.white }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.primary.dark,
          color: theme.newPalette.primary.lighter,
        },
      },
      '&.MuiChip-colorSecondary': {
        color: theme.newPalette.common.white,
        backgroundColor: theme.newPalette.secondary.main,
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.secondary.dark,
          color: theme.newPalette.secondary.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.common.white }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.secondary.dark,
          color: theme.newPalette.secondary.lighter,
        },
      },
      '&.MuiChip-colorInfo': {
        color: theme.newPalette.common.white,
        backgroundColor: theme.newPalette.info.main,
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.info.dark,
          color: theme.newPalette.info.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.common.white }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.info.dark,
          color: theme.newPalette.info.lighter,
        },
      },
      '&.MuiChip-colorSuccess': {
        color: theme.newPalette.common.white,
        backgroundColor: theme.newPalette.success.main,
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.success.dark,
          color: theme.newPalette.success.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.common.white }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.success.dark,
          color: theme.newPalette.success.lighter,
        },
      },
      '&.MuiChip-colorWarning': {
        color: theme.newPalette.common.black,
        backgroundColor: theme.newPalette.warning.main,
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.warning.dark,
          color: theme.newPalette.warning.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.text.primary }),
        },
        '& .MuiChip-deleteIcon': {
          color: theme.newPalette.text.primary,
          opacity: 0.56,
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.warning.dark,
          color: theme.newPalette.warning.lighter,
        },
      },
      '&.MuiChip-colorError': {
        color: theme.newPalette.common.white,
        backgroundColor: theme.newPalette.error.main,
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.error.dark,
          color: theme.newPalette.error.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.common.white }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.error.dark,
          color: theme.newPalette.error.lighter,
        },
      },
    },
    '&.MuiChip-outlined': {
      '&.MuiChip-colorDefault': {
        color: theme.newPalette.text.primary,
        borderColor: theme.newPalette.grey.transparent32,
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.grey.transparent48,
          color: theme.newPalette.text.primary,
          ...(iconNoBackground && { backgroundColor: '' }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.grey.transparent32,
          color: theme.newPalette.text.secondary,
        },
        '& .MuiChip-deleteIcon': {
          opacity: 0.24,
          color: theme.newPalette.text.primary,
        },
      },
      '&.MuiChip-colorPrimary': {
        color: theme.newPalette.primary.main,
        borderColor: theme.newPalette.primary.main,
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.primary.dark,
          color: theme.newPalette.primary.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.primary.main }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.primary.dark,
          color: theme.newPalette.primary.lighter,
        },
        '& .MuiChip-deleteIcon': {
          opacity: 0.7,
          color: theme.newPalette.primary.main,
        },
      },
      '&.MuiChip-colorSecondary': {
        color: theme.newPalette.secondary.main,
        borderColor: theme.newPalette.secondary.main,
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.secondary.dark,
          color: theme.newPalette.secondary.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.secondary.main }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.secondary.dark,
          color: theme.newPalette.secondary.lighter,
        },
        '& .MuiChip-deleteIcon': {
          opacity: 0.7,
          color: theme.newPalette.secondary.main,
        },
      },
      '&.MuiChip-colorInfo': {
        color: theme.newPalette.info.main,
        borderColor: theme.newPalette.info.main,
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.info.dark,
          color: theme.newPalette.info.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.info.main }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.info.dark,
          color: theme.newPalette.info.lighter,
        },
        '& .MuiChip-deleteIcon': {
          opacity: 0.7,
          color: theme.newPalette.info.main,
        },
      },
      '&.MuiChip-colorSuccess': {
        color: theme.newPalette.success.main,
        borderColor: theme.newPalette.success.main,
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.success.dark,
          color: theme.newPalette.success.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.success.main }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.success.dark,
          color: theme.newPalette.success.lighter,
        },
        '& .MuiChip-deleteIcon': {
          opacity: 0.7,
          color: theme.newPalette.success.main,
        },
      },
      '&.MuiChip-colorWarning': {
        color: theme.newPalette.warning.main,
        borderColor: theme.newPalette.warning.main,
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.warning.dark,
          color: theme.newPalette.warning.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.warning.main }),
        },
        '& .MuiChip-deleteIcon': {
          color: theme.newPalette.warning.main,
          opacity: 0.7,
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.warning.dark,
          color: theme.newPalette.warning.lighter,
        },
      },
      '&.MuiChip-colorError': {
        color: theme.newPalette.error.main,
        borderColor: theme.newPalette.error.main,
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.error.dark,
          color: theme.newPalette.error.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.error.main }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.error.dark,
          color: theme.newPalette.error.lighter,
        },
        '& .MuiChip-deleteIcon': {
          opacity: 0.7,
          color: theme.newPalette.error.main,
        },
      },
    },
    '&.MuiChip-soft': {
      '&.MuiChip-colorDefault': {
        color: theme.newPalette.text.primary,
        backgroundColor: theme.newPalette.grey.transparent16,
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.grey.transparent48,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.common.white }),
          color: theme.newPalette.text.primary,
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.grey.transparent32,
          color: theme.newPalette.text.secondary,
        },
        '& .MuiChip-deleteIcon': {
          opacity: 0.24,
          color: theme.newPalette.text.primary,
        },
      },
      '&.MuiChip-colorPrimary': {
        color: theme.newPalette.primary.dark,
        backgroundColor: theme.newPalette.primary.transparent16,
        '& .MuiChip-deleteIcon': {
          color: theme.newPalette.primary.main,
          opacity: 0.48,
        },
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.primary.dark,
          color: theme.newPalette.primary.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.primary.dark }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.primary.dark,
          color: theme.newPalette.primary.lighter,
        },
      },
      '&.MuiChip-colorSecondary': {
        color: theme.newPalette.secondary.dark,
        backgroundColor: theme.newPalette.secondary.transparent16,
        '& .MuiChip-deleteIcon': {
          color: theme.newPalette.secondary.main,
          opacity: 0.48,
        },
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.secondary.dark,
          color: theme.newPalette.secondary.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.secondary.dark }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.secondary.dark,
          color: theme.newPalette.secondary.lighter,
        },
      },
      '&.MuiChip-colorInfo': {
        color: theme.newPalette.info.dark,
        backgroundColor: theme.newPalette.info.transparent16,
        '& .MuiChip-deleteIcon': {
          color: theme.newPalette.info.main,
          opacity: 0.48,
        },
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.info.dark,
          color: theme.newPalette.info.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.info.dark }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.info.dark,
          color: theme.newPalette.info.lighter,
        },
      },
      '&.MuiChip-colorSuccess': {
        color: theme.newPalette.success.dark,
        backgroundColor: theme.newPalette.success.transparent16,
        '& .MuiChip-deleteIcon': {
          color: theme.newPalette.success.main,
          opacity: 0.48,
        },
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.success.dark,
          color: theme.newPalette.success.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.success.dark }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.success.dark,
          color: theme.newPalette.success.lighter,
        },
      },
      '&.MuiChip-colorWarning': {
        color: theme.newPalette.warning.dark,
        backgroundColor: theme.newPalette.warning.transparent16,
        '& .MuiChip-deleteIcon': {
          color: theme.newPalette.warning.main,
          opacity: 0.48,
        },
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.warning.dark,
          color: theme.newPalette.warning.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.warning.dark }),
        },

        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.warning.dark,
          color: theme.newPalette.warning.lighter,
        },
      },
      '&.MuiChip-colorError': {
        color: theme.newPalette.error.dark,
        backgroundColor: theme.newPalette.error.transparent16,
        '& .MuiChip-deleteIcon': {
          color: theme.newPalette.error.main,
          opacity: 0.48,
        },
        '& .MuiChip-icon': {
          backgroundColor: theme.newPalette.error.dark,
          color: theme.newPalette.error.lighter,
          ...(iconNoBackground && { backgroundColor: '' }),
          ...(iconNoBackground && { color: theme.newPalette.error.dark }),
        },
        '& .MuiChip-avatar': {
          backgroundColor: theme.newPalette.error.dark,
          color: theme.newPalette.error.lighter,
        },
      },
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const chipStyles = {
  styleOverrides,
};

const fontStyles = {
  /* Components/Chip/Label */
  fontFamily: publicSans,
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
};
const avatarFontStyles = {
  fontFamily: publicSans,
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
};
