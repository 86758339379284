import { gql } from '@apollo/client';

export const COMPLETE_USER_INFO_REQUESTED = gql`
  mutation CompleteUserInfoRequested($input: CompleteUserInfoRequestedInput) {
    completeUserInfoRequested(input: $input) {
      _id
      names
      lastNameP
      lastNameM
      birthdate
      gender
      maritalStatus
      address {
        street
        internalNumber
        externalNumber
        colony
        zipCode
        city
        state
        country
      }
    }
  }
`;
