// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import { useScreenSize } from '../../../../Hooks';
import {
  ControlledCountryInput,
  ControlledZipCodeInput,
  ControlledStateInput,
  ControlledCityInput,
  ControlledColonyInput,
  ControlledStreetInput,
  ControlledInternalNumberInput,
  ControlledExternalNumberInput,
} from '../../../../businessComponents/ControlledInputs';

/** @param {import('./UserInfoRequestedDialog.types').UserAddressFormProps} props */
export const UserAddressForm = (props) => {
  const { form, onSubmit } = props;
  const { isMobile, md } = useScreenSize();
  const { watch, setValue } = form;

  const stackDirection = isMobile ? 'column' : 'row';
  const stackPadding = isMobile ? 2 : 3;
  const stackWidth = isMobile || md ? '100%' : '480px';

  const resetCountryDependentFields = () => {
    setValue('zipCode', '');
    setValue('state', '');
    setValue('city', '');
    setValue('colony', '');
  };

  const country = watch('country');
  const isZipCodeEmpty = !watch('zipCode');

  return (
    <>
      <Stack p={stackPadding} width={stackWidth}>
        <Typography variant={isMobile ? 'h5' : 'h6'}>
          Completa tu dirección:
        </Typography>
      </Stack>
      <Stack p={stackPadding} gap={3} width={stackWidth}>
        <Stack direction={stackDirection} flex={1} gap={2}>
          <Stack flex={1}>
            <ControlledCountryInput
              control={form.control}
              textFieldProps={{
                label: 'País',
              }}
              onChange={() => {
                resetCountryDependentFields();
              }}
            />
          </Stack>
        </Stack>
        <Stack direction={stackDirection} flex={1} gap={2}>
          <Stack flex={1}>
            <ControlledZipCodeInput
              control={form.control}
              country={country}
              rules={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{
                label: 'Código postal',
                placeholder: '',
                disabled: !Boolean(country),
              }}
              onReceiveLocationData={(data) => {
                setValue('city', data.city);
                setValue('state', data.state);
                setValue('colony', data.colony);
              }}
              onClearZipCode={() => {
                resetCountryDependentFields();
              }}
            />
          </Stack>
          <Stack flex={1}>
            <ControlledStateInput
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{
                label: 'Estado',
                required: true,
                disabled: isZipCodeEmpty,
              }}
            />
          </Stack>
        </Stack>
        <Stack direction={stackDirection} flex={1} gap={2}>
          <Stack flex={1}>
            <ControlledCityInput
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{
                label: 'Ciudad',
                required: true,
                disabled: isZipCodeEmpty,
              }}
            />
          </Stack>
          <Stack flex={1}>
            <ControlledColonyInput
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{
                label: 'Colonia',
                required: true,
                disabled: isZipCodeEmpty,
              }}
            />
          </Stack>
        </Stack>
        <Stack>
          <ControlledStreetInput
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Campo requerido',
              },
            }}
            textFieldProps={{
              label: 'Calle',
              required: true,
            }}
          />
        </Stack>
        <Stack direction={stackDirection} flex={1} gap={2}>
          <Stack flex={1}>
            <ControlledExternalNumberInput
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{
                label: 'Número exterior',
                required: true,
              }}
            />
          </Stack>
          <Stack flex={1}>
            <ControlledInternalNumberInput
              control={form.control}
              textFieldProps={{ label: 'Número interior' }}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack p={stackPadding} width={stackWidth}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          disabled={!form.formState.isValid}
          onClick={form.handleSubmit(onSubmit)}
        >
          Continuar
        </Button>
      </Stack>
    </>
  );
};
