// @ts-check
import { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { TemplatePermissionDenied } from './TemplatePermissionDenied';
import { TemplateCreationDialog } from './TemplateCreationDialog';
import { TemplateUpdateDialog } from './TemplateUpdateDialog';
import { TemplateTable } from './TemplateTable';
import { TemplateDeleteConfirmationDialog } from './TemplateDeleteConfirmationDialog';
import { usePermissionChecker } from '../../../../Hooks';
import { refetchFunctionsVar } from '../../../../cache.reactiveVars';
import { useBlockableActions } from '../../../../components/ModuleBlockableComponent/useBlockableActions';
import { SORA_MODULES } from '../../../../components/ModuleBlockableComponent/ModuleBlockable.constants';
import { useGetTemplate } from './TemplateHooks';
import type { TemplateRow, TemplateToUpdate } from './Template.types';
import type { UpdateType } from './TemplateUpdateDialog/TemplateUpdateDialog.types';

export const CreateTemplateContainer = () => {
  const [templateSelected, setTemplateSelected] = useState(null);
  const [currentTemplate, setCurrentTemplate] =
    useState<TemplateToUpdate | null>(null);
  const [updateType, setUpdateType] = useState<UpdateType>('SETTINGS');
  const [
    showTemplateDeleteConfirmationDialog,
    setShowTemplateDeleteConfirmationDialog,
  ] = useState(false);
  const [showTemplateCreationDialog, setShowTemplateCreationDialog] =
    useState(false);
  const [showTemplateUpdateDialog, setShowTemplateUpdateDialog] =
    useState(false);
  const { refetchAllTemplates } = useReactiveVar(refetchFunctionsVar);
  const { handleGetTemplate } = useGetTemplate();
  const userHasPermissionToView = usePermissionChecker({
    permission: 'templates',
    action: 'read',
  });

  const {
    onClick: documentsModuleIsBlockedOnClick,
    isModuleAvailable: isDocumentsModuleAvailable,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);

  const handleCreateAction = () => {
    if (!isDocumentsModuleAvailable) {
      return documentsModuleIsBlockedOnClick();
    }
    setShowTemplateCreationDialog(true);
  };

  const handleDeleteAction = (row: TemplateRow) => {
    if (!isDocumentsModuleAvailable) {
      return documentsModuleIsBlockedOnClick();
    }
    setTemplateSelected(row);
    setShowTemplateDeleteConfirmationDialog(true);
  };

  const handleEditAction = async (row: TemplateRow) => {
    if (!isDocumentsModuleAvailable) {
      return documentsModuleIsBlockedOnClick();
    }

    await handleGetTemplate({
      templateId: row._id,
      onSuccess: (info) => {
        setShowTemplateUpdateDialog(true);
        setCurrentTemplate({
          _id: info._id,
          title: info.title,
          description: info.description,
          color: info.color.primary,
          secondaryColor: info.color.secondary,
          category: info.categories.category,
          subcategory: info.categories.subcategory,
          myEditor: info.htmlContent,
        });
      },
    });
  };

  if (!userHasPermissionToView) {
    return <TemplatePermissionDenied />;
  }

  return (
    <>
      <TemplateTable
        handleEditSettingsAction={(template) => {
          handleEditAction(template);
          setUpdateType('SETTINGS');
        }}
        handleEditContentAction={(template) => {
          handleEditAction(template);
          setUpdateType('CONTENT');
        }}
        handleDeleteAction={handleDeleteAction}
        handleCreateAction={handleCreateAction}
      />
      <TemplateCreationDialog
        showDialog={showTemplateCreationDialog}
        closeDialog={() => setShowTemplateCreationDialog(false)}
      />
      <TemplateUpdateDialog
        showDialog={showTemplateUpdateDialog}
        closeDialog={() => setShowTemplateUpdateDialog(false)}
        currentTemplate={currentTemplate}
        updateType={updateType}
      />
      <TemplateDeleteConfirmationDialog
        showDialog={showTemplateDeleteConfirmationDialog}
        closeDialog={() => setShowTemplateDeleteConfirmationDialog(false)}
        templateId={templateSelected?._id}
        onSuccess={() => {
          setTemplateSelected(null);
          refetchAllTemplates && refetchAllTemplates();
        }}
      />
    </>
  );
};
