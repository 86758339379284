//@ts-check
import React, { useState } from 'react';
import { DialogContent, Stack } from '@mui/material';
import { Menu } from '../../../../newComponents/Menu';
import { IconButton } from '../../../../newComponents/IconButton';
import { MenuItem } from '../../../../newComponents/MenuItem';
import { ListItemText } from '../../../../newComponents/ListItemText';
import { ListItemIcon } from '../../../../newComponents/ListItemIcon';
import { Icon } from '../../../../components/Icons/Icons';
import { Typography } from '../../../../newComponents/Typography';
import {
  deleteEmployeeAbsenceDialogVar,
  editEmployeeAbsenceDialogVar,
} from '../EmployeeAbsence.vars';
import { menuActionsItems } from '../EmployeeAbsence.constants';
import { Dialog } from '../../../../components/Dialogs/Dialog';
import { menuActionsStyles } from '../EmployeeAbsence.styles';
import { useScreenSize } from '../../../../Hooks';
import { globalSnackbarVar } from '../../../../cache.reactiveVars';

const BottomMenu = ({ open, onClose, handleAction, absence }) => {
  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onCloseXButton={false}
      showCloseButton={false}
      // TransitionComponent={Transition}
      sx={{
        ...menuActionsStyles,
      }}
    >
      <DialogContent sx={{ p: 0 }}>
        {menuActionsItems.map((action, i) =>
          absence.name === 'COMPLETED' && action.action !== 'DELETE' ? null : (
            <MenuItem key={i} onClick={() => handleAction(action.action)}>
              <ListItemIcon>
                <Icon icon={action.icon} color={action.color} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2" color={action.color}>
                  {action.label}
                </Typography>
              </ListItemText>
            </MenuItem>
          ),
        )}
      </DialogContent>
    </Dialog>
  );
};

export const AbsenceActions = ({ absence }) => {
  const { isMobile } = useScreenSize();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (anchorEl) return setAnchorEl(null);
    setAnchorEl(event.currentTarget);
  };

  const handleAction = (action) => {
    if (!action) return setAnchorEl(null);
    if (!absence) {
      globalSnackbarVar({
        show: true,
        message: 'No se ha seleccionado ninguna ausencia',
        severity: 'error',
        duration: 4000,
      });
      return setAnchorEl(null);
    }
    if (action === 'UPDATE') {
      editEmployeeAbsenceDialogVar({
        open: true,
        absenceData: absence,
        autoCompleteEmployee: true,
      });
      setAnchorEl(null);
      return;
    }

    if (action === 'DELETE') {
      deleteEmployeeAbsenceDialogVar({
        open: true,
        employeeId: absence.user._id,
        absenceIds: [absence.absenceId],
        isMultiple: false,
      });
      setAnchorEl(null);
    }
  };

  if (isMobile) {
    return (
      <Stack>
        <IconButton icon="more_2_line" onClick={handleClick} />
        <BottomMenu
          open={open}
          onClose={handleClick}
          handleAction={handleAction}
          absence={absence}
        />
      </Stack>
    );
  }

  return (
    <Stack>
      <IconButton id="basic-menu" icon="more_2_line" onClick={handleClick} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClick}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuActionsItems.map((action, i) =>
          absence.name === 'COMPLETED' && action.action !== 'DELETE' ? null : (
            <MenuItem key={i} onClick={() => handleAction(action.action)}>
              <ListItemIcon>
                <Icon icon={action.icon} color={action.color} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2" color={action.color}>
                  {action.label}
                </Typography>
              </ListItemText>
            </MenuItem>
          ),
        )}
      </Menu>
    </Stack>
  );
};
