// @ts-check
import React, { memo } from 'react';
import { ListItem, MenuItem, Skeleton, Stack } from '@mui/material';
import { EmployeeListItem } from '../EmployeeListItem';
import { WorkTitleListItem } from '../WorkTitleListItem';
import { GroupListItem } from '../GroupListItem';
import { WorkCenterListItem } from '../WorkCenterListItem';
import {
  WORK_CENTER_ROW,
  WORK_TITLE_ROW,
  GROUP_ROW,
} from './EmployeesFinder.constants';

export const EmployeesFinderListItem = memo(
  /** @param {import('./EmployeesFinder.types').EmployeesFinderListItemsProps} props */
  (props) => {
    const { row, style, onSelectRow, isRowSelected, allowDeselect } = props;

    if (!row) {
      return (
        <ListItem style={style} sx={{ padding: 1 }}>
          <Stack direction="row" alignItems="center" gap={2} width="100%">
            <Skeleton variant="circular" width={40} height={40} />
            <Stack width="70%">
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            </Stack>
          </Stack>
        </ListItem>
      );
    }

    const isSelected = isRowSelected(row);
    const handleSelectRow = () => {
      if (allowDeselect && isSelected) {
        onSelectRow(row);
      } else if (!isSelected) {
        onSelectRow(row);
      }
    };

    if (row.type === WORK_TITLE_ROW) {
      return (
        <MenuItem
          key={row.name}
          style={style}
          sx={{ padding: 1 }}
          onClick={handleSelectRow}
        >
          <WorkTitleListItem
            color={row.color}
            name={row.name}
            secondaryText="Puesto de trabajo"
            AvatarProps={{ selected: isSelected }}
          />
        </MenuItem>
      );
    }

    if (row.type === WORK_CENTER_ROW) {
      return (
        <MenuItem
          key={row._id}
          style={style}
          sx={{ padding: 1 }}
          onClick={handleSelectRow}
        >
          <WorkCenterListItem
            name={row.name}
            secondaryText="Centro de trabajo"
            AvatarProps={{ selected: isSelected }}
            color={row.color}
          />
        </MenuItem>
      );
    }

    if (row.type === GROUP_ROW) {
      return (
        <MenuItem
          key={row.name}
          style={style}
          sx={{ padding: 1 }}
          onClick={handleSelectRow}
        >
          <GroupListItem
            color={row.color}
            name={row.name}
            secondaryText="Grupo"
            AvatarProps={{ selected: isSelected }}
          />
        </MenuItem>
      );
    }

    return (
      <MenuItem
        key={row._id}
        style={style}
        sx={{ padding: 1 }}
        onClick={handleSelectRow}
      >
        <EmployeeListItem
          name={row.fullName}
          secondaryText={`${row.workCenterName} - ${row.workTitle}`}
          EmployeeAvatarProps={{
            profilePicture: row.profilePicture,
            selected: isSelected,
          }}
        />
      </MenuItem>
    );
  },
);
