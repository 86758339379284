import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DOWNLOAD_USER_FILE } from '../../containers/RHPod/Documents/gql';
import { SecondaryButton } from '../Buttons/Buttons';
import { Dialog } from '../Dialogs/Dialog';
import { Icon } from '../Icons/Icons';
import { ErrorWrapper, HeaderWrapper, LoaderWrapper, Wrapper } from './style';
import { useCustomTheme } from '../../Hooks/useCustomTheme';
import { Typography } from '../../newComponents/Typography';
import { SoraAngryIllustration } from '../Illustrations/Illustrations';

export const DocumentActionButtons = ({ handleClose, documentDownload }) => {
  const theme = useCustomTheme();

  return (
    <Stack
      spacing={1}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{
        padding: '5px',
        top: 0,
        width: '100%',
        backgroundColor: 'white',
      }}
    >
      <div>
        <IconButton size="large" onClick={handleClose}>
          <Icon
            fill={theme.customPalette.grayColors.gray_70}
            icon="close_line"
            pointer
          />
        </IconButton>
      </div>

      <div>
        <IconButton size="large" onClick={documentDownload}>
          <Icon
            fill={theme.customPalette.primary.blue}
            height="24px"
            icon="download_2_line"
          />
        </IconButton>
      </div>
    </Stack>
  );
};
export const DocumentImageViewer = ({
  open,
  docId,
  requestType,
  userId,
  onClose,
}) => {
  const [downloadLink, setDownloadLink] = useState('');
  const { data, loading, error } = useQuery(DOWNLOAD_USER_FILE, {
    fetchPolicy: 'network-only',
    variables: {
      type: requestType,
      searchId: docId,
      userId,
      asAttachment: false,
    },
  });

  const [imgLoading, setImgLoading] = useState(true);

  const [downloadFile] = useLazyQuery(DOWNLOAD_USER_FILE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      window.open(res.downloadUserFile, '_self');
    },
  });

  const documentDownload = async () => {
    await downloadFile({
      variables: {
        type: 'DOCUMENT',
        userId,
        searchId: docId,
        asAttachment: true,
      },
    });
  };

  useEffect(() => {
    if (data) {
      setDownloadLink(data?.downloadUserFile);
    }
    if (error) {
      setImgLoading(false);
    }
  }, [data, error]);

  if (error) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
        showCloseButton={false}
      >
        <Box component="div" sx={ErrorWrapper}>
          <Typography variant="h5">No pudimos obtener tu archivo</Typography>
          <SoraAngryIllustration
            height="300px"
            alt="No encontramos tu archivo"
          />
          <SecondaryButton onClick={onClose}>Cerrar</SecondaryButton>
        </Box>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      showCloseButton={false}
      onClose={onClose}
    >
      <Box sx={{ minHeight: '50vh' }}>
        <Box component="div" sx={HeaderWrapper}>
          <DocumentActionButtons
            documentDownload={documentDownload}
            handleClose={onClose}
          />
        </Box>
        <Box component="div" sx={Wrapper}>
          {(loading || imgLoading) && (
            <div style={LoaderWrapper}>
              <CircularProgress size={100} />
            </div>
          )}
          <img
            style={{
              width: '80%',
              borderRadius: '10px',
              margin: '10px',
              display: imgLoading ? 'none' : 'block',
            }}
            alt=""
            src={downloadLink}
            onLoad={() => setImgLoading(false)}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
