import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { Button } from '../../../../../newComponents/Button';
import { Icon } from '../../../../../components/Icons/Icons';

type CompanyPreviewEmailProps = {
  clientName: string;
  largeLogo: string;
  style?: React.CSSProperties;
};

export const CompanyPreviewEmail = React.forwardRef<
  HTMLDivElement,
  CompanyPreviewEmailProps
>((props, ref) => {
  const { clientName, largeLogo, style } = props;
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      ref={ref}
      style={style}
      container
      justifyContent="center"
      sx={{
        border: '2px dashed lightgray',
        maxWidth: '512px',
        margin: '0 auto',
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          background: theme.customPalette.grayColors.gray_10,
          color: theme.customPalette.primary.black,
          padding: '1rem',
        }}
      >
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={9}>
            <Typography variant="h4">
              {clientName} te invita a unirte como usuario en Sora.
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Icon
              icon="notification_4_line"
              width="50px"
              height="50px"
              color="grey"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid p={1} container spacing={2} justifyContent="space-between">
          {largeLogo && (
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <img
                width={sm ? 105 : 210}
                height={sm ? 26.25 : 52.5}
                alt="logo"
                src={largeLogo}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body1">Hola, Carolina</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>{clientName}</strong> te ha invitado a unirte como usuario
              en la plataforma de Sora.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              En Sora podrás firmar de manera electrónica tus recibos de nómina
              y otros documentos. Además de consultarlos y descargarlos cuando
              los necesites y desde tu celular.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant="body1">
              ¡Activa tu cuenta para disfrutar de este beneficio!
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={9}
        sx={{ textAlign: 'center', marginTop: '3rem', marginBottom: '1rem' }}
      >
        <Button variant="contained">Activa tu cuenta</Button>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
});
