// @ts-check
import { useState } from 'react';
import {
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../../newComponents/Button';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../components/Icons/Icons';
import { DocumentPreviewDialog } from '../../DocumentCreator/DocumentPreviewDialog';
import { getHmtlFromCreator } from '../../DocumentCreator/helper';
import { TemplateCreationSettingsForm } from './TemplateCreationSettingsForm';
import { TemplateCreationEditorForm } from './TemplateCreationEditorForm';
import { refetchFunctionsVar } from '../../../../../cache.reactiveVars';
import { useScreenSize } from '../../../../../Hooks';
import { useTemplateCreation } from '../TemplateHooks';
import { TemplateUnsavedChangesDialog } from '../TemplateUnsavedChangesDialog';
import type { TemplateCreationDialogProps } from './TemplateCreationDialog.types';
import {
  DEFAULT_TEMPLATE_EDITOR,
  DEFAULT_TEMPLATE_SETTINGS,
} from './TemplateCreationDialog.constants';

export const TemplateCreationDialog = (props: TemplateCreationDialogProps) => {
  const theme = useTheme();
  const [step, setStep] = useState(0);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [showConfirmCloseDialog, setShowConfirmCloseDialog] = useState(false);
  const { showDialog, closeDialog } = props;
  const { isMobile } = useScreenSize();
  const { handleCreateTemplate } = useTemplateCreation();
  const { refetchAllTemplates } = useReactiveVar(refetchFunctionsVar);

  const templateSettingsForm = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_TEMPLATE_SETTINGS,
  });

  const templateEditorForm = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_TEMPLATE_EDITOR,
    resolver: (data) => {
      if (!data.myEditor) {
        const message = 'Campo requerido';
        const errors = { myEditor: { type: 'required', message } };
        return { values: data.myEditor, errors: errors };
      }
      return { values: data.myEditor, errors: {} };
    },
  });

  const templateSettingsFormIsValid = templateSettingsForm.formState.isValid;
  const templateEditorFormIsValid = templateEditorForm.formState.isValid;
  const isTemplateSettingsFormDirty = templateSettingsForm.formState.isDirty;
  const isTemplateEditorFormDirty = templateEditorForm.formState.isDirty;

  const handleCloseDialog = (force?: boolean) => {
    if (force || (!isTemplateSettingsFormDirty && !isTemplateEditorFormDirty)) {
      templateSettingsForm.reset(DEFAULT_TEMPLATE_SETTINGS);
      templateEditorForm.reset(DEFAULT_TEMPLATE_EDITOR);
      setShowConfirmCloseDialog(false);
      setStep(0);
      closeDialog();
      return;
    }

    if (isTemplateSettingsFormDirty || isTemplateEditorFormDirty) {
      setShowConfirmCloseDialog(true);
    }
  };

  const handleTemplateCreation = () => {
    handleCreateTemplate({
      data: {
        ...templateSettingsForm.getValues(),
        ...templateEditorForm.getValues(),
      },
      onSuccess: () => {
        handleCloseDialog(true);
        refetchAllTemplates && refetchAllTemplates();
      },
    });
  };

  const createHtml = () => {
    const content = templateEditorForm.getValues().myEditor;
    return getHmtlFromCreator({ data: {}, content, preview: true });
  };

  return (
    <>
      <Dialog
        slideMode
        fullWidth
        open={showDialog}
        maxWidth={step === 0 ? 'xs' : 'xl'}
        onClose={() => handleCloseDialog()}
        showCloseButton={false}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: 3,
          }}
          component="div"
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Icon
              fill={theme.customPalette.status.inactive_gray}
              icon="close_line"
              height="26px"
              onClick={() => handleCloseDialog()}
              style={{ cursor: 'pointer' }}
            />
            Crear plantilla
          </Stack>
          {step === 1 && (
            <Stack>
              {isMobile ? (
                <IconButton
                  icon="eye_line"
                  color="default"
                  onClick={() => setShowPreviewDialog(true)}
                />
              ) : (
                <Button
                  variant="outlined"
                  color="default"
                  startIcon={<Icon icon="eye_line" />}
                  onClick={() => setShowPreviewDialog(true)}
                >
                  Previsualizar
                </Button>
              )}
            </Stack>
          )}
        </DialogTitle>
        <DialogContent dividers>
          {step === 0 && (
            <TemplateCreationSettingsForm form={templateSettingsForm} />
          )}
          {step === 1 && (
            <TemplateCreationEditorForm form={templateEditorForm} />
          )}
        </DialogContent>
        <DialogActions sx={{ p: '24px' }}>
          {step === 0 && (
            <Button
              variant="contained"
              size="large"
              disabled={!templateSettingsFormIsValid}
              onClick={templateSettingsForm.handleSubmit(() => {
                setStep(1);
              })}
            >
              Siguiente
            </Button>
          )}
          {step === 1 && (
            <>
              <Button
                variant="outlined"
                size="large"
                color="default"
                onClick={() => setStep(0)}
              >
                Volver
              </Button>
              <Button
                variant="contained"
                size="large"
                disabled={!templateEditorFormIsValid}
                onClick={templateEditorForm.handleSubmit(
                  handleTemplateCreation,
                )}
              >
                Finalizar
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <TemplateUnsavedChangesDialog
        showDialog={showConfirmCloseDialog}
        closeDialog={() => setShowConfirmCloseDialog(false)}
        onConfirm={() => handleCloseDialog(true)}
      />
      <DocumentPreviewDialog
        open={showPreviewDialog}
        setOpen={setShowPreviewDialog}
        createHtml={createHtml}
      />
    </>
  );
};
