import { Tooltip } from '@mui/material';
import React from 'react';
import { Icon } from '../Icons/Icons';
import { fontKarla } from '../Typographies/Fonts';
import { BodyMedium } from '../Typographies/Typography';
import { Message } from './style';

export const DocumentSignerHeader = ({ message, documentUpdated, theme }) => {
  return (
    <>
      {message && documentUpdated ? (
        <Message messageColor={theme.customPalette.purpleColors.purple_20}>
          <Tooltip
            placement="top"
            title="Ésta es una versión actualizada de un documento que firmaste anteriormente. Se genera debido a que se corrigieron algunos datos y por ello es necesario que lo vuelvas a firmar."
            componentsProps={{
              tooltip: {
                sx: {
                  background: 'white',
                  boxShadow:
                    '0 8px 16px 0 rgba(65, 67, 91, 0.16), 0 4px 8px 0 rgba(65, 67, 91, 0.16)',
                  color: 'black',
                  padding: 1.5,
                  fontFamily: fontKarla,
                  fontSize: 14,
                  fontWeight: '400',
                  maxWidth: 450,
                  lineHeight: 1.5,
                  textAlign: 'center',
                },
              },
            }}
          >
            <span>
              <Icon
                icon={'information_line'}
                fill={theme.customPalette.primary.purple}
                style={{ paddingRight: 5 }}
              />
            </span>
          </Tooltip>

          <BodyMedium style={{ color: theme.customPalette.primary.purple }}>
            {message}
          </BodyMedium>
        </Message>
      ) : (
        message && (
          <Message>
            <BodyMedium>{message}</BodyMedium>
          </Message>
        )
      )}
    </>
  );
};
