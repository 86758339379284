// @ts-check
import React, { useState, useEffect } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { DialogContent, DialogTitle, Stack } from '@mui/material';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Dialog } from '../../../../newComponents/Dialog';
import { IconButton } from '../../../../newComponents/IconButton';
import { SoriHappy } from '../../../../components/Illustrations/Illustrations';
import { COMPLETE_USER_INFO_REQUESTED } from './UserInfoRequestedDialog.gql';
import { useScreenSize } from '../../../../Hooks';
import { UserPersonalInfoForm } from './UserPersonalInfoForm';
import { UserAddressForm } from './UserAddressForm';
import { STEP_TYPES } from '../UserOnboarding.constants';
import { userOnboardingVar } from '../UserOnboarding.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import {
  SoriSection,
  InfoSection,
  CloseButtonWrapper,
} from '../UserOnboarding.styles';

const dataRequired = {
  BIRTHDATE: false,
  GENDER: false,
  MARITAL_STATUS: false,
  ADDRESS: false,
};

const addressData = {
  country: 'MEX',
  zipCode: '',
  state: '',
  city: '',
  colony: '',
  street: '',
  externalNumber: '',
  internalNumber: '',
};

/** @param {import('./UserInfoRequestedDialog.types').UserInfoRequestedDialogProps} props */
export const UserInfoRequestedDialog = (props) => {
  const { onUserInfoCompleted } = props;
  const [completeUserInfoRequested] = useMutation(COMPLETE_USER_INFO_REQUESTED);
  const [step, setStep] = useState(0);
  const { isMobile } = useScreenSize();
  const { onboardingStep, onboardingWorkflow, companyRequirements } =
    useReactiveVar(userOnboardingVar);

  const userPersonalInfoForm = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const userAddressForm = useForm({
    mode: 'onChange',
    defaultValues: addressData,
  });

  const handleCompleteUserPersonalInfo = async () => {
    if (dataRequired.ADDRESS) return setStep(1);

    const personalInfoData = userPersonalInfoForm.getValues();

    try {
      globalBackdropVar({ open: true, text: 'Agregando información...' });
      await completeUserInfoRequested({
        variables: { input: { ...personalInfoData } },
      });
      globalSnackbarVar({
        show: true,
        message: 'Información agregada correctamente',
        severity: 'success',
      });
      onUserInfoCompleted();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al agregar la información',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleCompleteUserAddressInfo = async () => {
    const personalInfoData = userPersonalInfoForm.getValues();
    const addressData = userAddressForm.getValues();

    try {
      globalBackdropVar({ open: true, text: 'Agregando información...' });
      await completeUserInfoRequested({
        variables: { input: { ...personalInfoData, address: addressData } },
      });
      globalSnackbarVar({
        show: true,
        message: 'Información agregada correctamente',
        severity: 'success',
      });
      onUserInfoCompleted();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al agregar la información',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  useEffect(() => {
    if (companyRequirements) {
      const { infoRequested } = companyRequirements;
      const defaultValues = infoRequested.reduce((acc, requirement) => {
        if (requirement.type === 'BIRTHDATE') {
          acc['birthdate'] = null;
          dataRequired.BIRTHDATE = true;
        }
        if (requirement.type === 'GENDER') {
          acc['gender'] = '';
          dataRequired.GENDER = true;
        }
        if (requirement.type === 'MARITAL_STATUS') {
          acc['maritalStatus'] = '';
          dataRequired.MARITAL_STATUS = true;
        }
        if (requirement.type === 'ADDRESS') {
          dataRequired.ADDRESS = true;
        }
        return acc;
      }, {});

      userPersonalInfoForm.reset(defaultValues);

      const somePersonalDataIsRequired = infoRequested.some(
        (info) => info.type !== 'ADDRESS',
      );
      const addressIsRequired = infoRequested.some(
        (info) => info.type === 'ADDRESS',
      );

      if (!somePersonalDataIsRequired && addressIsRequired) setStep(1);
    }
  }, [companyRequirements, userPersonalInfoForm]);

  const handleClose = () => props.onClose();

  if (!companyRequirements) return null;

  return (
    <Dialog
      open={
        onboardingWorkflow[onboardingStep] === STEP_TYPES.ASK_FOR_INFO_REQUESTED
      }
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: isMobile ? '0px' : '16px' } }}
    >
      {isMobile && (
        <DialogTitle textAlign="right">
          <IconButton icon="close_line" onClick={handleClose} />
        </DialogTitle>
      )}
      <DialogContent sx={{ padding: 0, height: '650px', position: 'relative' }}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <Stack direction="row">
              {!isMobile && (
                <SoriSection>
                  <SoriHappy width="300px" />
                </SoriSection>
              )}
              {!isMobile && (
                <CloseButtonWrapper>
                  <IconButton icon="close_line" onClick={handleClose} />
                </CloseButtonWrapper>
              )}
              <InfoSection height={height} pt={isMobile ? 0 : 4}>
                {step === 0 && (
                  <UserPersonalInfoForm
                    form={userPersonalInfoForm}
                    dataRequired={dataRequired}
                    onSubmit={handleCompleteUserPersonalInfo}
                  />
                )}
                {step === 1 && (
                  <UserAddressForm
                    form={userAddressForm}
                    onSubmit={handleCompleteUserAddressInfo}
                  />
                )}
              </InfoSection>
            </Stack>
          )}
        </AutoSizer>
      </DialogContent>
    </Dialog>
  );
};
