// @ts-check
import React from 'react';
import { ConfirmDialog } from '../../newComponents/Dialog';
import { Typography } from '../../newComponents/Typography';
import logout from '../../utils/logout';

/** @param {import('./LogoutDialog.types').LogoutDialogProps} props */
export const LogoutDialog = ({ open, onClose }) => {
  return (
    <ConfirmDialog
      open={open}
      title="Cerrar sesión"
      onConfirm={() => {
        onClose();
        logout();
      }}
      onCancel={onClose}
      onClose={onClose}
      handleCloseIconButton={true}
      cancelLabel="Cancelar"
      confirmLabel="Cerrar sesión"
      confirmButtonToRight
      DialogProps={{ fullWidth: true, fullScreen: false }}
    >
      <Typography variant="body1">¿Quieres cerrar sesión?</Typography>
      <Typography variant="body2">
        Podrás continuar con el proceso en otro momento.
      </Typography>
    </ConfirmDialog>
  );
};
