import axios from 'axios';
import { sifeConfig } from '../configs/sifeConfig';
import { interceptorHandler } from '../interceptors';

// Log app version

const origin = window.location.origin;
let cstmEnv = 'local';
const localIp = process.env.REACT_APP_LOCAL_IP;
let serverUrlHostname = 'localhost';
let serverUrlPort = '4000';
let sifeUrlHostname = 'localhost';
let sifeUrlPort = '3000';

if (localIp) {
  serverUrlHostname = localIp;
  sifeUrlHostname = localIp;
}

export let serverUrl = `http://${serverUrlHostname}:${serverUrlPort}/graphql`;
export let sifeAuthOptions = {
  login: '/ext-login?reqEmailVerify=1',
  signup: '/ext-signup?reqEmailVerify=1&reqFields=email,rfc',
};
export let sifeAuthUrl = `http://${sifeUrlHostname}:${sifeUrlPort}`;
export let sifeSingleSignUrl = `http://${sifeUrlHostname}:${sifeUrlPort}/home/single-sign/`;
export let sifeMultiSignUrl = `http://${sifeUrlHostname}:${sifeUrlPort}/home/multi-sign/`;
export let sifeVerifyUrl = `http://${sifeUrlHostname}:${sifeUrlPort}/home/verify/info`;
export let sifeCreateSignatureUrl = `http://${sifeUrlHostname}:${sifeUrlPort}/home/sign/create`;
export let sifeProfileUrl = `http://${sifeUrlHostname}:${sifeUrlPort}/home/profile/general`;
export let soraContratosUrl = `http://${sifeUrlHostname}:${sifeUrlPort}`;
let sifeOrigin = `http://${sifeUrlHostname}:${sifeUrlPort}`;

if (origin.includes('https://staging')) {
  // if (true) {
  cstmEnv = 'staging';
  serverUrl = 'https://staging.api.app.sora.mx/graphql';
  sifeOrigin = 'https://staging.sife.app.sora.mx';
  sifeAuthUrl = `${sifeOrigin}`;
  sifeVerifyUrl = `${sifeOrigin}/home/verify/info`;
  sifeSingleSignUrl = `${sifeOrigin}/home/single-sign/`;
  sifeMultiSignUrl = `${sifeOrigin}/home/multi-sign/`;
  sifeCreateSignatureUrl = `${sifeOrigin}/home/sign/create`;
  sifeProfileUrl = `${sifeOrigin}/home/profile/general`;
  soraContratosUrl = 'http://staging.contratos.sora.mx';
} else if (origin.includes('https://dev')) {
  cstmEnv = 'dev';
  serverUrl = 'https://dev.api.app.sora.mx/graphql';
  sifeOrigin = 'https://dev.sife.app.sora.mx';
  sifeAuthUrl = `${sifeOrigin}`;
  sifeVerifyUrl = `${sifeOrigin}/home/verify/info`;
  sifeSingleSignUrl = `${sifeOrigin}/home/single-sign/`;
  sifeMultiSignUrl = `${sifeOrigin}/home/multi-sign/`;
  sifeCreateSignatureUrl = `${sifeOrigin}/home/sign/create`;
  sifeProfileUrl = `${sifeOrigin}/home/profile/general`;
  soraContratosUrl = 'http://dev.contratos.sora.mx';
} else if (origin.includes('https://app.sora')) {
  cstmEnv = 'prod';
  serverUrl = 'https://api.app.sora.mx/graphql';
  sifeOrigin = 'https://sife.app.sora.mx';
  sifeAuthUrl = `${sifeOrigin}`;
  sifeVerifyUrl = `${sifeOrigin}/home/verify/info`;
  sifeSingleSignUrl = `${sifeOrigin}/home/single-sign/`;
  sifeMultiSignUrl = `${sifeOrigin}/home/multi-sign/`;
  sifeCreateSignatureUrl = `${sifeOrigin}/home/sign/create`;
  sifeProfileUrl = `${sifeOrigin}/home/profile/general`;
  soraContratosUrl = 'http://contratos.sora.mx';
}

if (cstmEnv !== 'prod') {
  console.log(
    'APP VERSION:',
    document.head.querySelector('meta[name="build-version"]')?.content ||
      'No v meta tag found',
  );
  console.log('Pointed GRAPHQL server: ', serverUrl);
}

export const trustedOrigins = [sifeOrigin];
export const environment = cstmEnv;
const getApiClient = ({ protocol, hostname, port, api, version }) => {
  if (environment !== 'prod' && environment !== 'dev' && localIp) {
    hostname = localIp;
  }
  const portString = port ? `:${port}` : '';
  const apiString = api ? `/${api}` : '';
  const versionString = version ? `/${version}` : '';
  const axiosConfig = {
    baseURL: `${protocol}://${hostname}${portString}${apiString}${versionString}`,
    responseType: 'json',
    validateStatus: () => {
      return true;
    },
  };
  environment !== 'prod' && console.log('Pointed API: ', axiosConfig);
  return axios.create(axiosConfig);
};

export const sifeInstance = getApiClient(sifeConfig(environment));

interceptorHandler(sifeInstance);

// Initialize Google Analytics

/**
 * @param {string} eventName
 * @param {string} eventCategory
 * @param {object} eventData
 */
export let gtag = (eventName, eventCategory, eventData) => {};

if (environment !== 'local' && environment !== 'staging') {
  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-B7KC74BEYL';
  script.async = true;
  document.head.appendChild(script);
  window.dataLayer = window.dataLayer || [];
  gtag = function () {
    window.dataLayer.push(arguments);
  };
  gtag('js', new Date());
  gtag('config', 'G-B7KC74BEYL');
}
