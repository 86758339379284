export const addCreatorInfoToTemplateItems = ({ templates, creatorUsers }) => {
  const newTemplates = templates.map((template) => {
    const creator = creatorUsers.find(({ _id }) => _id === template.createdBy);
    return {
      ...template,
      creatorFullName: creator?.fullName,
      creatorProfilePicture: creator?.profilePicture,
    };
  });
  return newTemplates;
};
