// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import { useScreenSize } from '../../../../Hooks';
import {
  ControlledBirthdateInput,
  ControlledGenderInput,
  ControlledMaritalStatusInput,
} from '../../../../businessComponents/ControlledInputs';

/** @param {import('./UserInfoRequestedDialog.types').UserPersonalInfoFormProps} props */
export const UserPersonalInfoForm = (props) => {
  const { form, dataRequired, onSubmit } = props;
  const { isMobile, md } = useScreenSize();
  const stackPadding = isMobile ? 2 : 3;
  const stackWidth = isMobile || md ? '100%' : '480px';

  return (
    <>
      <Stack p={stackPadding} width={stackWidth}>
        <Typography variant={isMobile ? 'h5' : 'h6'}>
          Completa tus datos personales:
        </Typography>
      </Stack>
      <Stack p={stackPadding} gap={3} width={stackWidth}>
        {(dataRequired.BIRTHDATE || dataRequired.GENDER) && (
          <Stack flex={1} gap={2} direction={isMobile ? 'column' : 'row'}>
            {dataRequired.BIRTHDATE && (
              <Stack flex={1}>
                <ControlledBirthdateInput
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Campo requerido',
                    },
                  }}
                  textFieldProps={{
                    label: 'Fecha de nacimiento',
                    placeholder: '',
                  }}
                />
              </Stack>
            )}
            {dataRequired.GENDER && (
              <Stack flex={1}>
                <ControlledGenderInput
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Campo requerido',
                    },
                  }}
                  textFieldProps={{ label: 'Sexo' }}
                />
              </Stack>
            )}
          </Stack>
        )}
        {dataRequired.MARITAL_STATUS && (
          <Stack>
            <ControlledMaritalStatusInput
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{ label: 'Estado civil' }}
            />
          </Stack>
        )}
      </Stack>
      <Stack p={stackPadding} width={stackWidth}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          disabled={!form.formState.isValid}
          onClick={form.handleSubmit(onSubmit)}
        >
          Continuar
        </Button>
      </Stack>
    </>
  );
};
