import { Stack } from '@mui/material';
import { EmptySpaceTemplateImg } from '../../../../../components/Illustrations/Illustrations';
import { Button } from '../../../../../newComponents/Button';
import { Typography } from '../../../../../newComponents/Typography';
import { usePermissionChecker } from '../../../../../Hooks';
import { TemplateEmptySpaceProps } from './TemplateEmptySpace.types';

export const TemplateEmptySpace = (props: TemplateEmptySpaceProps) => {
  const { onCreateTemplate } = props;
  const userHasPermissionToCreateOrEdit = usePermissionChecker({
    permission: 'templates',
    action: 'update',
  });

  return (
    <Stack
      gap={4}
      height="500px"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack>
        <EmptySpaceTemplateImg width="150px" />
      </Stack>
      <Stack sx={{ maxWidth: '400px', textAlign: 'center' }} gap={2}>
        <Typography variant="h3">Aún no tienes plantillas</Typography>
        <Typography variant="body1" color="grey">
          Las plantillas te ayudan a diseñar documentos con un aspecto y formato
          profesional de acuerdo a las necesidades y lineamientos de tu empresa.
        </Typography>
      </Stack>
      <Button
        variant="contained"
        disabled={!userHasPermissionToCreateOrEdit}
        onClick={onCreateTemplate}
        tooltipText={
          !userHasPermissionToCreateOrEdit &&
          'No cuentas con los permisos para esta acción'
        }
        tooltipArrow
        tooltipPlacement="top"
      >
        Crear plantilla
      </Button>
    </Stack>
  );
};
