// @ts-nocheck
import styled from 'styled-components';
import soraHappyImg from './soraHappy.png';
import soraAngryImg from './soraAngry.png';
import soriHappySvg from './sori_happy.svg';
import thumbsUpImg from './thumbsUp.png';
import noEmployees from './noEmployees.svg';
import waitingVerificationImg from './Esperando_Verificación.png';
import representingImg from './RepLeg.png';
import sendEmailImg from './Send_Mail.png';
import successVerificationImg from './Success_CuentaVerficada.png';
import workSpaceImg from './Worskspace.png';
import companyCreatedImage from './companyCreatedImage.svg';
import moduleInConstruction from './moduleInConstruction.svg';
import emptyspaceAttention from './emptySpaceAttention.png';
import emptyspaceAttentionSvg from './emptySpaceAttention.svg';
import reportSvg from './report_illustration.svg';
import errorSori from './sori_error.svg';
import createCompany from './createCompany.svg';
import adminInvitation from './adminInvitation.svg';
import noWorkCentersSvg from './noWorkcenters.svg';
import passwordCreatedSvg from './passwordCreated.svg';
import somethingWentWrong from './somethingWentWrong.svg';
import soraPermissions from './soraPermissions.svg';
import emptySpaceSuccessSvg from './emptySpaceSuccess.svg';
import myCompany from './myCompany.svg';
import emptySpaceNoItems from './emptySpaceNoItems.svg';
import oval from './Oval.svg';
import oval2 from './Oval2.svg';
import internationalPhoneNumber from './internationalPhoneNumber.svg';
import emptySpaceTemplate from './emptySpaceTemplate.png';
import emptySpaceWorkTitle from './emptySpaceWorkTitle.png';
import employee from './employee.webp';
import folder from './folder.svg';
import folderAdd from './folderAdd.svg';
import permissionLock from './permissionLock.png';
import icChat from './ic_chat.svg';
import popoverArrow from './popoverArrow.svg';
import xlsFile from './xlsFile.svg';
import emptyContentAnalytics from './illustrationEmptyContent.png';
import error from './error.png';
import search from './search.webp';
import clipboard from './clipboard.webp';
import module from './module.png';
import cursor from './cursor.png';
import emptySpaceChart from './empty_space_chart_2.png';
import emptySpaceAc from './empty_space_ac.svg';

export const PopoverArrow = styled.img.attrs((props) => ({
  src: popoverArrow,
  props,
}))``;

export const WaitingVerification = styled.img.attrs((props) => ({
  src: waitingVerificationImg,
  props,
}))``;

export const FolderIllustration = (props) => {
  return <img src={folder} alt="" {...props} />;
};

export const FolderAddIllustration = (props) => {
  return <img src={folderAdd} alt="" {...props} />;
};

export const XlsFileIllustration = (props) => {
  return <img src={xlsFile} alt="" {...props} />;
};

export const SoraHappyIllustration = (props) => {
  return <img src={soraHappyImg} alt="" {...props} />;
};

export const SoraAngryIllustration = (props) => {
  return <img src={soraAngryImg} alt="" {...props} />;
};

export const Representing = styled.img.attrs((props) => ({
  src: representingImg,
  props,
}))``;

export const SendEmailPng = styled.img.attrs((props) => ({
  src: sendEmailImg,
  props,
}))``;

export const SuccessVerification = styled.img.attrs((props) => ({
  src: successVerificationImg,
  props,
}))``;

export const WorkSpace = styled.img.attrs((props) => ({
  src: workSpaceImg,
  props,
}))``;

export const CreateCompany = styled.img.attrs((props) => ({
  src: createCompany,
  props,
}))``;

export const NoWorkCenters = styled.img.attrs((props) => ({
  src: noWorkCentersSvg,
  props,
}))``;

export const SoraHappy = styled.img.attrs((props) => ({
  src: soraHappyImg,
  props,
}))``;

export const SoriHappy = styled.img.attrs((props) => ({
  src: soriHappySvg,
  props,
}))``;

export const CompanyCreated = styled.img.attrs((props) => ({
  src: companyCreatedImage,
  props,
}))``;

export const ModuleInConstruction = styled.img.attrs((props) => ({
  src: moduleInConstruction,
  props,
}))``;

export const ReportSvg = styled.img.attrs((props) => ({
  src: reportSvg,
  props,
}))``;

export const SoraAngry = styled.img.attrs((props) => ({
  src: soraAngryImg,
  props,
}))``;

export const ThumbsUp = styled.img.attrs((props) => ({
  src: thumbsUpImg,
  props,
}))``;

export const NoEmployees = styled.img.attrs((props) => ({
  src: noEmployees,
  props,
}))``;

export const EmptySpaceImg = styled.img.attrs((props) => ({
  src: emptyspaceAttention,
  props,
}))``;

export const EmptySpaceSvg = styled.img.attrs((props) => ({
  src: emptyspaceAttentionSvg,
  props,
}))``;

export const SoriErrorImgTag = styled.img.attrs((props) => ({
  src: errorSori,
  props,
}))``;

export const SomethingWentWrongSvg = styled.img.attrs((props) => ({
  src: somethingWentWrong,
  props,
}))``;

export const AdminInvitationSvg = styled.img.attrs((props) => ({
  src: adminInvitation,
  props,
}))``;

export const MyCompanySvg = styled.img.attrs((props) => ({
  src: myCompany,
  props,
}))``;

export const SoraPermissions = styled.img.attrs((props) => ({
  src: soraPermissions,
  props,
}))``;

export const Oval = styled.img.attrs((props) => ({
  src: oval,
  props,
}))``;

export const Oval2 = styled.img.attrs((props) => ({
  src: oval2,
  props,
}))``;

export const EmptySpaceSuccess = styled.img.attrs((props) => ({
  src: emptySpaceSuccessSvg,
  props,
}))``;

export const InternationalPhoneNumber = styled.img.attrs((props) => ({
  src: internationalPhoneNumber,
  props,
}))``;

export const EmptySpaceTemplateImg = styled.img.attrs((props) => ({
  src: emptySpaceTemplate,
  props,
}))``;

export const EmptySpaceWorkTitleImg = styled.img.attrs((props) => ({
  src: emptySpaceWorkTitle,
  props,
}))``;

export const EmployeeImg = styled.img.attrs((props) => ({
  src: employee,
  props,
}))``;

export const PermissionLockImg = styled.img.attrs((props) => ({
  src: permissionLock,
  props,
}))``;

export const ModuleIllustration = styled.img.attrs((props) => ({
  src: module,
  props,
}))``;

export const IcChatIllustration = styled.img.attrs((props) => ({
  src: icChat,
  props,
}))``;
export const EmptyContentsAnalyticsPng = styled.img.attrs((props) => ({
  src: emptyContentAnalytics,
  props,
}))``;

export const ErrorImg = styled.img.attrs((props) => ({
  src: error,
  props,
}))``;

export const CursorIllustration = styled.img.attrs((props) => ({
  src: cursor,
  props,
}))``;
export const ClipboardImg = styled.img.attrs((props) => ({
  src: clipboard,
  props,
}))``;

export const SearchImg = styled.img.attrs((props) => ({
  src: search,
  props,
}))``;

export const EmptySpaceChart = styled.img.attrs((props) => ({
  src: emptySpaceChart,
  props,
}))``;

export const EmptySpaceAc = styled.img.attrs((props) => ({
  src: emptySpaceAc,
  props,
}))``;

export const Illustrations = {
  passwordCreated: passwordCreatedSvg,
  adminInvitationSvg: adminInvitation,
  somethingWentWrongSvg: somethingWentWrong,
  emptySpace: emptyspaceAttention,
  emptySpaceSvg: emptyspaceAttentionSvg,
  emptySpaceNoItems,
  emptySpaceSuccessSvg,
  noEmployees: noEmployees,
  noWorkCenters: noWorkCentersSvg,
  representing: representingImg,
  sendEmail: sendEmailImg,
  SoraAngry: SoraAngry,
  SoraHappy: SoraHappy,
  successVerification: successVerificationImg,
  thumbsUp: thumbsUpImg,
  waitingVerification: waitingVerificationImg,
  workSpace: workSpaceImg,
  SoriErrorImg: SoriErrorImgTag,
  SoriErrorSvg: errorSori,
  soraPermissions,
  moduleIllustration: module,
  cursorIllustration: cursor,
  emptySpaceChart: emptySpaceChart,
  emptySpaceAc: emptySpaceAc,
};
