import React from 'react';
import { useController } from 'react-hook-form';
import { RadioGroup } from '@mui/material';

export const ControlledRadio = ({
  control,
  name,
  muiProps,
  callbackOnChange = () => {},
  children,
}) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  return (
    <RadioGroup
      aria-label="form"
      name={name}
      value={value}
      onChange={(event) => {
        onChange(event);
        callbackOnChange(event);
      }}
      {...muiProps}
    >
      {children}
    </RadioGroup>
  );
};
