import { useMutation, useReactiveVar } from '@apollo/client';
import { useState, useEffect } from 'react';
import { PrimaryButton } from '../../../../components/Buttons/Buttons';
import { SpacedWrapper } from '../../../../components/Utils/Utils';
import { changeCompany } from '../../../../utils/changeCompany';
import { mimeTypes } from '../../../../utils/constants';
import {
  CONFIRM_RECEIPTS,
  CONFIRM_XML_RECEIPTS_TO_LINK,
  GET_RECEIPTS,
  RECEIPT_CONFIG,
  UPLOAD_RECEIPTS,
  UPLOAD_SALARY,
  VALIDATE_XML_RECEIPTS_TO_LINK,
} from '../gql';
import UploadProgress from './UploadProgress';
import { DefaultErrorComponent } from '../../../../components/ErrorBoundary/ErrorBoundary';
import { ErrorBoundary } from '@sentry/react';
import { useTheme } from '@mui/material/styles';
import { gtag } from '../../../../API/instance/createInstance';
import { UploadSalaryError } from '../SalaryConfig/SalaryConfig';
import { ErrorWrapper } from './styles';
import { XmlReceiptConfiguration } from './XmlReceiptConfiguration';
import { LinkingXmlImportResume } from './LinkingXmlImportResume';
import {
  currentCompanyVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { TextInput } from '../../../../newComponents/TextInput';
import { Divider, MenuItem, Stack } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { FileUpload } from '../../../../newComponents/Upload/FileUpload';

export const ReceiptUpload = ({
  onClose,
  handleCloseAll,
  setUploadError,
  refetchReceipts,
}) => {
  const theme = useTheme();
  const currentCompany = useReactiveVar(currentCompanyVar);
  const [showXMLSalary, setShowXMLSalary] = useState(
    currentCompany.configs.showXMLSalary.active,
  );

  const [areReceiptsSignable, setAreReceiptsSignable] = useState(
    currentCompany.configs.areReceiptsSignable?.active ?? true,
  );
  const [showRegisteredSalary, setShowRegisteredSalary] = useState(
    currentCompany.configs.showRegisteredSalary.active,
  );
  /**
   * Possible values: SELECT_TYPE, SALARY_ERROR, XML_ERROR, LOADING, XMLLINKRESUME
   */
  const [currentStage, setCurrentStage] = useState('SELECT_TYPE');
  const [file, setFile] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(false);
  const [salaryErrorData, setSalaryErrorData] = useState({});
  const [errorMsg, setErrorMsg] = useState('');
  const [errorsInFiles, setErrorsInFiles] = useState([]);
  const [salaryFile, setSalaryFile] = useState(null);
  const [xmlImportType, setXmlImportType] = useState('XML'); //XML - LINKTOPDF
  const [linkXmlValidation, setLinkXmlValidation] = useState({});
  const [errors, setErrors] = useState([]);

  const [validateReceipts] = useMutation(UPLOAD_RECEIPTS);
  const [confirmReceipts] = useMutation(CONFIRM_RECEIPTS, {
    refetchQueries: [{ query: GET_RECEIPTS }],
  });
  const [setReceiptConfigs] = useMutation(RECEIPT_CONFIG);
  const [uploadSalary] = useMutation(UPLOAD_SALARY);
  const [confirmXmlReceiptsToLink] = useMutation(CONFIRM_XML_RECEIPTS_TO_LINK, {
    refetchQueries: [{ query: GET_RECEIPTS }],
  });
  const [validateXmlReceiptsToLink] = useMutation(
    VALIDATE_XML_RECEIPTS_TO_LINK,
  );

  useEffect(() => {
    if (!showXMLSalary) setShowRegisteredSalary(false);
  }, [showXMLSalary]);

  useEffect(() => {
    if (!showRegisteredSalary) setSalaryFile(null);
  }, [showRegisteredSalary]);

  const onReceiptUpload = async (files) => {
    if (!files.length) return;
    const file = files[0];
    setFile(file);
    setErrors([]);
  };

  useEffect(() => {
    if (completed) {
      setTimeout(() => handleCloseAll(), 2000);
    }
  }, [completed, handleCloseAll]);

  const handleSubmit = async () => {
    setCurrentStage('LOADING');
    try {
      const initialShowXMLSalary = currentCompany.configs.showXMLSalary.active;
      const initialShowRegisteredSalary =
        currentCompany.configs.showRegisteredSalary.active;
      const initialAreReceiptsSignable =
        currentCompany.configs.areReceiptsSignable?.active;
      if (
        initialShowXMLSalary !== showXMLSalary ||
        initialShowRegisteredSalary !== showRegisteredSalary ||
        initialAreReceiptsSignable !== areReceiptsSignable
      ) {
        await setReceiptConfigs({
          variables: {
            input: {
              showXMLSalary,
              showRegisteredSalary,
              areReceiptsSignable,
            },
          },
        });
        changeCompany({
          ...currentCompany,
          configs: {
            showRegisteredSalary: { active: showRegisteredSalary },
            showXMLSalary: { active: showXMLSalary },
            areReceiptsSignable: { active: areReceiptsSignable },
          },
        });
      }
      if (salaryFile) {
        const salaryUploadResult = await uploadSalary({
          variables: {
            file: salaryFile,
          },
        });
        switch (salaryUploadResult.data.uploadSalary.__typename) {
          case 'Success':
            break;
          case 'UploadSalaryWsNotFound':
          case 'UploadSalaryUsersNotFound':
          case 'UploadSalaryNoRfcsFoundInExcel':
            setSalaryErrorData(salaryUploadResult.data.uploadSalary);
            setCurrentStage('SALARY_ERROR');
            return;
          default:
            throw Error('Error desconocido al subir salarios');
        }
      }
      if (xmlImportType === 'LINKTOPDF') {
        const { data } = await validateXmlReceiptsToLink({
          variables: {
            file,
          },
        });

        if (data.validateXmlReceiptsToLink.wasThereErrors) {
          setErrorMsg('Los recibos no pasaron la validación');
          setErrorsInFiles(
            data.validateXmlReceiptsToLink.receiptValidation.map(
              ({ filename, errorMessage }) => ({
                filename,
                errorMessage,
              }),
            ),
          );
          setError(true);
          setUploadError(true);
        } else if (data.validateXmlReceiptsToLink.wasThereWarnings) {
          setLinkXmlValidation(data.validateXmlReceiptsToLink);
          setCurrentStage('XMLLINKRESUME');
        } else {
          const xmlValidationData = data.validateXmlReceiptsToLink;
          const foundReceipts = xmlValidationData.fileLookupResume
            .filter((r) => r.receiptsFound.length === 1)
            .map((r) => ({
              uuid: r.stampUuid,
              id: r.receiptsFound[0]._id,
              type: 'FOUND',
            }));
          const confirmResult = await confirmXmlReceiptsToLink({
            variables: {
              input: {
                requestId: xmlValidationData.requestKey,
                stampList: xmlValidationData.requestStamps,
                solvingReceipts: foundReceipts,
              },
            },
          });
          switch (confirmResult.data.confirmXmlReceiptsToLink.__typename) {
            case 'Success':
              setCompleted(true);
              globalSnackbarVar({
                message: 'Recibos importados exitosamente',
                show: true,
                severity: 'success',
              });
              break;
            case 'SelectedPdfConflict':
              globalSnackbarVar({
                message:
                  'Hay un conflicto en los PDF encontrados y los seleccionados',
                severity: 'error',
                show: true,
              });
              break;
            default:
              globalSnackbarVar({
                message:
                  'Hubo un error al importar los recibos. Contacte a Sora.',
                severity: 'error',
                show: true,
              });
          }
        }
      } else {
        const { data } = await validateReceipts({
          variables: {
            file,
          },
        });

        if (data.uploadReceipts.wasThereErrors) {
          setErrorMsg('Los recibos no pasaron la validación');
          setErrorsInFiles(
            data.uploadReceipts.receiptValidation.map(
              ({ filename, errorMessage }) => ({
                filename,
                errorMessage,
              }),
            ),
          );
          setError(true);
          setUploadError(true);
          return;
        }

        const { requestKey, requestStamps } = data.uploadReceipts;
        if (requestStamps?.length > 0) {
          await confirmReceipts({
            variables: {
              requestKey,
              stampList: requestStamps,
              xmlOnly: !areReceiptsSignable,
            },
          });
          setTimeout(() => setCompleted(true), 1000);
          refetchReceipts && refetchReceipts();
        } else {
          setErrorMsg('Los recibos no pasaron la validación');
          setError(true);
          setUploadError(true);
        }
        gtag('event', 'click', {
          event_category: 'Upload_Receipt_XML',
        });
      }
    } catch (err) {
      console.error(err);
      setUploadError(true);
      setError(true);
    }
  };

  switch (currentStage) {
    case 'SALARY_ERROR':
      return (
        <ErrorWrapper>
          <UploadSalaryError errorData={salaryErrorData} />
        </ErrorWrapper>
      );
    case 'LOADING':
      return (
        <UploadProgress
          file={file}
          completed={completed}
          onClose={onClose}
          error={error}
          errorMsg={errorMsg}
          errorsInFiles={errorsInFiles}
        />
      );
    case 'XMLLINKRESUME':
      return (
        <LinkingXmlImportResume
          xmlValidationData={linkXmlValidation}
          setCurrentStage={setCurrentStage}
          setCompleted={setCompleted}
        />
      );
    default:
      break;
  }

  return (
    <ErrorBoundary fallback={<DefaultErrorComponent />}>
      <SpacedWrapper column m="32px 24px 32px 24px">
        <TextInput
          label="Tipo de carga"
          select
          value={xmlImportType}
          size="small"
          fullWidth
          onChange={(e) => setXmlImportType(e.target.value)}
        >
          <MenuItem value="XML">Cargar recibos nuevos</MenuItem>
          <MenuItem value="LINKTOPDF">
            Ligar .xml a .pdf previamente importados
          </MenuItem>
        </TextInput>
        <Divider />
        {xmlImportType && (
          <>
            <Stack gap={1}>
              <Typography variant="h6">
                {xmlImportType === 'XML'
                  ? 'Cargar recibos nuevos'
                  : 'Ligar .xml a .pdf previamente importados'}
              </Typography>
              <Typography variant="body2">
                {xmlImportType === 'XML'
                  ? 'Deberás importar tus archivos .xml en una carpeta .zip'
                  : 'Si importaste previamente recibos .pdf, podrás ligarles recibos .xml.'}
              </Typography>
            </Stack>
            <FileUpload
              id="receiptUpload"
              single
              title={file ? file.name : 'Seleccionar carpeta .zip'}
              onChange={onReceiptUpload}
              accept={mimeTypes.zip}
              setErrors={setErrors}
              iconSize={xmlImportType === 'XML' ? '50px' : '100px'}
              direction={xmlImportType === 'XML' ? 'row' : 'column'}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.newPalette.grey.transparent8,
                height: xmlImportType === 'XML' ? 'auto' : '500px',
              }}
              content={
                <ul
                  style={{
                    color: theme.newPalette.text.secondary,
                  }}
                >
                  <li>
                    <Typography
                      variant="caption"
                      color={theme.newPalette.text.secondary}
                    >
                      Comprime en una carpeta .zip tus recibos .xml
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      variant="caption"
                      color={theme.newPalette.text.secondary}
                    >
                      Arrastra aquí o seleccionar tu carpeta .zip
                    </Typography>
                  </li>
                </ul>
              }
            />
            {xmlImportType === 'XML' && (
              <XmlReceiptConfiguration
                areReceiptsSignable={areReceiptsSignable}
                salaryFile={salaryFile}
                setAreReceiptsSignable={setAreReceiptsSignable}
                setSalaryFile={setSalaryFile}
                showXMLSalary={showXMLSalary}
                setShowRegisteredSalary={setShowRegisteredSalary}
                showRegisteredSalary={showRegisteredSalary}
                setShowXMLSalary={setShowXMLSalary}
              />
            )}
            <PrimaryButton
              style={{ alignSelf: 'flex-end' }}
              disabled={!file || Boolean(errors.length)}
              onClick={handleSubmit}
            >
              Comenzar la importacion
            </PrimaryButton>
          </>
        )}
      </SpacedWrapper>
    </ErrorBoundary>
  );
};
