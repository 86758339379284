// @ts-check
import React from 'react';
import { Chip } from '../../newComponents/Chip';
import { Icon } from '../../components/Icons/Icons';

export const DocumentRequestedStatusChip = () => {
  return (
    <Chip
      icon={<Icon icon="indeterminate_circle_line" />}
      color="default"
      label="Solicitado al empleado"
      variant="soft"
    />
  );
};
