import { useReactiveVar } from '@apollo/client';
import { Dialog, Box, useMediaQuery, Grid } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { environment } from '../../../API/instance/createInstance';
import { PrimaryButton } from '../../../components/Buttons/Buttons';
import DocumentSigner from '../../../components/DocumentSigner/DocumentSigner';
import { AdminInvitationSvg } from '../../../components/Illustrations/Illustrations';
import { BodyLarge } from '../../../components/Typographies/Typography';
import { buttonStyles, containerStyle, Title } from './style';
import {
  currentCompanyVar,
  globalSnackbarVar,
  pendingDocsVar,
} from '../../../cache.reactiveVars';
import { useCustomTheme } from '../../../Hooks/useCustomTheme';

const AdminDocuments = ({
  shouldShowDialogs,
  setShouldShowDialogs,
  adminDocuments,
  ...props
}) => {
  const [showContract, setShowContract] = useState(false);
  const [open, setOpen] = useState(true);

  const history = useHistory();
  const company = useReactiveVar(currentCompanyVar);
  const pendingDocs = useReactiveVar(pendingDocsVar);

  const theme = useCustomTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.up('md'));

  const handleSuccessfulSign = (docIds) => {
    const newAdminDocs = [...pendingDocs.adminDocs];
    //Filtra documentos por la empresa actual
    const currentAdminDocs = adminDocuments.filter(
      (doc) => doc?._id !== docIds[0]?.id,
    );

    //Filtra documentos por todas las empresas del usuario y eso sigo guardando en cache
    const filteredNewAdminDocs = newAdminDocs.filter(
      (doc) => doc?._id !== docIds[0]?.id,
    );
    const newPendingDocsVars = {
      ...pendingDocsVar(),
      adminDocs: filteredNewAdminDocs,
    };
    pendingDocsVar(newPendingDocsVars);
    localStorage.pendingDocs = JSON.stringify(newPendingDocsVars);
    setOpen(false);
    if (!currentAdminDocs?.length) {
      setShouldShowDialogs({
        ...shouldShowDialogs,
        askForAdminConsent: false,
      });
      return globalSnackbarVar({
        show: true,
        severity: 'success',
        message: 'Documento firmado exitosamente',
      });
    }
    setTimeout(() => {
      setOpen(true);
    }, 500);
  };

  const cancelSign = () => {
    if (environment === 'local' || environment === 'staging') {
      setShouldShowDialogs({
        ...shouldShowDialogs,
        askForAdminConsent: false,
      });
      return setOpen(false);
    }
    history.push('/rhpod/empleado');
    setShowContract(false);
  };

  if (!adminDocuments.length) return null;

  if (showContract)
    return (
      <DocumentSigner
        signed={false}
        handleClose={cancelSign}
        company={company.clientName}
        title={adminDocuments[0].title}
        docId={adminDocuments[0]._id}
        documentUpdated={adminDocuments[0]?.updated}
        signable
        requestType="DOCUMENT"
        open={open}
        onSuccess={handleSuccessfulSign}
        message={adminDocuments[0]?.updated ? 'Documento actualizado' : ''}
      />
    );

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullScreen={xs}
      fullWidth
      {...props}
      onClose={cancelSign}
    >
      <Grid container sx={{ ...containerStyle }}>
        {!sm && (
          <Grid item xs={12} md={6} textAlign="center">
            <AdminInvitationSvg width="250px" />
          </Grid>
        )}
        <Grid item xs={12} md={6} margin="auto">
          <Title>
            <span style={{ color: theme.customPalette.primary.blue }}>
              {company?.clientName}
            </span>{' '}
            te ha invitado a ser un administrador
          </Title>
          <Box sx={{ mt: 3 }}>
            <BodyLarge>
              Ahora tendrás acceso a funcionalidades que ayudarán a mejorar la
              organización de la empresa.
            </BodyLarge>
          </Box>
          <PrimaryButton
            sx={buttonStyles}
            fullWidth
            onClick={() => setShowContract(true)}
          >
            Activar perfil de administrador
          </PrimaryButton>
        </Grid>
        {sm && (
          <Grid item xs={12} md={6} textAlign="center">
            <AdminInvitationSvg width="365px" height="360px" />
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

export default AdminDocuments;
