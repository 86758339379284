//@ts-check
import React, { memo, useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDateTimePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { esES } from '@mui/x-date-pickers/locales';
import {
  formatDate,
  formatDateTime,
  todayAt0Minutes,
} from '../../../../utils/utils';
import { TextInput } from '../../../../newComponents/TextInput';
import { globalSnackbarVar } from '../../../../cache.reactiveVars';
import { es } from 'date-fns/locale';
import { isAfter, isEqual, startOfDay } from 'date-fns';
import { IconButton } from '../../../../newComponents/IconButton';
import { Button, Stack } from '@mui/material';

/**
 * @typedef {'small' | 'medium' | 'tiny'} ValiditySelectorSize
 *
 * @typedef {object} ValiditySelectorBaseProps
 * @property {*} [value]
 * @property {Function} setValue
 * @property {string} [label='Vigencia']
 * @property {boolean} [disabled = false]
 * @property {boolean} [includeTime = false]
 * @property {ValiditySelectorSize} [size]
 * @property {string} [undefinedLabel='Seleccionar']
 * @property {object} [sx]
 * @property {(newValue: Date|null, updateDate: Function) => void} [onChange] overrides default onChange validations for date picker
 * @property {(newValue: Date|null, updateDate: Function) => void} [onAccept] overrides default onAccept validations for date picker
 *
 */

/**
 * Component to select a validity date, actually any date between date and undefined
 * @param {ValiditySelectorBaseProps & Omit<import('@mui/material').TextFieldProps, 'size'|'onChange'> & Omit<import('@mui/material').StandardTextFieldProps, 'size'|'onChange'>} props
 * @return {React.JSX.Element}
 */
export const ValiditySelector = ({
  value = null,
  setValue,
  label = 'Vigencia',
  disabled = false,
  includeTime = false,
  size,
  sx,
  undefinedLabel = '',
  onChange,
  onAccept,
  ...restProps
}) => {
  // We use two internal states to handle the date picker
  // date is use to store the value of the component and representig the selected date, is displayed in the input
  // temporarySelectedDate is used to store a default dateTime and store the selected dateTime when onChange event is fired, and is used to validate the date picker value and update the date state.
  const [date, setDate] = useState(value ?? null);
  const [temporarySelectedDate, setTemporarySelectedDate] =
    useState(todayAt0Minutes());
  const [IsDatePickerOpen, setIsDatePickerOpen] = useState(false);

  /**
   * Update date and value for component value and internal state
   * @param {Date|null} date
   */
  const updateDate = (date) => {
    setDate(date);
    setValue(date);
  };

  const isValidDate = (date) => {
    const today = startOfDay(new Date());
    const newDate = startOfDay(date);
    const isValid = includeTime
      ? isAfter(date, new Date())
      : isEqual(newDate, today) || isAfter(newDate, today);
    return isValid;
  };

  const onChangePickerValue = (newValue) => {
    if (!Boolean(newValue)) return;
    setTemporarySelectedDate(newValue);
    if (!isValidDate(newValue)) {
      globalSnackbarVar({
        show: true,
        message: 'La fecha no puede ser anterior a la fecha actual',
        severity: 'error',
      });
    }
  };

  const onAcceptPickerValue = (newValue) => {
    if (!Boolean(newValue)) return;
    if (!isValidDate(newValue)) {
      globalSnackbarVar({
        show: true,
        message: 'La fecha no puede ser anterior a la fecha actual',
        severity: 'error',
      });
      return;
    }
    updateDate(newValue);
  };

  const openPicker = () => {
    setIsDatePickerOpen(true);
  };

  const dateString = date
    ? includeTime
      ? formatDateTime(date)
      : formatDate(date)
    : 'Elegir fecha...';

  const definedOnChange = (newValue) => {
    if (onChange) {
      setTemporarySelectedDate(newValue);
      onChange(newValue, updateDate);
    } else {
      onChangePickerValue(newValue);
    }
  };
  const definedOnAccept = (newValue) => {
    if (onAccept) {
      onAccept(newValue, updateDate);
    } else {
      onAcceptPickerValue(newValue);
    }
  };

  useEffect(() => {
    if (value) {
      setDate(value);
    } else {
      setDate(null);
    }
  }, [value]);

  return (
    <>
      <TextInput
        value={dateString}
        label={label}
        disabled={disabled}
        size={size === 'tiny' ? 'small' : size}
        sx={{
          '& .MuiOutlinedInput-input': { cursor: 'pointer' },
          '& .MuiInputBase-root': { paddingRight: 0 },
          ...sx,
          paddingRight: 0,
        }}
        InputProps={{
          onClick: () => openPicker(),
          readOnly: true,
          endAdornment: Boolean(date) ? (
            <Stack
              height="100%"
              onClick={(e) => {
                updateDate(null);
                e.stopPropagation();
              }}
              alignItems="center"
              justifyContent="center"
            >
              <IconButton
                onClick={(e) => {
                  updateDate(null);
                }}
                sx={{
                  cursor: 'pointer',
                }}
                icon="close_line"
                disabled={!value}
                size="small"
                color="grey"
              />
            </Stack>
          ) : null,
        }}
        {...restProps}
      />
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={
          esES.components.MuiLocalizationProvider.defaultProps.localeText
        }
        adapterLocale={es}
      >
        {includeTime ? (
          <MobileDateTimePicker
            open={IsDatePickerOpen}
            sx={{ display: 'none' }}
            views={['year', 'month', 'day', 'hours']}
            value={date}
            onChange={definedOnChange}
            disablePast={true}
            ampm={true}
            defaultValue={temporarySelectedDate}
            minTime={todayAt0Minutes()}
            slots={{
              actionBar: ({ className }) => {
                const isValid = isValidDate(temporarySelectedDate);

                return (
                  <Stack
                    className={className}
                    justifyContent="right"
                    flexDirection="row"
                    padding={1}
                  >
                    <Button onClick={() => setIsDatePickerOpen(false)}>
                      Cancelar
                    </Button>
                    <Button
                      disabled={!isValid}
                      onClick={() => {
                        definedOnAccept(temporarySelectedDate);
                        setIsDatePickerOpen(false);
                      }}
                    >
                      OK
                    </Button>
                  </Stack>
                );
              },
            }}
          />
        ) : (
          <MobileDatePicker
            sx={{ display: 'none' }}
            value={date}
            onChange={definedOnChange}
            disablePast={true}
            defaultValue={temporarySelectedDate}
            open={IsDatePickerOpen}
            slots={{
              actionBar: ({ className }) => {
                const isValid = isValidDate(temporarySelectedDate);

                return (
                  <Stack
                    className={className}
                    justifyContent="right"
                    flexDirection="row"
                    padding={1}
                  >
                    <Button onClick={() => setIsDatePickerOpen(false)}>
                      Cancelar
                    </Button>
                    <Button
                      disabled={!isValid}
                      onClick={() => {
                        definedOnAccept(temporarySelectedDate);
                        setIsDatePickerOpen(false);
                      }}
                    >
                      OK
                    </Button>
                  </Stack>
                );
              },
            }}
          />
        )}
      </LocalizationProvider>
    </>
  );
};

export const MemoizedValiditySelector = memo(ValiditySelector);
