// @ts-check
import { Stack, DialogContent, DialogTitle, Box, Grid } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Dialog } from '../../../../newComponents/Dialog';
import { Typography } from '../../../../newComponents/Typography';
import { IconButton } from '../../../../newComponents/IconButton';
import { useScreenSize } from '../../../../Hooks';
import { PendingDocumentsCard } from './PendingDocumentCard';
import { STEP_TYPES } from '../UserOnboarding.constants';
import { userOnboardingVar } from '../UserOnboarding.vars';

/** @param {import('./PendingDocumentsDialog.types').PendingDocumentsDialogProps} props */
export const PendingDocumentsDialog = (props) => {
  const { onDocumentUploaded } = props;
  const { isMobile } = useScreenSize();

  const { onboardingStep, onboardingWorkflow, companyRequirements } =
    useReactiveVar(userOnboardingVar);

  if (!companyRequirements) {
    return null;
  }

  const { pendingDocuments } = companyRequirements;

  const open =
    onboardingWorkflow[onboardingStep] === STEP_TYPES.ASK_FOR_PENDING_DOCUMENTS;

  const handleClose = () => props.onClose();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{ sx: { borderRadius: isMobile ? '0px' : '16px' } }}
    >
      <DialogTitle
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        sx={{ padding: '24px' }}
      >
        <Stack>
          <Typography variant="h6">Complete tu expediente</Typography>
          {!isMobile && (
            <Typography variant="body2">
              Para completar tu expediente debes enviar a tu administrador los
              siguientes documentos.
            </Typography>
          )}
        </Stack>
        <Box>
          <IconButton icon="close_line" onClick={handleClose} />
        </Box>
      </DialogTitle>
      <DialogContent dividers sx={{ minHeight: '50vh', padding: '16px' }}>
        <Grid container spacing={2}>
          {pendingDocuments.map((pendingDocument, index) => (
            <Grid
              item
              xs={12}
              md={4}
              key={`card-${pendingDocument.category}-${pendingDocument.subcategory}`}
            >
              <PendingDocumentsCard
                pendingDocument={pendingDocument}
                onDocumentUploaded={onDocumentUploaded}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
