//@ts-check

import React, { useEffect, useMemo } from 'react';
import { Dialog } from '../../../../components/Dialogs/Dialog';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  deleteEmployeeAbsenceDialogVar,
  finishEmployeeAbsenceDialogVar,
  editEmployeeAbsenceDialogVar,
  resetEditEmployeeAbsenceDialogVar,
  resetFinishEmployeeAbsenceDialogVar,
  resetDeleteEmployeeAbsenceDialogVar,
} from '../EmployeeAbsence.vars';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { IconButton } from '../../../../newComponents/IconButton';
import { Button } from '../../../../newComponents/Button';
import { useForm } from 'react-hook-form';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { EditEmployeeAbsenceForm } from './EditEmployeeAbsenceDialogForm';
import { Typography } from '../../../../newComponents/Typography';
import {
  buildSelectedEmployeeAbsence,
  isDirtyFields,
} from '../EmployeeAbsence.helpers';
import { UPDATE_EMPLOYEE_ABSENCE } from '../EmployeeAbsence.gql';
import { useRefetchAbsenceQueries } from '../hooks/useRefetchQueries';
import { useScreenSize } from '../../../../Hooks';
import { EditEmployeeAbsenceMenu } from './EditEmployeeAbsenceMenu';

/** @type {import('../EmployeeAbsence.types').ExtendedFormEmployeeAbsence} */
const INITIAL_FORM_VALUES = {
  employee: null,
  start: null,
  end: null,
  updateStatusToEndAbsent: true,
  reason: '',
  description: '',
  file: null,
  errors: [],
  shouldUpdateFile: false,
};

export const EditEmployeeAbsenceDialog = () => {
  const { isMobile } = useScreenSize();
  const { open, absenceData } = useReactiveVar(editEmployeeAbsenceDialogVar);
  const currentCompany = useReactiveVar(currentCompanyVar);

  const refetchAbsenceQueries = useRefetchAbsenceQueries();
  const [updateEmployeeAbsence] = useMutation(UPDATE_EMPLOYEE_ABSENCE);

  const form = useForm({
    mode: 'onChange',
    defaultValues: INITIAL_FORM_VALUES,
    reValidateMode: 'onChange',
  });

  const { isValid } = form.formState;
  const [watchErrors] = form.watch(['errors']);
  const watch = form.watch();

  const handleClose = () => {
    form.reset();
    resetEditEmployeeAbsenceDialogVar();
    resetFinishEmployeeAbsenceDialogVar();
    resetDeleteEmployeeAbsenceDialogVar();
  };

  const finishAusence = () => {
    if (!absenceData?.absenceId || !absenceData?.user?._id) {
      return globalSnackbarVar({
        show: true,
        message: 'No se encontró la ausencia a finalizar',
        severity: 'warning',
        duration: 5000,
      });
    }
    finishEmployeeAbsenceDialogVar({
      open: true,
      employeeId: absenceData.user._id,
      absenceIds: [absenceData.absenceId],
      isMultiple: false,
    });
  };

  const deleteAbsence = () => {
    if (!absenceData?.absenceId || !absenceData?.user?._id) {
      return globalSnackbarVar({
        show: true,
        message: 'No se encontró la ausencia a eliminar',
        severity: 'warning',
        duration: 5000,
      });
    }
    deleteEmployeeAbsenceDialogVar({
      open: true,
      employeeId: absenceData.user._id,
      absenceIds: [absenceData.absenceId],
      isMultiple: false,
    });
  };

  const onSubmit = async () => {
    const absenceValues = form.getValues();
    const start = absenceValues?.start;
    const end = absenceValues?.end;

    if (start && end) {
      if (start?.getTime() > end?.getTime()) {
        return globalSnackbarVar({
          show: true,
          message: 'La fecha de inicio no puede ser mayor a la fecha de fin',
          severity: 'error',
          duration: 5000,
        });
      }
    }
    globalBackdropVar({ open: true, text: 'Actualizando ausencia...' });

    try {
      const response = await updateEmployeeAbsence({
        variables: {
          input: {
            companyId: currentCompany._id,
            employeeId: absenceData.user._id,
            absenceId: absenceData.absenceId,
            start: absenceValues.start,
            end: absenceValues.end,
            updateStatusToEndAbsent: absenceValues.updateStatusToEndAbsent,
            reason: absenceValues.reason,
            description: absenceValues.description,
            file: absenceValues.file?.length ? absenceValues.file[0] : null,
            shouldUpdateFile: absenceValues.shouldUpdateFile,
          },
        },
      });

      const data = response?.data?.updateEmployeeAbsence;

      if (data?.__typename === 'ErrorToUpdateEmployeeAbsence') {
        globalSnackbarVar({
          show: true,
          message:
            data.message || 'Error al actualizar ausencia, contacta a SORA.',
          severity: 'error',
          duration: 5000,
        });
        return;
      }

      globalSnackbarVar({
        show: true,
        message: 'Ausencia actualizada',
        severity: 'success',
        duration: 5000,
      });

      if (refetchAbsenceQueries) {
        refetchAbsenceQueries();
      }
      handleClose();
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        show: true,
        message: 'Error al crear ausencia, contacta a SORA',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const isDirtySpecialFields = () => {
    return isDirtyFields({
      oldAbsence: absenceData,
      updatedAbsence: form.getValues(),
    });
  };

  const disabledButton = useMemo(() => {
    const isDirtySpecialFieldsResult = isDirtySpecialFields();
    if (!isValid) return true;
    if (!isDirtySpecialFieldsResult) return true;
    if (watchErrors?.length) return true;

    return false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    if (!absenceData) return;
    const employee = buildSelectedEmployeeAbsence(absenceData.user);
    const fileExists = absenceData.employeeDocumentId
      ? { name: absenceData.documentTitle, id: absenceData.employeeDocumentId }
      : null;
    form.reset({
      employee,
      start: new Date(absenceData.start),
      end: new Date(absenceData.end),
      reason: absenceData.reason,
      description: absenceData.description,
      updateStatusToEndAbsent: absenceData.updateStatusToEndAbsent,
      file: fileExists,
      shouldUpdateFile: false,
      errors: [],
    });
  }, [absenceData, form]);

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={handleClose}
      showCloseButton={false}
    >
      <DialogTitle
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          justifyContent: 'space-between',
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton icon="close_line" onClick={handleClose} />
          <Typography variant="h6">Editar ausencia</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          {isMobile && absenceData?.name === 'PENDING' && (
            <EditEmployeeAbsenceMenu absence={absenceData} />
          )}

          {!isMobile && absenceData?.name === 'PENDING' && (
            <Button
              variant="outlined"
              color="default"
              size="small"
              onClick={finishAusence}
            >
              Finalizar ausencia
            </Button>
          )}
          <IconButton
            icon="delete_bin_line"
            color="error"
            size="small"
            onClick={deleteAbsence}
          />
        </Stack>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ p: '24px' /* display: 'flex', alignItems: 'stretch'  */ }}
      >
        <EditEmployeeAbsenceForm form={form} absence={absenceData} />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          variant="contained"
          size="large"
          onClick={onSubmit}
          disabled={disabledButton}
        >
          Finalizar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
