import { useReactiveVar } from '@apollo/client';
import { Box, Dialog, Grid, Slide, useMediaQuery } from '@mui/material';
import React from 'react';
import { environment } from '../../../API/instance/createInstance';
import { PrimaryButton } from '../../../components/Buttons/Buttons';
import { SoraHappy } from '../../../components/Illustrations/Illustrations';
import { BodyLarge, H4 } from '../../../components/Typographies/Typography';
import { useTheme } from '@mui/material/styles';
import { containerStyle } from '../PendingDocuments/style';
import { dialogOpenVar, userVar } from '../../../cache.reactiveVars';

const WelcomeDialog = ({
  shouldShowDialogs,
  setShouldShowDialogs,
  ...props
}) => {
  const user = useReactiveVar(userVar);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.up('md'));
  // user.myNewCompany = 'CM IDENTIDAD';
  const onCloseDialog = () => {
    if (environment !== 'prod') {
      dialogOpenVar({ ...dialogOpenVar(), welcomeDialog: false });
      setShouldShowDialogs({
        ...shouldShowDialogs,
        welcomeDialog: false,
      });
    }
  };
  const handleClose = () => {
    dialogOpenVar({ ...dialogOpenVar(), welcomeDialog: false });
    setShouldShowDialogs({
      ...shouldShowDialogs,
      welcomeDialog: false,
    });
  };
  return (
    <Dialog
      open={true}
      fullWidth
      fullScreen={xs}
      maxWidth="lg"
      TransitionComponent={Transition}
      onClose={onCloseDialog}
      {...props}
    >
      <Grid container sx={{ ...containerStyle }}>
        {!sm && (
          <Grid item xs={12} md={6} textAlign="center">
            <SoraHappy width="250px" />
          </Grid>
        )}
        <Grid item xs={12} md={6} margin="auto">
          <div>
            <H4 color={theme.customPalette.primary.blue}>{`${user.name} ,`}</H4>
            <H4>¡Te damos la bienvenida a Sora!</H4>
          </div>
          <Box sx={{ mt: 3 }}>
            <BodyLarge>
              A continuación te daremos un tour por tu nueva cuenta personal y
              todas las funcionalidades.
            </BodyLarge>
          </Box>

          <PrimaryButton
            style={{ marginTop: '16px' }}
            fullWidth
            onClick={handleClose}
          >
            ¡Empecemos!
          </PrimaryButton>
        </Grid>
        {sm && (
          <Grid item xs={12} md={6} textAlign="center">
            <SoraHappy width="430px" height="425px" />
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default WelcomeDialog;
