// @ts-check
import React from 'react';
import { Dialog } from '../../../newComponents/Dialog';
import { Stack, useTheme } from '@mui/material';
import { Icon } from '../../../components/Icons/Icons';
import {
  DefaultErrorComponent,
  ErrorBoundary,
} from '../../../components/ErrorBoundary/ErrorBoundary';
import {
  documentCreatorDialogVar,
  resetDocumentCreatorDialog,
  setDocumentCreatorDialogVar,
} from './DocumentCreatorDialog.vars';
import { useReactiveVar } from '@apollo/client';
import { Typography } from '../../../newComponents/Typography';
import ConfirmationDialog from '../../../components/Dialogs/ConfirmationDialog';
import DocumentCreator from '../../../containers/RHPod/Documents/DocumentCreator/DocumentCreator';

export function DocumentCreatorDialog() {
  const documentCreatorDialog = useReactiveVar(documentCreatorDialogVar);
  const { open, openConfirmDialog, onUploadSuccess } = documentCreatorDialog;

  const handleCloseDocument = (confirm) => {
    setDocumentCreatorDialogVar({ openConfirmDialog: false });
    if (confirm) {
      resetDocumentCreatorDialog();
    }
  };

  /** @type {import('../../../theme').CustomTheme} */
  const theme = useTheme();
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={() => resetDocumentCreatorDialog()}
      open={open}
      slideMode
    >
      <Stack direction="row" p="16px" gap="8px">
        <Icon
          fill={theme.newPalette.grey.grey600}
          icon="close_line"
          height="26px"
          pointer
          onClick={() =>
            setDocumentCreatorDialogVar({ openConfirmDialog: true })
          }
        />
        <Typography variant="h6" sx={{ pl: 2 }}>
          Crear documento
        </Typography>
      </Stack>
      <>
        <ErrorBoundary fallback={<DefaultErrorComponent />}>
          <DocumentCreator
            handleConfirmClose={() =>
              setDocumentCreatorDialogVar({ openConfirmDialog: true })
            }
            handleCloseAll={resetDocumentCreatorDialog}
            onUploadSuccess={onUploadSuccess}
          />
        </ErrorBoundary>
        <ConfirmationDialog
          open={openConfirmDialog}
          onClose={handleCloseDocument}
          title="¿Quieres salir del creador de documentos"
          content="Tu progreso no se guardará y tendrás que comenzar de nuevo"
          cancelLabel="Volver"
          acceptLabel="Salir"
        />
      </>
    </Dialog>
  );
}
