// @ts-check
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import AutoSizer from 'react-virtualized-auto-sizer';
import { DialogContent, Stack } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import { Dialog } from '../../../../newComponents/Dialog';
import { SoriHappy } from '../../../../components/Illustrations/Illustrations';
import { useScreenSize } from '../../../../Hooks';
import { SoriSection, InfoSection } from '../UserOnboarding.styles';
import { STEP_TYPES } from '../UserOnboarding.constants';
import {
  userOnboardingVar,
  setUserOnboardingVar,
} from '../UserOnboarding.vars';

export const FullOnboardingWelcomeDialog = () => {
  const { isMobile } = useScreenSize();
  const { onboardingStep, onboardingWorkflow } =
    useReactiveVar(userOnboardingVar);

  const handleClose = () => {
    setUserOnboardingVar({ onboardingStep: onboardingStep + 1 });
  };

  return (
    <Dialog
      open={
        onboardingWorkflow[onboardingStep] ===
        STEP_TYPES.FULL_ONBOARDING_WELCOME_DIALOG
      }
      PaperProps={{ sx: { borderRadius: isMobile ? '0px' : '16px' } }}
    >
      <DialogContent
        sx={{
          padding: 0,
          height: '650px',
          position: 'relative',
        }}
      >
        <AutoSizer disableWidth>
          {({ height }) => (
            <Stack direction="row" height={height}>
              {!isMobile && (
                <SoriSection>
                  <SoriHappy width="300px" />
                </SoriSection>
              )}
              <InfoSection justifyContent="center">
                <Stack
                  gap={2}
                  width={isMobile ? '100%' : '480px'}
                  p={isMobile ? 2 : 3}
                >
                  <Stack
                    gap={4}
                    alignItems={isMobile ? 'center' : 'flex-start'}
                  >
                    <Stack>{isMobile && <SoriHappy width="110px" />}</Stack>
                    <Typography
                      variant={isMobile ? 'h5' : 'h4'}
                      sx={{ fontSize: isMobile ? 'auto' : '34px !important' }}
                    >
                      ¡Te damos la bienvenida a sora!
                    </Typography>
                    <Typography variant="body1">
                      Nos alegra tenerte aqui.
                    </Typography>
                    <Stack gap={0.5}>
                      <Typography variant="body1">
                        A continuación, deberás:
                      </Typography>
                      <Stack gap={0.5} px={1}>
                        <Typography variant="body1">
                          1. Completar tu información personal
                        </Typography>
                        <Typography variant="body1">
                          2. Enviar a tu administrador algunos documentos para
                          completar tu expediente laboral.
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack width="100%">
                      <Button
                        variant="contained"
                        onClick={handleClose}
                        size="large"
                      >
                        ¡Empecemos!
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </InfoSection>
            </Stack>
          )}
        </AutoSizer>
      </DialogContent>
    </Dialog>
  );
};
