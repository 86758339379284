// @ts-check
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Chip } from '../../newComponents/Chip';
import { Menu } from '../../newComponents/Menu';
import { MenuItem } from '../../newComponents/MenuItem';
import { Icon } from '../../components/Icons/Icons';
import { Typography } from '../../newComponents/Typography';
import { ConfirmDialog } from '../../newComponents/Dialog';
import { REQUEST_DOCUMENT_TO_EMPLOYEE } from './DocumentStatusChip.gql';
import { globalBackdropVar, globalSnackbarVar } from '../../cache.reactiveVars';
import { usePermissionChecker } from '../../Hooks';
import { TooltipAccessWrapper } from '../../businessComponents/TooltipAccessWrapper';

/** @param {{ documentId?: string, hideMenu?: boolean, onDocumentRequested?: () => void }} props */
export const DocumentCompletedStatusChip = ({
  documentId,
  hideMenu = false,
  onDocumentRequested = () => {},
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [requestDocumentToEmployee] = useMutation(REQUEST_DOCUMENT_TO_EMPLOYEE);
  const userCanRequestDocsToEmployees = usePermissionChecker({
    permission: 'requestDocsToEmployees',
    action: 'update',
  });

  const [anchorEl, setAnchorEl] = useState(
    /** @type {HTMLElement | null} */ (null),
  );

  const menuOpen = Boolean(anchorEl);

  /** @param {React.MouseEvent<HTMLElement>} event */
  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const handleOpenConfirmDialog = () => setShowConfirmDialog(true);
  const handleCloseConfirmDialog = () => setShowConfirmDialog(false);

  const handleRequestDocumentToEmployee = async () => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Solicitando documento al empleado...',
      });
      await requestDocumentToEmployee({
        variables: { id: documentId },
      });
      onDocumentRequested();
      handleCloseMenu();
      handleCloseConfirmDialog();
      globalSnackbarVar({
        show: true,
        message: 'Solicitud de documento enviada al empleado',
        severity: 'info',
      });
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Error al solicitar el documento al empleado',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return (
    <>
      <Chip
        icon={<Icon icon="checkbox_circle_line" />}
        color="success"
        label="Completado"
        variant="soft"
        deleteIcon={
          <Icon icon={menuOpen ? 'arrow_up_s_line' : 'arrow_down_s_line'} />
        }
        onDelete={hideMenu ? undefined : handleOpenMenu}
      />
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <TooltipAccessWrapper accessAllowed={userCanRequestDocsToEmployees}>
          <MenuItem
            onClick={handleOpenConfirmDialog}
            disabled={!userCanRequestDocsToEmployees}
          >
            <Typography variant="body2">
              Volver a solicitar documento al empleado
            </Typography>
          </MenuItem>
        </TooltipAccessWrapper>
      </Menu>
      <ConfirmDialog
        open={showConfirmDialog}
        onClose={handleCloseConfirmDialog}
        title="Volver a solicitar documento al empleado"
        onCancel={handleCloseConfirmDialog}
        onConfirm={handleRequestDocumentToEmployee}
        confirmLabel="Enviar solicitud"
        cancelLabel="Cancelar"
        confirmButtonToRight
        DialogProps={{ fullScreen: false }}
      >
        <Typography variant="body1" color="grey">
          Se le volverá a solicitar al empleado que envié el documento.
        </Typography>
      </ConfirmDialog>
    </>
  );
};
