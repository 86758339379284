// @ts-check
import React from 'react';
import { Chip } from '../../newComponents/Chip';
import { Icon } from '../../components/Icons/Icons';

export const DocumentPendingReviewStatusChip = () => {
  return (
    <Chip
      icon={<Icon icon="eye_line" />}
      color="warning"
      label="En aprobación"
      variant="soft"
    />
  );
};
