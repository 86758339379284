export const receiptCopies = [
  {
    emoji: '🤔',
    title: '¡Oh, oh! Parece que se te olvidó firmar tu recibo.',
  },
  {
    emoji: '🖐🏻',
    title: '¡Espera! No te vayas sin firmar.',
  },
  {
    emoji: '✍🏻',
    title: 'Hace falta tu firma aquí.',
  },
];

export const documentCopies = [
  {
    emoji: '🤔',
    title: '¡Oh, oh! Parece que se te olvidó firmar tu documento.',
  },
  {
    emoji: '🖐🏻',
    title: '¡Espera! No te vayas sin firmar.',
  },
  {
    emoji: '✍🏻',
    title: 'Hace falta tu firma aquí.',
  },
];

export const noticieCopies = [
  {
    emoji: '👀',
    title: '¿Ya te vas? Márcame como visto.',
  },
  {
    emoji: '👀',
    title: 'No olvides marcarme como visto.',
  },
];
