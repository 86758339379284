// import { format } from 'date-fns';
// import { es } from 'react-date-range/dist/locale';

const variableToReplace = {
  '[Nombre_de_la_Empresa]': '{{company.name}}',
  '[Nombre_del_Empleado]': '{{user.name}}',
  '[RFC_del_Empleado]': '{{user.rfc}}',
  '[No_nomina_del_Empleado]': '{{workSpace.payrollId}}',
  '[Puesto_del_Empleado]': '{{workSpace.workTitle}}',
  '[Centro_de_Trabajo_del_Empleado]': '{{workCenter.name}}',
};

export const htmlDomPurify = (data) => {
  if (data.content && !data.preview) {
    Object.keys(variableToReplace).forEach((key) => {
      data.content = data.content.replaceAll(key, variableToReplace[key]);
    });
  }

  return `
  <!DOCTYPE html>
  <html>
    <head>
      <title id="Head"></title>
      <meta
        http-equiv="Content-Type"
        content="text/html; charset=UTF-8; width=device-width"
      />
      <style>
        * {
            font-family: Arial, Helvetica, sans-serif;
        }
       
        .title {
          color: blue; 
          font-weight: 700;
          font-size: 34px;
          line-height: 128%; 
          letter-spacing: 0px; 
          text-align: center;
        }
        .date {
          margin-bottom: 16px; 
          margin-left: 16px;
        }
        .variables {
          line-height: 24px; 
          margin-left: 16px;
        }

        .main {
          line-height: 24px !important;
          margin: 16px;
        }

        .container_sign {
          padding: 10px 10px 110px 10px; 
          display: flex; 
          justify-content: space-around;
        }

        .sign {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column; 
          padding: 0 80px;
          border-top: 1px solid;
          display: flex;
          justify-content: center;
          font-weight: 700;
          font-size: 18px;
        }

        .hash {
          border-top: 1px solid;
          display: flex;
          justify-content: center;
          font-weight: 700;
          font-size: 18px;
        }

      </style>
    </head>
    <body>
      ${
        data.logo
          ? `<img class='logo' height='100px' alt='logo' src=${
              data.preview ? data.logo : '{{firstImgUrl}}'
            }>`
          : ''
      }
      ${
        data.title
          ? `<h2 class="title">
          ${data.title}
        </h2>`
          : ''
      }
${
  ''
  // TODO: add date as variable <p class="date">Fecha: ${format(new Date(), 'd MMM hh:mm a y OOOO', {
  //   locale: es,
  // })}
}
      </p>
      <div class="main">
        ${data.content}
      </div>
      
      ${
        data.image
          ? `<img class='extraImage' height='100px' alt='logo' src=${
              data.preview ? data.image : '{{secondImgUrl}}'
            }>`
          : ''
      }
    </body>
  </html>
  `;
};

export const getHmtlFromCreator = ({ data, preview, content }) => {
  const { signable } = data;
  const htmlPopulate = htmlDomPurify({
    logo: null,
    preview,
    image: null,
    signable,
    content,
  });
  return htmlPopulate;
};

export const dynamicFields = (theme) => {
  return {
    employees: {
      text: 'Empleados',
      color: theme.customPalette.primary.blue,
      icon: 'team_line',
      label: 'Buscar empleados o centros de trabajo',
      warning:
        'Puedes seleccionar solo a los empleados y centros de trabajo que tengas asignados.',
    },
    companySigners: {
      text: 'Firmantes de empresa',
      color: theme.customPalette.status.success_green_70,
      icon: 'user_2_line',
      label: 'Buscar usuarios con permisos para firmar documentos',
      warning:
        'Sólo los usarios con permiso para firmar documentos apareceran en esta lista. ',
    },
  };
};
