import { useReactiveVar } from '@apollo/client';
import { Box, Divider, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Icon } from '../../../components/Icons/Icons';
import NotificationBox from '../../../components/Notifications/NotificationBox';
import { SpacedWrapper } from '../../../components/Utils/Utils';
import logout from '../../../utils/logout';
import { useTheme } from '@mui/material/styles';
import { profileDialogVar, userVar } from '../../../cache.reactiveVars';
import { Avatar } from '../../../newComponents/Avatar';
import { Menu } from '../../../newComponents/Menu';
import { MenuItem } from '../../../newComponents/MenuItem';
import { Typography } from '../../../newComponents/Typography';
import { Button } from '../../../newComponents/Button';

const adminOptions = [
  {
    title: 'Panel Admin',
    icon: 'lock_line',
    route: '/sora-admin',
  },
  {
    title: 'Panel Soporte',
    icon: 'door_lock_line',
    route: '/sora-support',
  },
  {
    title: 'Desarrollo',
    icon: 'terminal_window_line',
    route: '/sora-development',
  },
  {
    title: 'Analytics',
    icon: 'bar_chart_box_line',
    route: '/sora-analytics',
  },
];

/**
 * @param {object} props
 * @param {object} props.history
 * @param {boolean} props.disabled
 * @param {Function} [props.setShowTourEmployee]
 * @param {boolean} [props.notifInMenu]
 */
const UserMenu = ({ history, disabled, setShowTourEmployee, notifInMenu }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNotifMenu, setOpenNotifMenu] = useState(false);
  const user = useReactiveVar(userVar);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (e) => {
    if (disabled) return;
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    if (disabled) return;
    profileDialogVar(true);
    handleClose();
  };

  const navigateTo = (route) => {
    if (disabled) return;
    history.push(route);
    handleClose();
  };

  return (
    <div>
      <Avatar
        alt={user.name}
        id="user-menu-admin-tour"
        src={user.profilePicture}
        onClick={handleClick}
        color="primary"
        sx={{
          width: sm ? 36 : 48,
          height: sm ? 36 : 48,
          cursor: 'pointer',
          fontWeight: 'bold',
        }}
      >
        {user.name?.charAt(0).toUpperCase()}
      </Avatar>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem>
          <Box width="273px" display="flex" flexDirection="column" ml={1}>
            <Typography style={{ fontWeight: '600' }} variant="body2">
              {`${user.name} ${user.lastNameP} ${user.lastNameM}`}
            </Typography>
            <Typography color="grey" variant="caption">
              {user.email}
            </Typography>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleProfile}>
          <Box display="flex" m={1}>
            <Icon icon="user_line" />
            <Box ml={2}>
              <Typography color="text" variant="body2">
                Perfil
              </Typography>
            </Box>
          </Box>
        </MenuItem>
        {notifInMenu && (
          <MenuItem onClick={() => setOpenNotifMenu(!openNotifMenu)}>
            <SpacedWrapper m="8px">
              <NotificationBox
                inMenu
                setShowTourEmployee={setShowTourEmployee}
                openNotifMenu={openNotifMenu}
                setOpenNotifMenu={setOpenNotifMenu}
                setMenuAnchorEl={setAnchorEl}
              />
              <Typography variant="body2">Notificaciones</Typography>
            </SpacedWrapper>
          </MenuItem>
        )}
        {localStorage.soraAdmin === 'true' && (
          <AdminOptionList
            navigateTo={navigateTo}
            adminOptions={adminOptions}
          />
        )}
        <MenuItem onClick={() => logout(history)}>
          <Button fullWidth variant="outlined" color="primary">
            Cerrar Sesion
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
};

const AdminOptionList = ({ adminOptions, navigateTo }) => {
  return adminOptions.map((option) => (
    <MenuItem onClick={() => navigateTo(option.route)} key={option.title}>
      <SpacedWrapper m="8px" alignItems="center">
        <Icon icon={option.icon} />
        <Typography variant="body2">{option.title}</Typography>
      </SpacedWrapper>
    </MenuItem>
  ));
};

export default UserMenu;
