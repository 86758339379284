// @ts-check
import React from 'react';
import { IconButton as MuiIconButton } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { Tooltip } from '../Tooltip';

/**
 * @typedef {import('@mui/material/IconButton').IconButtonProps} IconButtonProps
 * @typedef {import('../../components/Icons/Icons').Icons} Icons
 *
 * @typedef CustomProps
 * @property {keyof Icons} icon
 * @property {'xtraSmall'|'small'|'medium'|'large'} [size]
 * @property {import('../../components/Icons/IconTypes').IconColors} [color]
 * @property {import('@mui/material').TooltipProps['title']} [tooltipText]
 * @property {import('@mui/material').TooltipProps['arrow']} [tooltipArrow]
 * @property {import('@mui/material').TooltipProps['placement']} [tooltipPlacement]
 */

const iconButtonSizes = {
  xtraSmall: '18px',
  small: '20px',
  medium: '24px',
  large: '32px',
};

/**
 * @param {Omit<IconButtonProps,"color"|"size"> & CustomProps} props
 */
export const IconButton = ({
  icon,
  size = 'medium',
  color = 'grey',
  disabled = false,
  tooltipText,
  tooltipPlacement,
  tooltipArrow,
  ...props
}) => {
  if (disabled) {
    color = 'grey';
  }
  if (tooltipText) {
    return (
      <Tooltip
        title={tooltipText}
        placement={tooltipPlacement}
        arrow={tooltipArrow}
      >
        <span>
          <MuiIconButton
            size={size === 'xtraSmall' ? 'small' : size}
            disabled={disabled}
            {...props}
          >
            <Icon
              icon={icon}
              height={iconButtonSizes[size]}
              width={iconButtonSizes[size]}
              color={color}
            />
          </MuiIconButton>
        </span>
      </Tooltip>
    );
  }
  return (
    <MuiIconButton
      size={size === 'xtraSmall' ? 'small' : size}
      disabled={disabled}
      {...props}
    >
      <Icon
        icon={icon}
        height={iconButtonSizes[size]}
        width={iconButtonSizes[size]}
        color={color}
      />
    </MuiIconButton>
  );
};
