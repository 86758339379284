import { useMutation } from '@apollo/client';
import { UPDATE_TEMPLATE } from '../../gql';
import type { TemplateToUpdate } from '../Template.types';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export type UpdateTemplateInput = {
  data: TemplateToUpdate;
  onSuccess: () => void;
};

export const useTemplateUpdate = () => {
  const [UpdateTemplate] = useMutation(UPDATE_TEMPLATE);

  const handleUpdateTemplate = async (input: UpdateTemplateInput) => {
    try {
      const { data, onSuccess } = input;
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Guardando cambios...',
      });

      const updateRes = await UpdateTemplate({
        variables: {
          input: {
            _id: data._id,
            title: data.title,
            description: data.description,
            htmlContent: data.myEditor,
            color: {
              primary: data.color,
              secondary: data.secondaryColor,
            },
            categories: {
              category: data.category,
              subcategory: data.subcategory,
            },
          },
        },
      });

      const { updateTemplate } = updateRes.data;
      if (updateTemplate.__typename === 'TemplateUpdated') {
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Plantilla actualizada',
        });
        onSuccess();
      }
      if (updateTemplate.__typename === 'ResourceNotFound') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'La plantilla a eliminar ya no se encuentra registrada',
        });
      }
    } catch (error) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleUpdateTemplate };
};
