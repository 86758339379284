import {
  currentCompanyVar,
  moduleBlockedDialogVar,
} from '../../cache.reactiveVars';
import { useReactiveVar } from '@apollo/client';

export const useBlockableActions = (requiredModule) => {
  const currentCompany = useReactiveVar(currentCompanyVar);
  const { modules: companyModules } = currentCompany;

  const keyToSearch = {
    DOCUMENTS: 'documents',
    REST: 'rest',
  }[requiredModule];

  const isModuleAvailable = companyModules?.[keyToSearch]?.active;

  const onClick = () => {
    if (!isModuleAvailable) {
      moduleBlockedDialogVar({
        open: true,
      });
    }
  };

  return {
    onClick,
    isModuleAvailable,
  };
};
