import { useState } from 'react';
import { Stack } from '@mui/material';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import { clearBulletPoints, removeTextFormat } from './helpers';
import { ConfirmDialog } from '../../../../newComponents/Dialog/ConfirmDialog';
import { Typography } from '../../../../newComponents/Typography/Typography';
/**
 * @typedef {import('react-hook-form').UseFormReturn<any>} Form
 * @typedef {import('react').MutableRefObject} MutableRefObject
 */
/**
 * @param {object} props
 * @param {Form} props.form
 * @param {(...event: any[]) => void} props.onChange
 * @param {any} props.value
 * @param {MutableRefObject} props.editorRef
 * @param {boolean} [props.disabled]
 */
export const Editor = ({ form, onChange, value, editorRef, disabled }) => {
  const values = form.getValues();
  const [clipboardData, setClipboard] = useState('');
  const [openFormatTextDialog, setOpenFormatTextDialog] = useState(false);

  const handleEditorChange = (editor) => {
    onChange(editor);
  };

  const uploadImage = (cb, value, meta) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = function () {
      const file = this.files[0];
      const reader = new FileReader();
      reader.onload = function () {
        const id = 'blobid' + new Date().getTime();
        const blobCache = editorRef.current.editorUpload.blobCache;
        const base64 = reader.result.split(',')[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);
        cb(blobInfo.blobUri(), { title: file.name });
      };
      reader.readAsDataURL(file);
    };
    input.click();
  };

  const removeFormat = () => {
    const modifiedContent = removeTextFormat(clipboardData);
    editorRef?.current.execCommand('mceInsertContent', false, modifiedContent);
    setOpenFormatTextDialog(false);
  };

  const insertContent = (e) => {
    e.preventDefault();
    const modifiedContent = clearBulletPoints(clipboardData);
    editorRef?.current.execCommand('mceInsertContent', false, modifiedContent);
    setOpenFormatTextDialog(false);
  };

  const onPasteText = (e) => {
    const clipboard = e.clipboardData || window.clipboardData;
    const htmlText = clipboard.getData('text/html');
    if (!htmlText) return;
    e.preventDefault();
    setClipboard(htmlText);
    setOpenFormatTextDialog(true);
  };

  return (
    <Stack minHeight="180px">
      <ConfirmDialog
        open={openFormatTextDialog}
        cancelLabel="Limpiar formato"
        confirmLabel="Mantener formato"
        onCancel={removeFormat}
        onConfirm={insertContent}
        title="¿Quieres pegar el texto manteniendo su formato original?"
        confirmButtonToRight
        ConfirmButtonProps={{ autoFocus: true }}
      >
        <Typography variant="body2">
          Algunos estilos podrían no ser compatibles con el editor.
        </Typography>
      </ConfirmDialog>
      <TinyEditor
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={value}
        def
        onPaste={onPasteText}
        onEditorChange={handleEditorChange}
        initialValue=""
        apiKey="cd2vy8em07evm4qhvgq9v3gm241cv8kll6jn2q7ut712wd87"
        disabled={disabled}
        init={{
          language: 'es',
          selector: 'textarea#file-picker',
          min_height: 620,
          elementpath: false,
          plugins: ['lists', 'image', 'paste'],
          menubar: 'file edit view insert format tools table tc help',
          toolbar:
            'bold italic underline strikethrough forecolor backcolor | alignleft aligncenter ' +
            'alignright alignjustify image | bullist numlist outdent indent | ' +
            'insertfile undo redo a11ycheck styleselect | formatselect fontsizeselect | ' +
            'removeformat | help link  | ',
          fullscreen_native: true,
          image_title: true,
          automatic_uploads: true,
          file_picker_types: 'image',
          file_picker_callback: function (cb, value, meta) {
            uploadImage(cb, value, meta);
          },
          content_style: `body { font-family:Arial,Helvetica,sans-serif; font-size:14px, background: red } .variable {
              font-weight: bold;
            }`,
          setup: (editor) => {
            editor.on('init', function (e) {
              if (values?.myEditor) {
                editor.setContent(`${values.myEditor}`);
              }
            });
          },
        }}
      />
    </Stack>
  );
};
