export const tableStyles = {
  boxShadow: 'none',
  '& .MuiDataGrid-main': {
    px: '10px',
    borderRadius: '12px',
    backgroundColor: '#F4F6F8',
    boxSizing: 'border-box',
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    px: '10px',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
  },
  '& .MuiDataGrid-row': {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    marginBottom: '10px',
    minHeight: '64px !important',
    maxHeight: '64px !important',
    ':hover .MuiDataGrid-actionsCell .MuiButtonBase-root': {
      visibility: 'visible',
    },
  },
};
