// @ts-check
import { makeVar } from '@apollo/client';
import { EMPLOYEE_ROW, FIND_BY_EMPLOYEES } from './EmployeesFinder.constants';

/** @type {import('./EmployeesFinder.types').EmployeesOnlySelectorVars} */
const employeesOnlySelectorDefault = {
  open: false,
  loading: false,
  workTitles: [],
  groups: [],
  data: {
    options: [],
    total: 0,
  },
  findBySelected: FIND_BY_EMPLOYEES,
};
export const employeesOnlySelectorVar = makeVar(employeesOnlySelectorDefault);

export const resetEmployeesOnlySelectorVar = () => {
  employeesOnlySelectorVar(employeesOnlySelectorDefault);
};

export const setEmployeesOnlySelectorVar = (input) => {
  employeesOnlySelectorVar({
    ...employeesOnlySelectorVar(),
    ...input,
  });
};

/** @type {import('./EmployeesFinder.types').EmployeesFinderVars} */
const employeesFinder = {
  open: false,
  loading: false,
  workTitles: [],
  groups: [],
  data: {
    options: [],
    total: 0,
  },
  findBySelected: FIND_BY_EMPLOYEES,
};
export const employeesFinderVar = makeVar(employeesFinder);
export const employeesFinderByCompanyIdsVar = makeVar({ ...employeesFinder });

export const resetEmployeesFinder = () => {
  employeesFinderVar(employeesFinder);
};

export const resetEmployeesFinderByCompanyIds = () => {
  employeesFinderByCompanyIdsVar(employeesFinder);
};

/** @type {() => void} */
const refetchAllEmployeesList = () => {};
const refetchEmployeesByCompanyIdsList = () => {};
export const refetchAllEmployeesListVar = makeVar(refetchAllEmployeesList);
export const refetchEmployeesByCompanyIdsListVar = makeVar(
  refetchEmployeesByCompanyIdsList,
);

/** @type {import('./EmployeesFinder.types').UpdateEmployee} */
export const updateEmployee = (input) => {
  const { _id, update } = input;
  const { data } = employeesFinderVar();
  const employee = data.options.find((option) => {
    if (option.type === EMPLOYEE_ROW) return option._id === _id;
    return false;
  });

  if (!employee) return;
  if (employee.type !== EMPLOYEE_ROW) return;
  const employeeUpdated = update(employee);

  const newOptions = data.options.map((option) => {
    if (option.type === EMPLOYEE_ROW && option._id === _id) {
      return employeeUpdated;
    }
    return option;
  });
  employeesFinderVar({
    ...employeesFinderVar(),
    data: { ...data, options: newOptions },
  });
};

export const employeesFinderReactivesVars = {
  employeesOnlySelectorVar: {
    read: () => employeesOnlySelectorVar(),
  },
  employeesFinderVar: {
    read: () => employeesFinderVar(),
  },
  refetchAllEmployeesListVar: {
    read: () => refetchAllEmployeesListVar(),
  },
  employeesFinderByCompanyIdsVar: {
    read: () => employeesFinderByCompanyIdsVar(),
  },
  refetchEmployeesByCompanyIdsListVar: {
    read: () => refetchEmployeesByCompanyIdsListVar(),
  },
};
